import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';

import BarHeat from '../../components/heat_graph/barGraph';
import PlaneHeat from '../../components/heat_graph/planeGraph';
import DeltaTHeat from '../../components/heat_graph/deltaTGraph';
import Checkbox from '@material-ui/core/Checkbox';


import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";
import InputAdornment from '@material-ui/core/InputAdornment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {

  state = {
    id: this.props.id,
    name: '',

    alturaMargemDown: { value: 1, text: -1, valid: true },
    alturaMargemUp: { value: 1, text: 1, valid: true },
    alturaLimiteDown: { value: 1, text: -2, valid: true },
    alturaLimiteUp: { value: 1, text: 2, valid: true },
    alturaSet: 4,

    fluxoMargemDown: { value: 2, text: -2, valid: true },
    fluxoMargemUp: { value: 2, text: 2, valid: true },
    fluxoLimiteDown: { value: 3, text: -5, valid: true },
    fluxoLimiteUp: { value: 3, text: 5, valid: true },
    fluxoSet: 10,

    velocidadeMargemDown: { value: 10, text: -10, valid: true },
    velocidadeMargemUp: { value: 10, text: 10, valid: true },
    velocidadeLimiteDown: { value: 20, text: -30, valid: true },
    velocidadeLimiteUp: { value: 20, text: 30, valid: true },
    velocidadeSet: 180,

    velocidadeVMargemDown: { value: 8, text: 2, valid: true },
    velocidadeVMargemUp: { value: 8, text: 18, valid: true },
    velocidadeVLimiteDown: { value: 2, text: 0, valid: true },
    velocidadeVLimiteUp: { value: 12, text: 30, valid: true },
    velocidadeVSet: 10,


    espelharAnguloV: true,
    anguloVMargemDown: { value: 50, text: 50, valid: true },
    anguloVMargemUp: { value: 50, text: 50, valid: true },
    anguloVLimiteDown: { value: 20, text: 70, valid: true },
    anguloVLimiteUp: { value: 20, text: 70, valid: true },
    anguloVSet: 0,


    temperaturaMargemDown: { value: 17.5, text: 5, valid: true },
    temperaturaMargemUp: { value: 17.5, text: 40, valid: true },
    temperaturaLimiteDown: { value: 5, text: 0, valid: true },
    temperaturaLimiteUp: { value: 10, text: 50, valid: true },
    temperaturaSet: 22.5,


    UmidadeMargemDown: { value: 37.5, text: 15, valid: true },
    UmidadeMargemUp: { value: 37.5, text: 90, valid: true },
    UmidadeLimiteDown: { value: 5, text: 10, valid: true },
    UmidadeLimiteUp: { value: 10, text: 100, valid: true },
    UmidadeSet: 52.5,


    //-----

    checkConfig: [0, 0, 0, 0, 0],
  };

  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/profile_auto/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              let state = this.state;
              state.name = result[0].name;
              for (let i = 0; i < result[0].params.length; i++) {
                const element = result[0].params[i];
                if (element.type === 'altura') {
                  state.alturaMargemDown.value = element.margin_down;
                  state.alturaMargemDown.text = element.set_a - element.margin_down;
                  state.alturaMargemUp.value = element.margin_up;
                  state.alturaMargemUp.text = element.set_a + element.margin_up;
                  state.alturaLimiteDown.value = element.limit_down;
                  state.alturaLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.alturaLimiteUp.value = element.limit_up;
                  state.alturaLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                  if (element.set_a) {
                    state.alturaSet = element.set_a;
                    state.checkConfig[0] = 1;
                  }
                } else if (element.type === 'fluxo') {
                  state.fluxoMargemDown.value = element.margin_down;
                  state.fluxoMargemDown.text = element.set_a - element.margin_down;
                  state.fluxoMargemUp.value = element.margin_up;
                  state.fluxoMargemUp.text = element.set_a + element.margin_up;
                  state.fluxoLimiteDown.value = element.limit_down;
                  state.fluxoLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.fluxoLimiteUp.value = element.limit_up;
                  state.fluxoLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                  if (element.set_a) {
                    state.fluxoSet = element.set_a;
                    state.checkConfig[2] = 1;
                  }
                } else if (element.type === 'velocidade') {
                  state.velocidadeMargemDown.value = element.margin_down;
                  state.velocidadeMargemDown.text = element.set_a - element.margin_down;
                  state.velocidadeMargemUp.value = element.margin_up;
                  state.velocidadeMargemUp.text = element.set_a + element.margin_up;
                  state.velocidadeLimiteDown.value = element.limit_down;
                  state.velocidadeLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.velocidadeLimiteUp.value = element.limit_up;
                  state.velocidadeLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                  if (element.set_a) {
                    state.velocidadeSet = element.set_a;
                    state.checkConfig[1] = 1;
                  }
                } else if (element.type === 'velocidadeV') {
                  state.velocidadeVMargemDown.value = element.margin_down;
                  state.velocidadeVMargemDown.text = element.set_a - element.margin_down;
                  state.velocidadeVMargemUp.value = element.margin_up;
                  state.velocidadeVMargemUp.text = element.set_a + element.margin_up;
                  state.velocidadeVLimiteDown.value = element.limit_down;
                  state.velocidadeVLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.velocidadeVLimiteUp.value = element.limit_up;
                  state.velocidadeVLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                } else if (element.type === 'angulo') {
                  state.anguloVMargemDown.value = element.margin_down;
                  state.anguloVMargemDown.text = element.margin_down;
                  state.anguloVMargemUp.value = element.margin_up;
                  state.anguloVMargemUp.text = element.margin_up;
                  state.anguloVLimiteDown.value = element.limit_down;
                  state.anguloVLimiteDown.text = element.margin_down + element.limit_down;
                  state.anguloVLimiteUp.value = element.limit_up;
                  state.anguloVLimiteUp.text = element.margin_up + element.limit_up;
                  if (state.anguloVLimiteUp.value !== state.anguloVLimiteDown.value ||
                    state.anguloVMargemUp.value !== state.anguloVMargemDown.value) {
                    state.espelharAnguloV = false;
                  }
                } else if (element.type === 'temperatura') {
                  state.temperaturaSet = element.set_a;
                  state.temperaturaMargemDown.value = element.margin_down;
                  state.temperaturaMargemDown.text = element.set_a - element.margin_down;
                  state.temperaturaMargemUp.value = element.margin_up;
                  state.temperaturaMargemUp.text = element.set_a + element.margin_up;
                  state.temperaturaLimiteDown.value = element.limit_down;
                  state.temperaturaLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.temperaturaLimiteUp.value = element.limit_up;
                  state.temperaturaLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                } else if (element.type === 'umidade') {
                  state.UmidadeSet = element.set_a;
                  state.UmidadeMargemDown.value = element.margin_down;
                  state.UmidadeMargemDown.text = element.set_a - element.margin_down;
                  state.UmidadeMargemUp.value = element.margin_up;
                  state.UmidadeMargemUp.text = element.set_a + element.margin_up;
                  state.UmidadeLimiteDown.value = element.limit_down;
                  state.UmidadeLimiteDown.text = element.set_a - element.margin_down - element.limit_down;
                  state.UmidadeLimiteUp.value = element.limit_up;
                  state.UmidadeLimiteUp.text = element.set_a + element.margin_up + element.limit_up;
                }

              }
              this.setState(state);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.name ||
      !this.state.alturaMargemDown.valid ||
      !this.state.alturaMargemUp.valid ||
      !this.state.alturaLimiteDown.valid ||
      !this.state.alturaLimiteUp.valid ||
      !this.state.fluxoMargemDown.valid ||
      !this.state.fluxoMargemUp.valid ||
      !this.state.fluxoLimiteDown.valid ||
      !this.state.fluxoLimiteUp.valid ||
      !this.state.velocidadeMargemDown.valid ||
      !this.state.velocidadeMargemUp.valid ||
      !this.state.velocidadeLimiteDown.valid ||
      !this.state.velocidadeLimiteUp.valid ||
      !this.state.velocidadeVMargemDown.valid ||
      !this.state.velocidadeVMargemUp.valid ||
      !this.state.velocidadeVLimiteDown.valid ||
      !this.state.velocidadeVLimiteUp.valid ||
      (!this.state.espelharAnguloV && !this.state.anguloVMargemDown.valid) ||
      !this.state.anguloVMargemUp.valid ||
      (!this.state.espelharAnguloV && !this.state.anguloVLimiteDown.valid) ||
      !this.state.anguloVLimiteUp.valid ||
      !this.state.temperaturaMargemDown.valid ||
      !this.state.temperaturaMargemUp.valid ||
      !this.state.temperaturaLimiteDown.valid ||
      !this.state.temperaturaLimiteUp.valid ||
      !this.state.UmidadeMargemDown.valid ||
      !this.state.UmidadeMargemUp.valid ||
      !this.state.UmidadeLimiteDown.valid ||
      !this.state.UmidadeLimiteUp.valid) {
      notification.open({
        message: this.props.res.CONFIG_INVALID,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }

    let method = 'POST';
    let apiUrl = Config.server + '/profile_auto';
    if (this.state.id) {
      apiUrl += `/${this.state.id}`;
      method = 'PUT';
    }

    let json = {
      name: this.state.name,
      type: 'analise',
      params: [
        {
          type: 'altura',
          set_a: this.state.checkConfig[0] ? this.state.alturaSet : 0,
          margin_up: this.state.alturaMargemUp.value,
          margin_down: this.state.alturaMargemDown.value,
          limit_up: this.state.alturaLimiteUp.value,
          limit_down: this.state.alturaLimiteDown.value,
        },
        {
          type: 'fluxo',
          set_a: this.state.checkConfig[2] ? this.state.fluxoSet : 0,
          margin_up: this.state.fluxoMargemUp.value,
          margin_down: this.state.fluxoMargemDown.value,
          limit_up: this.state.fluxoLimiteUp.value,
          limit_down: this.state.fluxoLimiteDown.value,
        },
        {
          type: 'velocidade',
          set_a: this.state.checkConfig[1] ? this.state.velocidadeSet : 0,
          margin_up: this.state.velocidadeMargemUp.value,
          margin_down: this.state.velocidadeMargemDown.value,
          limit_up: this.state.velocidadeLimiteUp.value,
          limit_down: this.state.velocidadeLimiteDown.value,
        },
        {
          type: 'velocidadeV',
          set_a: this.state.velocidadeVSet,
          margin_up: this.state.velocidadeVMargemUp.value,
          margin_down: this.state.velocidadeVMargemDown.value,
          limit_up: this.state.velocidadeVLimiteUp.value,
          limit_down: this.state.velocidadeVLimiteDown.value,
        },
        {
          type: 'angulo',
          set_a: 90,//VSetRight
          set_b: 270,//VSetLeft
          margin_up: this.state.espelharAnguloV ? this.state.anguloVMargemDown.value : this.state.anguloVMargemUp.value,
          margin_down: this.state.anguloVMargemDown.value,
          limit_up: this.state.espelharAnguloV ? this.state.anguloVLimiteDown.value : this.state.anguloVLimiteUp.value,
          limit_down: this.state.anguloVLimiteDown.value,
        },
        {
          type: 'temperatura',
          set_a: this.state.temperaturaSet,
          margin_up: this.state.temperaturaMargemUp.value,
          margin_down: this.state.temperaturaMargemDown.value,
          limit_up: this.state.temperaturaLimiteUp.value,
          limit_down: this.state.temperaturaLimiteDown.value,
        },
        {
          type: 'umidade',
          set_a: this.state.UmidadeSet,
          margin_up: this.state.UmidadeMargemUp.value,
          margin_down: this.state.UmidadeMargemDown.value,
          limit_up: this.state.UmidadeLimiteUp.value,
          limit_down: this.state.UmidadeLimiteDown.value,
        }
      ]
    };

    let options = {
      method: method,
      body: JSON.stringify(json),
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'Content-Type': 'application/json'
      },
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let msg = this.props.res.ITEM_INCLUIR;
          if (this.state.id) {
            msg = this.props.res.ITEM_ALTERADO;
          }
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );

  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  changeSettingsMargemDown = (value, setValue, margemDown, limiteDown, margemUp) => {
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemUp = this.state[margemUp];
    if (margemUp) {
      temp_setValue = (Number(temp_margemUp.text) - Number(value)) / 2 + Number(value);
    }
    let temp_margemDown = this.state[margemDown];
    temp_margemDown.text = value;
    if (!isNaN(value) &&
      Number(value) >= 0 &&
      (temp_setValue - Number(value)) >= 0) {
      temp_margemDown.valid = true;
      temp_margemDown.value = (temp_setValue - Number(value));
      let temp_limiteDown = this.state[limiteDown];
      let temp = (temp_setValue - this.state[margemDown].value - this.state[limiteDown].value);
      if (temp < 0) {
        temp_limiteDown.value = temp === 0 ? 0 : this.state[limiteDown].value + temp;
        if (temp_limiteDown.valid) temp_limiteDown.text = value - temp_limiteDown.value;
      }
      temp_limiteDown.text = (temp_setValue - temp_margemDown.value - temp_limiteDown.value);
      let update = { [margemDown]: temp_margemDown, [limiteDown]: temp_limiteDown, [setValue]: temp_setValue };
      if (margemUp) {
        temp_margemUp.value = Number(temp_margemUp.text) - temp_setValue;
        update[margemUp] = temp_margemUp;
      }
      this.setState(update);
    } else {
      temp_margemDown.valid = false;
      this.setState({ [margemDown]: temp_margemDown });
    }
  };
  changeSettingsMargemUp = (value, setValue, margemUp, limiteUp, limitMax, margemDown) => {
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemDown = this.state[margemDown];
    if (margemDown) {
      temp_setValue = (Number(value) - Number(temp_margemDown.text)) / 2 + Number(temp_margemDown.text);
    }
    let temp_margemUp = this.state[margemUp];
    temp_margemUp.text = value;
    if (!isNaN(value) &&
      ((limitMax && (temp_setValue + Number(value)) <= limitMax) || !limitMax) &&
      (Number(value) - temp_setValue) >= 0) {
      temp_margemUp.value = (Number(value) - temp_setValue);
      temp_margemUp.valid = true;
      let temp_limiteUp = this.state[limiteUp];
      if (limitMax && (temp_setValue + temp_margemUp.value + temp_limiteUp.value) > limitMax) {
        temp_limiteUp.value = limitMax - temp_margemUp.value;
      }
      temp_limiteUp.text = temp_setValue + temp_margemUp.value + temp_limiteUp.value;
      let update = { [margemUp]: temp_margemUp, [limiteUp]: temp_limiteUp, [setValue]: temp_setValue };
      if (margemDown) {
        temp_margemDown.value = temp_setValue - Number(temp_margemDown.text);
        update[margemDown] = temp_margemDown;
      }
      this.setState(update);
    } else {
      temp_margemUp.valid = false;
      this.setState({ [margemUp]: temp_margemUp });
    }
  };
  changeSettingsLimitDown = (value, setValue, margemDown, limiteDown) => {
    let temp_limitDown = this.state[limiteDown];
    temp_limitDown.text = value;
    if (!isNaN(value) &&
      Number(value) >= 0 &&
      Number(value) <= (Number(this.state[setValue]) - Number(this.state[margemDown].value))) {
      temp_limitDown.value = (Number(this.state[setValue]) - Number(this.state[margemDown].value) - Number(value))
      temp_limitDown.text = value;
      temp_limitDown.valid = true;
      this.setState({ [limiteDown]: temp_limitDown });
    } else {
      temp_limitDown.valid = false;
      this.setState({ [limiteDown]: temp_limitDown });
    }
  };
  changeSettingsLimitUp = (value, setValue, margemUp, limiteUp, limitMax) => {
    let temp_value = Number(value);
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemUp = Number(this.state[margemUp].value);
    let temp_limiteUp = this.state[limiteUp];
    temp_limiteUp.text = value;
    if (!isNaN(value) &&
      ((limitMax && (temp_value) <= limitMax) || !limitMax) &&
      (temp_value) >= (temp_margemUp + temp_setValue)) {

      temp_limiteUp.value = (temp_value - (temp_setValue + temp_margemUp));
      temp_limiteUp.valid = true;
      this.setState({ [limiteUp]: temp_limiteUp });
    } else {
      temp_limiteUp.valid = false;
      this.setState({ [limiteUp]: temp_limiteUp });
    }
  };
  changeType = (config, set, limiteDown, margemDown, margemUp, limiteUp) => ev => {
    if (config) {
      const setValue = ev ? Number(ev.target.value) : this.state[set];
      this.state[limiteDown].text = setValue - (this.state[margemDown].value + this.state[limiteDown].value);
      this.state[margemDown].text = setValue - this.state[margemDown].value;
      this.state[margemUp].text = setValue + this.state[margemUp].value;
      this.state[limiteUp].text = setValue + this.state[margemUp].value + this.state[limiteUp].value;
    } else {
      this.state[limiteDown].text = 0 - (this.state[margemDown].value + this.state[limiteDown].value);
      this.state[margemDown].text = 0 - this.state[margemDown].value;
      this.state[margemUp].text = 0 + this.state[margemUp].value;
      this.state[limiteUp].text = 0 + this.state[margemUp].value + this.state[limiteUp].value;
    }
    let state = {
      [limiteDown]: this.state[limiteDown],
      [margemDown]: this.state[margemDown],
      [margemUp]: this.state[margemUp],
      [limiteUp]: this.state[limiteUp],
    };
    if (ev) {
      state[set] = ev.target.value;
    }
    this.setState(state);
  };

  styleTitle = { marginTop: '1rem', width: 'auto', height: '2.3rem', marginBottom: '-1.5rem', textDecoration: 'underline', textAlign: 'center' };
  montaItem = (label, config, set, limitDown, maginDown, maginUp, limitUp) => {
    return <div className="divContentItem">
      <div><h4 style={this.styleTitle}>{label[0].toUpperCase()}</h4></div>
      <div style={{ marginBottom: '-1rem' }}>
        <FormControl
          style={{ padding: ".4rem 0 .4rem 0" }}
          className={this.props.classes.textField}
          fullWidth
          component="fieldset">
          <RadioGroup id="tipo_pessoa" aria-label="pessoa" name="pessoa1" value={this.state.checkConfig[config]}
            onChange={(ev) => {
              this.state.checkConfig[config] = Number(ev.target.value);
              this.setState({ checkConfig: this.state.checkConfig },
                this.changeType(this.state.checkConfig[config], set, limitDown, maginDown, maginUp, limitUp)
              );
            }}
          >
            <FormControlLabel value={0} control={<Radio color="primary" />} label={'Configuração da Aeronave'} />
            <FormControlLabel value={1} control={<Radio color="primary" />} label={'Configuração fixa'} />
          </RadioGroup>
          <TextField
            style={{ width: '9rem', position: 'absolute', left: '11rem', top: '3.5rem' }}
            disabled={!this.state.checkConfig[config]}
            value={this.state[set]}
            onChange={this.changeType(this.state.checkConfig[config], set, limitDown, maginDown, maginUp, limitUp)}
            type="number"
          />
        </FormControl>
      </div>
      <BarHeat values={this.state.checkConfig[config] ? [
        Number(this.state[set]) - (this.state[maginDown].value + this.state[limitDown].value),
        Number(this.state[set]) - this.state[maginDown].value,
        Number(this.state[set]),
        Number(this.state[set]) + this.state[maginUp].value,
        Number(this.state[set]) + this.state[maginUp].value + this.state[limitUp].value
      ] : [
        0 - (this.state[maginDown].value + this.state[limitDown].value),
        0 - this.state[maginDown].value,
        0,
        this.state[maginUp].value,
        this.state[maginUp].value + this.state[limitUp].value
      ]} />
      <div style={{ textAlign: 'center' }}>
        <TextField
          label={label[3]}
          value={Math.round(this.state[maginDown].text * 1000) / 1000}
          error={!this.state[maginDown].valid}
          onChange={this.changeMargemDown(this.state.checkConfig[config], set, maginDown, limitDown)}
          size="small"
          margin="dense"
          type="number"
          style={{ marginRight: '.5rem', width: '10rem' }}
        />
        <TextField
          label={label[4]}
          value={Math.round(this.state[maginUp].text * 1000) / 1000}
          error={!this.state[maginUp].valid}
          onChange={this.changeMargemUp(this.state.checkConfig[config], set, maginUp, limitUp)}
          size="small"
          margin="dense"
          InputProps={{
            startAdornment: this.state.checkConfig[config] || this.state[maginUp].text <= 0 ? null : <InputAdornment position="start" style={{ marginRight: '0', marginTop: '-.2rem' }}>+</InputAdornment>,
          }}
          type="number"
          style={{ width: '10rem' }}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <TextField
          label={label[5]}
          value={Math.round(this.state[limitDown].text * 1000) / 1000}
          error={!this.state[limitDown].valid}
          onChange={this.changeLimitDown(this.state.checkConfig[config], set, maginDown, limitDown)}
          size="small"
          margin="dense"
          type="number"
          style={{ marginRight: '.5rem', width: '10rem' }}
        />
        <TextField
          label={label[6]}
          value={Math.round(this.state[limitUp].text * 1000) / 1000}
          error={!this.state[limitUp].valid}
          onChange={this.changeLimitUp(this.state.checkConfig[config], set, maginUp, limitUp)}
          size="small"
          margin="dense"
          InputProps={{
            startAdornment: this.state.checkConfig[config] || this.state[limitUp].text <= 0 ? null : <InputAdornment position="start" style={{ marginRight: '0', marginTop: '-.2rem' }}>+</InputAdornment>,
          }}
          type="number"
          style={{ width: '10rem' }}
        />
      </div>
    </div>;
  }

  changeMargemUp = (config, set, margem, limit) => event => {
    let text = Number(event.target.value);
    this.state[margem].text = text;
    if (config) {
      let setValue = Number(this.state[set]);
      if (text >= 0 && setValue <= text) {
        this.state[margem].valid = true;
        this.state[margem].value = text - setValue;
        this.state[limit].text = setValue + this.state[margem].value + this.state[limit].value;
        this.setState({ [margem]: this.state[margem], [limit]: this.state[limit] });
      } else {
        this.state[margem].valid = false;
        this.setState({ [margem]: this.state[margem] });
      }
    } else {
      if (text < 0) {
        this.state[margem].valid = false;
        this.state[margem].value = 0;
      } else {
        this.state[margem].valid = true;
        this.state[margem].value = text;
      }
      this.state[limit].text = text + this.state[limit].value;
      this.setState({ [margem]: this.state[margem], [limit]: this.state[limit] });
    }
  };
  changeMargemDown = (config, set, margem, limit) => event => {
    let text = Number(event.target.value);
    this.state[margem].text = text;
    if (config) {
      let setValue = Number(this.state[set]);
      if (text >= 0 && setValue >= text) {
        this.state[margem].valid = true;
        this.state[margem].value = setValue - text;
        this.state[limit].text = setValue - this.state[margem].value - this.state[limit].value;
        this.state[limit].valid = this.state[limit].text < 0 ? false : true;
        this.setState({ [margem]: this.state[margem], [limit]: this.state[limit] });
      } else {
        this.state[margem].valid = false;
        this.setState({ [margem]: this.state[margem] });
      }
    } else {
      if (text > 0) {
        this.state[margem].valid = false;
        this.state[margem].value = 0;
      } else {
        this.state[margem].valid = true;
        this.state[margem].value = text * -1;
      }
      this.state[limit].text = text - this.state[limit].value;
      this.setState({ [margem]: this.state[margem], [limit]: this.state[limit] });
    }
  };
  changeLimitUp = (config, set, margem, limit) => event => {
    let value = Number(event.target.value);
    this.state[limit].text = value;
    if (value < this.state[margem].text) {
      this.state[limit].valid = false;
    } else {
      this.state[limit].valid = true;
      this.state[limit].value = value - this.state[margem].text;
    }

    this.setState({ [limit]: this.state[limit] });
  };
  changeLimitDown = (config, set, margem, limit) => event => {
    let value = Number(event.target.value);
    this.state[limit].text = value;
    let setValue = Number(this.state[set]);
    if (config) {
      if (value > this.state[margem].text || value < 0) {
        this.state[limit].valid = false;
      } else {
        this.state[limit].valid = true;
        this.state[limit].value = this.state[margem].text - value;
      }
    } else {
      if (value > this.state[margem].text || value > 0) {
        this.state[limit].valid = false;
        this.state[limit].value = 0;
      } else {
        this.state[limit].valid = true;
        this.state[limit].value = value * -1 - this.state[margem].value;
      }
    }
    this.setState({ [limit]: this.state[limit] });
  }

  render() {
    const { classes, link, res } = this.props;

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="divContent">
          <div className="containerTop">
            <div><h1>{'Perfil de Automação'}</h1></div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" onClick={evemt => { link('/app/cadastro/perfil_auto/list') }} className="popUpButton">
                {res.CANCELAR}
              </Button> &nbsp;
              <Button type="submit" variant="contained" color="primary" className="popUpButton">
                <SaveIcon className="iconMapS" />
                {res.SALVAR}
              </Button>
            </div>
          </div>
          <div><h2 style={{ marginLeft: '.5rem' }}>{'Qualidade'}</h2></div>
          <div className="form-group notTooBig">
            <FormControl style={{ width: '100%' }}>
              <TextField
                required
                id="name"
                label={res.NOME}
                className={classes.textField}
                value={this.state.name}
                onChange={this.handleChange('name')}
                margin="normal"
                fullWidth
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
            </FormControl>
          </div>
        </div>
        <div key={'teste'}>
          <div
            style={{
              paddingBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
          </div>
        </div>
        <div key={'teste1'}>
          <h3
            style={{
              marginBottom: '1rem',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            {'AERONAVE'}
          </h3>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '.5rem',
              paddingBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
            {this.montaItem([res.ALTURA, res.TOLERANCIA_ALTURA_MIN, res.TOLERANCIA_ALTURA_MAX, res.LIMITE_ALTURA_MIN, res.LIMITE_ALTURA_MAX],
              0, 'alturaSet', 'alturaLimiteDown', 'alturaMargemDown', 'alturaMargemUp', 'alturaLimiteUp')}
            {this.montaItem([res.FLUXO, res.TOLERANCIA_FLUXO_MIN, res.TOLERANCIA_FLUXO_MAX, res.LIMITE_FLUXO_MIN, res.LIMITE_FLUXO_MAX],
              2, 'fluxoSet', 'fluxoLimiteDown', 'fluxoMargemDown', 'fluxoMargemUp', 'fluxoLimiteUp')}
            {this.montaItem([res.VELOCIDADE, res.TOLERANCIA_VELOCIDADE_MIN, res.TOLERANCIA_VELOCIDADE_MAX, res.LIMITE_VELOCIDADE_MIN, res.LIMITE_VELOCIDADE_MAX],
              1, 'velocidadeSet', 'velocidadeLimiteDown', 'velocidadeMargemDown', 'velocidadeMargemUp', 'velocidadeLimiteUp')}
          </div>
        </div>
        <div key={'teste2'}>
          <h3
            style={{
              marginBottom: '1rem',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            {'CLIMA'}
          </h3>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '.5rem',
              paddingBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
            <div className="divContentItem">
              <div style={{ position: 'relative' }}>
                <div><h4 style={this.styleTitle}>{res.VELOCIDADE_VENTO.toUpperCase()}</h4></div>
                <BarHeat values={[
                  Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value - this.state.velocidadeVLimiteDown.value,
                  Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value,
                  null,//Number(this.state.velocidadeVSet),
                  Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value,
                  Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value + this.state.velocidadeVLimiteUp.value
                ]} grayZero={true} />
                <div style={{ textAlign: 'center' }}>
                  <TextField
                    label={res.TOLERANCIA_VELOCIDADE_VENTO_MIN}
                    value={Math.round(this.state.velocidadeVMargemDown.text * 1000) / 1000}
                    error={!this.state.velocidadeVMargemDown.valid}
                    onChange={(event) => {
                      this.changeSettingsMargemDown(
                        event.target.value,
                        'velocidadeVSet',
                        'velocidadeVMargemDown',
                        'velocidadeVLimiteDown',
                        'velocidadeVMargemUp',
                      );
                    }}
                    size="small"
                    margin="dense"
                    type="number"
                    style={{ marginRight: '.5rem' }}
                  />
                  <TextField
                    label={res.TOLERANCIA_VELOCIDADE_MAX}
                    value={Math.round(this.state.velocidadeVMargemUp.text * 1000) / 1000}
                    error={!this.state.velocidadeVMargemUp.valid}
                    onChange={(event) => {
                      this.changeSettingsMargemUp(
                        event.target.value,
                        'velocidadeVSet',
                        'velocidadeVMargemUp',
                        'velocidadeVLimiteUp',
                        null,
                        'velocidadeVMargemDown'
                      );
                    }}
                    size="small"
                    margin="dense"
                    type="number"
                  />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <TextField
                    label={res.LIMITE_VELOCIDADE_MIN}
                    value={Math.round(this.state.velocidadeVLimiteDown.text * 1000) / 1000}
                    error={!this.state.velocidadeVLimiteDown.valid}
                    onChange={(event) => {
                      this.changeSettingsLimitDown(
                        event.target.value,
                        'velocidadeVSet',
                        'velocidadeVMargemDown',
                        'velocidadeVLimiteDown'
                      );
                    }}
                    size="small"
                    margin="dense"
                    type="number"
                    style={{ marginRight: '.5rem' }}
                  />
                  <TextField
                    label={res.LIMITE_VELOCIDADE_MAX}
                    value={Math.round(this.state.velocidadeVLimiteUp.text * 1000) / 1000}
                    error={!this.state.velocidadeVLimiteUp.valid}
                    onChange={(event) => {
                      this.changeSettingsLimitUp(
                        event.target.value,
                        'velocidadeVSet',
                        'velocidadeVMargemUp',
                        'velocidadeVLimiteUp'
                      );
                    }}
                    size="small"
                    margin="dense"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="divContentItem" style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', right: '0px', top: '4rem', zIndex: '1', userSelect: 'none' }}>
                <span onClick={(ev) => { this.setState({ espelharAnguloV: !this.state.espelharAnguloV }) }} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  espelhar?
                </span>
                <Checkbox
                  checked={this.state.espelharAnguloV}
                  onClick={(ev) => { this.setState({ espelharAnguloV: !this.state.espelharAnguloV }) }}
                />
              </div>
              <div><h4 style={this.styleTitle}>{res.ANGULO_DESVIO_VENTO_UNI.toUpperCase()}</h4></div>
              <div style={{
                width: '23.45rem',
                margin: '-.5rem'
              }}>
                <PlaneHeat type="P" ckey="0" values={[
                  Number(this.state.anguloVLimiteDown.value),
                  Number(this.state.anguloVMargemDown.value),
                  Number(this.state.anguloVSet),
                  this.state.espelharAnguloV ? Number(this.state.anguloVMargemDown.value) : Number(this.state.anguloVMargemUp.value),
                  this.state.espelharAnguloV ? Number(this.state.anguloVLimiteDown.value) : Number(this.state.anguloVLimiteUp.value)
                ]} limit="90" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.TOLERANCIA_ANGULO_VENTO_MIN}
                  value={Math.round(this.state.anguloVMargemDown.text * 1000) / 1000}
                  error={!this.state.anguloVMargemDown.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemUp(
                      event.target.value,
                      'anguloVSet',
                      'anguloVMargemDown',
                      'anguloVLimiteDown',
                      90
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.TOLERANCIA_ANGULO_VENTO_MAX}
                  value={this.state.espelharAnguloV ?
                    Math.round(this.state.anguloVMargemDown.text * 1000) / 1000 :
                    Math.round(this.state.anguloVMargemUp.text * 1000) / 1000}
                  error={!this.state.anguloVMargemUp.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemUp(
                      event.target.value,
                      'anguloVSet',
                      'anguloVMargemUp',
                      'anguloVLimiteUp',
                      90
                    );
                  }}
                  size="small"
                  margin="dense"
                  disabled={this.state.espelharAnguloV}
                  type="number"
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.LIMITE_ANGULO_VENTO_MIN}
                  value={Math.round(this.state.anguloVLimiteDown.text * 1000) / 1000}
                  error={!this.state.anguloVLimiteDown.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitUp(
                      event.target.value,
                      'anguloVSet',
                      'anguloVMargemDown',
                      'anguloVLimiteDown',
                      90
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.LIMITE_ANGULO_VENTO_MAX}
                  value={this.state.espelharAnguloV ?
                    Math.round(this.state.anguloVLimiteDown.text * 1000) / 1000 :
                    Math.round(this.state.anguloVLimiteUp.text * 1000) / 1000}
                  error={!this.state.anguloVLimiteUp.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitUp(
                      event.target.value,
                      'anguloVSet',
                      'anguloVMargemUp',
                      'anguloVLimiteUp',
                      90
                    );
                  }}
                  size="small"
                  margin="dense"
                  disabled={this.state.espelharAnguloV}
                  type="number"
                />
              </div>
            </div>
            <div className="divContentItem">
              <div><h4 style={this.styleTitle}>{res.DELTA_T.toUpperCase()}</h4></div>
              <div style={{ marginLeft: '-.7rem' }}>
                <DeltaTHeat values={this.state} temperatura_values={[
                  Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value - this.state.temperaturaLimiteDown.value,
                  Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value,
                  null, //Number(this.state.temperaturaSet),
                  Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value,
                  Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value + this.state.temperaturaLimiteUp.value
                ]} humidade_values={[
                  Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value - this.state.UmidadeLimiteDown.value,
                  Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value,
                  null, //Number(this.state.UmidadeSet),
                  Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value,
                  Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value + this.state.UmidadeLimiteUp.value
                ]} />
              </div>
              <div><h4 style={this.styleTitle}>{res.TEMPERATURA_UNI.toUpperCase()}</h4></div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.TOLERANCIA_TEMPERATURA_MIN}
                  value={Math.round(this.state.temperaturaMargemDown.text * 1000) / 1000}
                  error={!this.state.temperaturaMargemDown.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemDown(
                      event.target.value,
                      'temperaturaSet',
                      'temperaturaMargemDown',
                      'temperaturaLimiteDown',
                      'temperaturaMargemUp',
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.TOLERANCIA_TEMPERATURA_MAX}
                  value={Math.round(this.state.temperaturaMargemUp.text * 1000) / 1000}
                  error={!this.state.temperaturaMargemUp.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemUp(
                      event.target.value,
                      'temperaturaSet',
                      'temperaturaMargemUp',
                      'temperaturaLimiteUp',
                      null,
                      'temperaturaMargemDown',
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.LIMITE_TEMPERATURA_MIN}
                  value={Math.round(this.state.temperaturaLimiteDown.text * 1000) / 1000}
                  error={!this.state.temperaturaLimiteDown.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitDown(
                      event.target.value,
                      'temperaturaSet',
                      'temperaturaMargemDown',
                      'temperaturaLimiteDown',
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.LIMITE_TEMPERATURA_MAX}
                  value={Math.round(this.state.temperaturaLimiteUp.text * 1000) / 1000}
                  error={!this.state.temperaturaLimiteUp.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitUp(
                      event.target.value,
                      'temperaturaSet',
                      'temperaturaMargemUp',
                      'temperaturaLimiteUp'
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                />
              </div>
              <div><h4 style={this.styleTitle}>{res.HUMIDADE_UNI.toUpperCase()}</h4></div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.TOLERANCIA_HUMIDADE_MIN}
                  value={Math.round(this.state.UmidadeMargemDown.text * 1000) / 1000}
                  error={!this.state.UmidadeMargemDown.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemDown(
                      event.target.value,
                      'UmidadeSet',
                      'UmidadeMargemDown',
                      'UmidadeLimiteDown',
                      'UmidadeMargemUp',
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.TOLERANCIA_HUMIDADE_MAX}
                  value={Math.round(this.state.UmidadeMargemUp.text * 1000) / 1000}
                  error={!this.state.UmidadeMargemUp.valid}
                  onChange={(event) => {
                    this.changeSettingsMargemUp(
                      event.target.value,
                      'UmidadeSet',
                      'UmidadeMargemUp',
                      'UmidadeLimiteUp',
                      null,
                      'UmidadeMargemDown',
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  label={res.LIMITE_HUMIDADE_MIN}
                  value={Math.round(this.state.UmidadeLimiteDown.text * 1000) / 1000}
                  error={!this.state.UmidadeLimiteDown.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitDown(
                      event.target.value,
                      'UmidadeSet',
                      'UmidadeMargemDown',
                      'UmidadeLimiteDown'
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                  style={{ marginRight: '.5rem' }}
                />
                <TextField
                  label={res.LIMITE_HUMIDADE_MAX}
                  value={Math.round(this.state.UmidadeLimiteUp.text * 1000) / 1000}
                  error={!this.state.UmidadeLimiteUp.valid}
                  onChange={(event) => {
                    this.changeSettingsLimitUp(
                      event.target.value,
                      'UmidadeSet',
                      'UmidadeMargemUp',
                      'UmidadeLimiteUp'
                    );
                  }}
                  size="small"
                  margin="dense"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </form >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">

      <TextFields1 link={link} id={id} res={res} />

    </div>)
}

export default Box;

