import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from '@material-ui/icons/Filter';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { notification } from 'antd';

//import { withTranslation } from "react-i18next";

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, res } = this.props;

    const columnData = [
      { id: 'action', disablePadding: false, label: res.ACAO },
      { id: 'name', disablePadding: true, label: res.NOME },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight: {
    color: '#6c76ff',
    backgroundColor: '#d7d9e0',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, deleteButton, handleChange, filtro, clear, link, res } = props;

  const limpaCampo = () => {
    document.getElementById('campoFiltro').value = '';
    clear();
  }

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} {res.SELECIONADO}
          </Typography>
        ) : (
          <Typography variant="h4" id="tableTitle">
            {'Perfil de Automação'}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={() => deleteButton()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{ width: '14rem' }}>
            <SearchIcon style={{ marginRight: '.5rem', marginTop: '1.5rem' }} />
            <TextField
              placeholder={res.FILTRO}
              margin="normal"
              onChange={handleChange('filtro')}
              id="campoFiltro"
              type="search"
            />
            {(filtro === '') ? <div></div> :

              <MaterialIcon icon="clear" style={{ position: 'absolute', top: '1.7rem', right: '3.7rem', cursor: 'pointer' }} onClick={() => limpaCampo()} />

            }
          </div>
        )}
      </div>
      <div className={classes.actions}>
        <Tooltip title={res.ADICIONAR}>
          <IconButton aria-label={res.ADICIONAR} onClick={event => { link('/app/cadastro/perfil_auto/add') }} >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 600,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  itemSize: {
    fontSize: '.9rem',
  },
});

class EnhancedTable extends React.Component {

  state = {
    order: 'asc',
    orderBy: 'nome',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 10,
    error: null,
    filtro: '',
  };


  componentDidMount() {
    this.updateList();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  clear = () => {
    this.setState({
      filtro: ''
    });
  };

  updateList = () => {
    const apiUrl = Config.server + '/profile_auto';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  deleteUser = (id) => {
    const { selected } = this.state;
    const apiUrl = Config.server + '/profile_auto';
    const formData = new URLSearchParams();
    let descr = '';

    if (isNaN(id)) {
      formData.append('ids', selected);
      descr = this.props.res.ITENS_EXCLUIDOS;
    }
    else {
      formData.append('ids', id);
      descr = this.props.res.ITEM_EXCLUIDO;
    }

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ selected: [] });

          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });

          this.updateList();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  changeAuto = (id) => {
    const apiUrl = `${Config.server}/update_profile_auto/${id}`;
    let descr = '';

    const options = {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ selected: [] });

          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });

          this.updateList();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  updateFilters = event => {
    let listFilter = {
      id: this.state.showModal.id,
      filters: this.state.showModal.filters
    }

    const apiUrl = `${Config.server}/profile_auto_filters`;
    let descr = '';

    const options = {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(listFilter),
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ showModal: false });
          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  clickRule = false;
  editRules = (id) => {
    this.clickRule = true;
    this.setState({ showModal: id });
  };

  newAutoConfig = () => {
    return [
      {
        active: false,
        source: 'set',
        type: 'largura',
        set_min: 15,
        set_max: 25,
      },
      {
        active: false,
        source: 'set',
        type: 'fluxo',
        set_min: 5,
        set_max: 15,
      },
      {
        active: false,
        source: 'set',
        type: 'altura',
        set_min: 1,
        set_max: 5,
      },
      {
        active: false,
        source: 'set',
        type: 'velocidade',
        set_min: 150,
        set_max: 230,
      }
    ]
  };

  handleChangeFilters = (item, type, field) => event => {
    let filter = item.filters.find(item => (item.type === type));
    if (field === 'active') {
      filter[field] = !filter['active'];
    } else if (field === 'source') {
      filter[field] = event.target.value;
    } else {
      filter[field] = Number(event.target.value);
    }
    this.setState({ data: this.state.data });
  };
  handlePerfilChange = (item) => event => {
    item.id_profile = event.target.value;
    this.setState({ data: this.state.data, showModal: false });
  };
  disableFilters = event => {
    if (this.state.showModal.filters) {
      for (let i = 0; i < this.state.showModal.filters.length; i++) {
        const element = this.state.showModal.filters[i];
        element.active = false;
      }
    }
    this.updateFilters();
  };

  autoConfig = (item) => {
    const classes = this.props.classes;
    let defaultValue = this.newAutoConfig();
    if (!item || !item.filters) {
      if (!item) item = {};
      item.filters = defaultValue
    }

    let largura = item.filters.find(item => (item.type === 'largura'));
    let fluxo = item.filters.find(item => (item.type === 'fluxo'));
    let altura = item.filters.find(item => (item.type === 'altura'));
    let velocidade = item.filters.find(item => (item.type === 'velocidade'));

    let style = { position: 'absolute', right: '.5rem', top: '.1rem', padding: '0' };

    const mountItem = (title, filter, type, defaultValue) => {
      return <div style={{ width: '12rem', position: 'relative', margin: '1rem' }}>
        <h4>{title}</h4>
        <Checkbox
          disableRipple
          checked={filter && filter.active}
          onClick={this.handleChangeFilters(item, type, 'active')}
          style={style}
        />
        <RadioGroup disabled={!filter || !filter.active} row aria-label="janela" value={filter.source} onChange={this.handleChangeFilters(item, type, 'source')}>
          <FormControlLabel disabled={!filter || !filter.active} style={{ marginBottom: '-1rem' }} value="set" control={<Radio color="primary" />} label={'Valor Atribuído'} />
          <FormControlLabel disabled={!filter || !filter.active} value="avg" control={<Radio color="primary" />} label={'Valor Médio'} />
        </RadioGroup>
        <TextField
          disabled={!filter || !filter.active}
          label={'Mínimo'}
          value={(filter ? filter : defaultValue).set_min}
          onChange={this.handleChangeFilters(item, type, 'set_min')}
          type="number"
        />
        <TextField
          disabled={!filter || !filter.active} k
          label={'Máximo'}
          value={(filter ? filter : defaultValue).set_max}
          onChange={this.handleChangeFilters(item, type, 'set_max')}
          type="number"
        />
      </div>;
    };
    return <div >
      <div style={{ display: 'flex' }}>
        {mountItem(this.props.res.LARGURA_FAIXA, largura, 'largura', defaultValue[0])}
        {mountItem(this.props.res.FLUXO_APLICACAO + ' (L/ha)', fluxo, 'fluxo', defaultValue[1])}
        {mountItem(this.props.res.ALTURA_VOO, altura, 'altura', defaultValue[2])}
        {mountItem(this.props.res.VELOCIDADE_TITLE, velocidade, 'velocidade', defaultValue[3])}
      </div>
    </div>
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    if (this.clickRule) {
      this.clickRule = false;
      return;
    }

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, link, res } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const editIcon = id => (
      <IconButton onClick={event => (link('/app/cadastro/perfil_auto/edit/' + id))}>
        <EditIcon color="primary" />
      </IconButton>
    );

    return (
      <Paper className={classes.root}>
        <Dialog PaperProps={{ style: { maxWidth: '57rem', width: '100%' } }} onClose={() => { this.setState({ showModal: false }) }} aria-labelledby="simple-dialog-title" aria-describedby="alert-dialog-description" open={this.state.showModal}>          <DialogTitle id="alert-dialog-title">{"Filtros"}</DialogTitle>
          <DialogContent>
            <div >{this.autoConfig(this.state.showModal)}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.disableFilters} color="primary" autoFocus>
              {'Disativar'}
            </Button>
            <Button onClick={this.updateFilters} color="primary" autoFocus>
              {'Aplicar'}
            </Button>
          </DialogActions>
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} deleteButton={this.deleteUser} filtro={this.state.filtro} handleChange={this.handleChange} clear={this.clear} link={link} res={res} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" style={{ tableLayout: 'auto' }}>

            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              res={res}
            />
            <TableBody>
              {data
                .filter((item, key) => {
                  return (
                    item.name.includes(this.state.filtro)
                    && key > -1
                  );
                })
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell className="check" padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      <TableCell
                        className="coluna"
                        padding="checkbox"
                      >
                        <div style={{ display: 'flex' }}>
                          {editIcon(n.id)}
                          {n === data[0] ?
                            <IconButton onClick={event => { this.changeAuto(n.id) }} style={{ marginRight: '1rem' }}>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" className="bi bi-file-earmark-arrow-down-fill" viewBox="-30 -20 300 300">
                                  <g id="_2539151417168" fill={n.auto ? '#3f51b5' : '#dedede'}>
                                    <path className="fil0" d="M133 17.44c58.05,0 106.23,43.9 115.57,101.56l-27.61 0c-8.97,-41.97 -44.93,-73.35 -87.96,-73.35 -49.77,0 -90.11,41.99 -90.11,93.79 0,25.33 9.64,48.3 25.32,65.18l-19.24 19.88c-20.54,-21.98 -33.19,-51.98 -33.19,-85.06 0,-67.38 52.48,-122 117.22,-122z" />
                                    <path className="fil0" d="M57.61 154.63l-42.68 0c-1.63,0 -2.97,-1.33 -2.97,-2.96l0 -24.46c0,-1.63 1.34,-2.96 2.97,-2.96l42.68 0c1.64,0 2.97,1.33 2.97,2.96l0 24.46c0,1.63 -1.33,2.96 -2.97,2.96z" />
                                    <path className="fil0" d="M67.46 95.53l-32.35 -26.76c-1.42,-1.16 -1.62,-3.26 -0.45,-4.67l15.13 -18.3c1.17,-1.41 3.27,-1.61 4.68,-0.45l32.35 26.76c1.41,1.16 1.61,3.26 0.45,4.67l-15.13 18.3c-1.17,1.41 -3.28,1.61 -4.68,0.45z" />
                                    <path className="fil0" d="M146.59 16.83l0 41.44c0,1.98 -1.62,3.59 -3.59,3.59l-23.21 0c-1.98,0 -3.59,-1.61 -3.59,-3.59l0 -41.44c0,-1.98 1.61,-3.59 3.59,-3.59l23.21 0c1.97,0 3.59,1.61 3.59,3.59z" />
                                    <g>
                                      <path className="fil0" d="M119.46 126.91l46.07 -48.13c5.44,-5.69 14.54,-5.89 20.22,-0.44l0.01 0c5.69,5.45 5.88,14.54 0.44,20.23l-46.08 48.13c-5.44,5.69 -14.53,5.88 -20.22,0.44l-0.01 0c-5.68,-5.45 -5.88,-14.54 -0.43,-20.23z" />
                                      <path className="fil0" d="M130.02 114.41c12.39,0 22.42,10.03 22.42,22.42 0,12.39 -10.03,22.43 -22.42,22.43 -12.39,0 -22.42,-10.04 -22.42,-22.43 0,-12.39 10.03,-22.42 22.42,-22.42z" />
                                    </g>
                                    <path className="fil0" d="M219.66 183.43l-19.48 -17.87 -42.87 42.86c-5.16,4.94 -9.72,4.52 -13.8,0l-25.63 -25.63c-6.35,-6.83 -12.86,-7.46 -19.58,0l-53.12 53.12c-5.03,8.19 12.45,23.9 20.39,15.83l35.82 -35.81c4.6,-4.75 9.19,-4.55 13.81,0l26.08 26.08c6.42,6.89 13.02,6.35 19.79,0l58.59 -58.58zm-8.47 -28.53l-21.82 -22.91 61.56 -0.4 0 62.24 -19.33 -21.41 -11.94 11.01 -19.48 -17.87 11.01 -10.66z" />
                                  </g>
                                </svg>
                              </div>
                            </IconButton>
                            : <IconButton onClick={event => { this.editRules(n) }} style={{ marginRight: '1rem' }}>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FilterIcon style={{ color: n.filters && n.filters.find(i => (i.active)) ? '#0000008a' : '#dedede' }} />
                              </div>
                            </IconButton>
                          }
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper >
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const EnhancedTable1 = withStyles(styles)(EnhancedTable);

const Section = (props) => {
  const { link, res } = props;
  return (
    <div className="rowForm">
      <EnhancedTable1 link={link} res={res} />
    </div>)
}

export default Section;
