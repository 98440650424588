import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';
import SvgIcon from '@material-ui/core/SvgIcon';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import L from 'leaflet';
import 'leaflet-draw';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import tools from '../../components/util/tools';
//import geo from '../../components/util/geo';

import { useParams } from "react-router";
import saveAs from 'file-saver';
import jszip from 'jszip';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from '@material-ui/core/Checkbox';

import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import BarHeat from '../../components/heat_graph/barGraph';
import PlaneHeat from '../../components/heat_graph/planeGraph';
import DeltaTHeat from '../../components/heat_graph/deltaTGraph';

//import PlaneAngle from '../../components/custom_compass/planeAngle';

import Tab from '../../components/Tab/local';
import Marks from './../../components/image/img';
import Img_mark from './../../assets/images/aircraft.png';
import Img_up from './../../assets/images/gaugeUp.png';
import Img_down from './../../assets/images/gaugeDown.png';
import Img_fine from './../../assets/images/gaugeOk.png';

import uMarker from './../job_stats/update_marker';
import Slider from '../../components/Slider/barSlider';
import './anime.css';

import SPW from "string-pixel-width";

import Accordion from '../../components/Accordion';
import MapItem from './MapItem';

import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';

Chart.register(zoomPlugin);

class Edit extends React.Component {
  base = {
    updateValuesAnimation: false,
    id_job: null,
    task: null,//localStorage.getItem('check-task') === 'null' || this.props.guid ? null : localStorage.getItem('check-task'),
    progress: false,
    loading: false,
    progressValue: 0,
    title: '...',
    title_sk: '...',
    data: null,
    state: 0,//0=null;1=task;2=processing;3=open
    tiro: 0,
    play: false,
    end: false,
    polygons: [],
    time: 0,
    area: 0,
    correto: 0,
    fora: 0,
    sobre: 0,
    restante: 0,
    timeS: 0,
    areaS: 0,
    corretoS: 0,
    foraS: 0,
    sobreS: 0,
    restanteS: 0,
    sTime: 1,
    tab: '0',
    tabMenu: '2',
    tabSubMenuV: '0',
    tabSubMenuD: '0',
    openTask: false,
    showChart: false,
    chartIndex: 0,
    fastProgress: false,

    showForm: false,

    dateIni: '',
    dateFim: '',

    area_talhoes: 0,
    distancia_percorrida: 0,

    showStack: false,
    listTypes: [
      { type: 'caminho', label: 'Caminho', active: true },
      { type: 'aplicacao', label: 'Aplicação', active: true },
      { type: 'area', label: 'Área', active: true },
      { type: 'name', label: 'Título', active: true }
    ],

    atual_altura: 0,
    count_altura: 0,
    media_altura: 0,
    media_alturaS: 0,
    corretoAltS: 0,
    corretoAlt: 0,
    aceitavelAltSupS: 0,
    aceitavelAltSup: 0,
    aceitavelAltInfS: 0,
    aceitavelAltInf: 0,
    incorretaAltInfS: 0,
    incorretaAltInf: 0,
    incorretaAltSupS: 0,
    incorretaAltSup: 0,

    alturaMargemDown: { value: 1, text: 3, valid: true },
    alturaMargemUp: { value: 1, text: 5, valid: true },
    alturaLimiteDown: { value: 1, text: 2, valid: true },
    alturaLimiteUp: { value: 1, text: 6, valid: true },
    alturaSet: 4,

    count_fluxo: 0,
    media_fluxo: 0,
    media_fluxoS: 0,
    corretoFlxS: 0,
    corretoFlx: 0,
    aceitavelFlxSupS: 0,
    aceitavelFlxSup: 0,
    aceitavelFlxInfS: 0,
    aceitavelFlxInf: 0,
    incorretaFlxInfS: 0,
    incorretaFlxInf: 0,
    incorretaFlxSupS: 0,
    incorretaFlxSup: 0,

    fluxoMargemDown: { value: 2, text: 8, valid: true },
    fluxoMargemUp: { value: 2, text: 12, valid: true },
    fluxoLimiteDown: { value: 3, text: 5, valid: true },
    fluxoLimiteUp: { value: 3, text: 15, valid: true },
    fluxoSet: 10,

    count_velocidade: 0,
    media_velocidade: 0,
    media_velocidadeS: 0,
    corretoVlcS: 0,
    corretoVlc: 0,
    aceitavelVlcSupS: 0,
    aceitavelVlcSup: 0,
    aceitavelVlcInfS: 0,
    aceitavelVlcInf: 0,
    incorretaVlcInfS: 0,
    incorretaVlcInf: 0,
    incorretaVlcSupS: 0,
    incorretaVlcSup: 0,

    velocidadeMargemDown: { value: 10, text: 170, valid: true },
    velocidadeMargemUp: { value: 10, text: 190, valid: true },
    velocidadeLimiteDown: { value: 20, text: 150, valid: true },
    velocidadeLimiteUp: { value: 20, text: 210, valid: true },
    velocidadeSet: 180,

    count_velocidade_vento: 0,
    media_velocidade_vento: 0,
    media_velocidade_ventoS: 0,
    corretoVlcVS: 0,
    corretoVlcV: 0,
    aceitavelVlcVSupS: 0,
    aceitavelVlcVInf: 0,
    incorretaVlcVInfS: 0,
    aceitavelVlcVSup: 0,
    aceitavelVlcVInfS: 0,
    incorretaVlcVInf: 0,
    incorretaVlcVSupS: 0,
    incorretaVlcVSup: 0,

    velocidadeVMargemDown: { value: 8, text: 2, valid: true },
    velocidadeVMargemUp: { value: 8, text: 18, valid: true },
    velocidadeVLimiteDown: { value: 2, text: 0, valid: true },
    velocidadeVLimiteUp: { value: 12, text: 30, valid: true },
    velocidadeVSet: 10,

    count_angulo_vento: 0,
    media_angulo_vento: 0,
    media_angulo_ventoS: 0,
    corretoAngVS: 0,
    corretoAngV: 0,
    aceitavelAngVSupS: 0,
    aceitavelAngVSup: 0,
    aceitavelAngVInfS: 0,
    aceitavelAngVInf: 0,
    incorretaAngVInfS: 0,
    incorretaAngVInf: 0,
    incorretaAngVSupS: 0,
    incorretaAngVSup: 0,

    atual_angulo_vento: -1,
    media_angulo_up: -1,
    media_angulo_down: -1,

    angulo_plane: 0,
    angulo_wind: 0,

    espelharAnguloV: true,
    anguloVMargemDown: { value: 50, text: 50, valid: true },
    anguloVMargemUp: { value: 50, text: 50, valid: true },
    anguloVLimiteDown: { value: 20, text: 70, valid: true },
    anguloVLimiteUp: { value: 20, text: 70, valid: true },
    anguloVSet: 0,

    count_temperatura: 0,
    media_temperatura: 0,
    media_temperaturaS: 0,
    corretoTempVS: 0,
    corretoTemp: 0,
    aceitavelTempSupS: 0,
    aceitavelTempSup: 0,
    aceitavelTempInfS: 0,
    aceitavelTempInf: 0,
    incorretaTempInfS: 0,
    incorretaTempInf: 0,
    incorretaTempSupS: 0,
    incorretaTempSup: 0,

    temperaturaMargemDown: { value: 17.5, text: 5, valid: true },
    temperaturaMargemUp: { value: 17.5, text: 40, valid: true },
    temperaturaLimiteDown: { value: 5, text: 0, valid: true },
    temperaturaLimiteUp: { value: 10, text: 50, valid: true },
    temperaturaSet: 22.5,

    count_Umidade: 0,
    media_Umidade: 0,
    media_UmidadeS: 0,
    corretoUmiVS: 0,
    corretoUmi: 0,
    aceitavelUmiSupS: 0,
    aceitavelUmiSup: 0,
    aceitavelUmiInfS: 0,
    aceitavelUmiInf: 0,
    incorretaUmiInfS: 0,
    incorretaUmiInf: 0,
    incorretaUmiSupS: 0,
    incorretaUmiSup: 0,

    UmidadeMargemDown: { value: 37.5, text: 15, valid: true },
    UmidadeMargemUp: { value: 37.5, text: 90, valid: true },
    UmidadeLimiteDown: { value: 5, text: 10, valid: true },
    UmidadeLimiteUp: { value: 10, text: 100, valid: true },
    UmidadeSet: 52.5,

    count_DeltaT: 0,
    media_DeltaT: 0,
    media_DeltaTS: 0,
    corretoDeltaTVS: 0,
    corretoDeltaT: 0,
    aceitavelDeltaTSupS: 0,
    aceitavelDeltaTSup: 0,
    aceitavelDeltaTInfS: 0,
    aceitavelDeltaTInf: 0,
    incorretaDeltaTInfS: 0,
    incorretaDeltaTInf: 0,
    incorretaDeltaTSupS: 0,
    incorretaDeltaTSup: 0,

    larguraSet: 20,
    larguraDefault: 0,
    alturaDefault: 0,
    fluxoDefault: 0,
    velocidadeDefault: 0,

    cultura: '',
    nome: '',
    cliente: '',
    clients: [],
    piloto: '',
    pilots: [],
    aeronave: '',
    planes: [],
    gCultura: '',
    gNome: '',
    gCliente: '',
    gPiloto: '',
    gAeronave: '',
    gAcerto: 0,
    gFalha: 0,
    gPerda: 0,
    gUniformidade: 0,

    gfluxo: 0,
    gAltura: 0,
    gVelocidade: 0,
    gVelocidadeV: 0,
    gDirecaoV: 0,
    gDeltaT: 0,
    gTemperatura: 0,
    gHumidade: 0,

    tabMenuItens: [
      { label: this.props.res.INFORMACAO, value: '0', active: false },
      { label: this.props.res.ANIMACAO, value: '1', active: false },
      { label: this.props.res.CONFIGURACAO, value: '2', active: true },
    ],
    tabItens: [
      { label: this.props.res.APLICACAO, value: '0', active: true },
      { label: this.props.res.ALTURA, value: '1', active: false },
      { label: this.props.res.FLUXO, value: '2', active: false },
      { label: this.props.res.VELOCIDADE_TAB, value: '3', active: false },
      { label: 'Vento', value: '4', active: false },
      { label: 'Delta-T', value: '5', active: false },
    ],
    tabItensVento: [
      { label: 'Velocidade', value: '0', active: true },
      { label: 'Direcao', value: '1', active: true },
    ],
    tabItensDeltaT: [
      { label: 'Delta-T', value: '0', active: true },
      { label: 'Temperatura', value: '1', active: true },
      { label: 'Umidade', value: '2', active: true },
    ],

    fonstes: [],
    fonteClima: 0,
    tipoFonte: 'EXTERNO',

    legend: {
      area: {
        data1: 0,
        data2: 0,
        data3: 0,
      },
      aplicacao: {
        data1: 0,
        data2: 0,
        data3: 0,
      },
      altura: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      fluxo: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      velocidade: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      velocidadeVento: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      direcaoVento: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
        data6: 0,
        data7: 0,
        data8: 0,
        data9: 0,
        data10: 0,
      },
      temperatura: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      humidade: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      },
      deltaT: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        data5: 0,
      }
    },
    limitWeather: 0,
    limitConsumption: 0,
    showLimit: true,
    checkConfig: [false, false, false, false, false],
    itensTask: [],
    clear: false,
    new: { msg: this.props.res.NOVO_ANALISE_CONFIRM, func: this.clear },

    ruler_a: false,
    showDeltat: false,
    //teste: false,
    //teste2: true
  };

  state = JSON.parse(JSON.stringify(this.base));

  drawCreateToolbar = null;
  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  //itens animacao
  featureGroupI = null;//icone
  featureGroupL = null;//linha rota do voo
  featureGroupPoly = null;//polygon da area
  fGAPP = {
    '0': null,//poly da aplicacao
    '1': null,//poly da altura da plicacao
    '2': null,//poly do fluxo da aplicacao
    '3': null,//poly do velocidade da aplicacao
    '4': null,//poly do velocidade do vento da aplicacao
    '5': null,//poly do direcao do vento da aplicacao
    '6': null,//poly do deltaT da aplicacao
    '7': null,//poly do temperatura da aplicacao
    '8': null,//poly do humidade da aplicacao
  }
  //itens static
  featureGroupLS = null;//linha rota do voo
  featureGroupPolyS = null;//polygon da area
  fGAPPS = {
    '0': null,//poly da aplicacao
    '1': null,//poly da altura da plicacao
    '2': null,//poly do fluxo da aplicacao
    '3': null,//poly do velocidade da aplicacao
    '4': null,//poly do velocidade do vento da aplicacao
    '5': null,//poly do direcao do vento da aplicacao
    '6': null,//poly do deltaT da aplicacao
    '7': null,//poly do temperatura da aplicacao
    '8': null,//poly do humidade da aplicacao
  }

  selectedItem = null;

  featureGroupTemp = null;//polygon da area

  featureAtual = {
    caminho: null,
    aplicacao: null,
    area: null,
    //icone: null
  }

  polyNames = {};
  polygonsDefault = [];

  marker = {
    base: null,
    marker: null,
    angle: 45
  };
  marker_heat = null;
  run = true;
  current_run = 0;
  new_run = 0;
  iPlay = 1;
  line = null;

  altura = [];
  fluxo = [];
  velocidade = [];
  velocidadeVento = [];
  direcaoVento = [];
  deltaT = [];
  temperatura = [];
  humidade = [];

  alturaS = [];
  fluxoS = [];
  velocidadeS = [];
  velocidadeVentoS = [];
  direcaoVentoS = [];
  deltaTS = [];
  temperaturaS = [];
  humidadeS = [];

  pieChart = {
    aplicacao: null,
    area: null,
    altura: null,
    fluxo: null,
    velocidade: null,
    velocidadeVento: null,
    direcaoVento: null,
    deltaT: null,
    temperatura: null,
    humidade: null,
  };

  pieChartData = { info: null, anime: null };
  barChart = null;
  selectedPath = null;

  lineClick = false;

  typeMapItem = 'analise';//[analise, retoque]

  styles = {
    area: {
      color: '#47839e',
      weight: 4,
      opacity: 0.5,
      fillOpacity: 0.1,
    },
    linha: {
      color: '#d0d0d0',
      weight: 1,
      opacity: .9,
      smoothFactor: 1,
      className: 'caminho',
    },
    sobre: {
      color: '#0d1763',
      fillOpacity: .5,
      opacity: 0,
      className: 'aplicacao',
    },
    fora: {
      color: '#ffd500',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao',
    },
    correto: {
      color: '#32a852',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao',
    },
    area_static: {
      color: '#eb8c34',
      opacity: 0,
      fillOpacity: 1,
    },
    sobre_static: {
      color: '#0d1763',
      light_color: '#467580',
      fillOpacity: .5,
      opacity: 0,
      className: 'aplicacao',
    },
    fora_static: {
      color: '#ffd500',
      fillOpacity: 1,
      opacity: 0,
      className: 'aplicacao',
    },
    correto_static: {
      color: '#32a852',
      light_color: '#26b54d',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao',
    },
    stripColor: {
      correto: '#5db445',
      aceitavelInf: '#f1da0f',
      aceitavelSup: '#6dbfbf',
      incorretaInf: '#e74c3c',
      incorretaSup: '#a536f5'
    }
  };

  acc = {};

  p = {};

  componentDidMount() {
    this.boot();
    uMarker(L);
    this.getAuto('pilot', 'pilots');
    this.getAuto('plane', 'planes');
    this.getAuto('client', 'clients');
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      this.run = false;
      return;
    };
  };

  boot = () => {
    let local_ini = Config.place;
    navigator.geolocation.getCurrentPosition((position) => {
      if (!this.state.polygons) {
        this.setState({ lat: this.round_coord(position.coords.latitude), lng: this.round_coord(position.coords.longitude) });
        this.mymap.setView([this.round_coord(position.coords.latitude), this.round_coord(position.coords.longitude)]);
      }
    });

    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(local_ini, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupTemp = L.featureGroup();
    this.featureGroupTemp.addTo(this.mymap);

    //static
    this.featureGroupLS = L.featureGroup();
    this.fGAPPS['0'] = L.featureGroup();
    this.fGAPPS['1'] = L.featureGroup();
    this.fGAPPS['2'] = L.featureGroup();
    this.fGAPPS['3'] = L.featureGroup();
    this.fGAPPS['4'] = L.featureGroup();
    this.fGAPPS['5'] = L.featureGroup();
    this.fGAPPS['6'] = L.featureGroup();
    this.fGAPPS['7'] = L.featureGroup();
    this.fGAPPS['8'] = L.featureGroup();

    this.featureGroupPolyS = L.featureGroup();

    //animacao
    this.featureGroupI = L.featureGroup();
    this.featureGroupL = L.featureGroup();
    this.fGAPP['0'] = L.featureGroup();
    this.fGAPP['1'] = L.featureGroup();
    this.fGAPP['2'] = L.featureGroup();
    this.fGAPP['3'] = L.featureGroup();
    this.fGAPP['4'] = L.featureGroup();
    this.fGAPP['5'] = L.featureGroup();
    this.fGAPP['6'] = L.featureGroup();
    this.fGAPP['7'] = L.featureGroup();
    this.fGAPP['8'] = L.featureGroup();
    this.featureGroupPoly = L.featureGroup();

    this.mymap.createPane("marks").style.zIndex = 651;

    this.mymap.on('click', () => {
      this.showBarChart(false);
    });

    this.mymap.on('zoomend', () => {
      this.updateWidth(this.selectedItem);
    });

    this.mymap.on('mouseup', e => {
      if (this.state.ruler_a && !this.mymap.dragging._enabled) {
        this.mymap.dragging.enable();
        if (this.ruler_p.second.lat === null) {
          this.drawCreateToolbar.addVertex(e.latlng);
        }
      }
    });

    this.mymap.on('draw:drawvertex', (e) => {
      if (this.drawCreateToolbar.type === 'polyline' && this.state.ruler_a) {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

    this.createPieChart('pieChart1', 'aplicacao', this.state.tabMenu);
    this.createPieChart('pieChart2', 'area', this.state.tabMenu);
    this.createPieChart('pieChart3', 'altura', this.state.tabMenu);
    this.createPieChart('pieChart4', 'fluxo', this.state.tabMenu);
    this.createPieChart('pieChart5', 'velocidade', this.state.tabMenu);
    this.createPieChart('pieChart6', 'velocidadeVento', this.state.tabMenu);
    this.createPieChart('pieChart7', 'direcaoVento', this.state.tabMenu);
    this.createPieChart('pieChart8', 'deltaT', this.state.tabMenu);
    this.createPieChart('pieChart9', 'temperatura', this.state.tabMenu);
    this.createPieChart('pieChart10', 'humidade', this.state.tabMenu);
    this.createBarChart();

    if (this.state.task) {
      this.getTask(this.state.task);
    } else if (this.props.guid) {
      this.checkJob();

    }
    this.loadTask(false);
  };

  useType = (type) => {
    if (type !== 'N') {
      this.p = {
        bearing: 'bearing', tempo: 'tempo', size: 'size', size_cross: 'size_cross', is_out: 'is_out',
        restante: 'restante', setAltura: 'setAltura', setFluxo: 'setFluxo', setLargura: 'setLargura',
        setVelocidade: 'setVelocidade', setVelocidadeV: 'setVelocidadeV', setDirecaoV: 'setDirecaoV',
        setTemperatura: 'setTemperatura', setUmidade: 'setUmidade', iApp: 'iApp', iSobre: 'iSobre',
        iFora: 'iFora', iLatlng: 'iLatlng', iAltura: 'iAltura', iFluxo: 'iFluxo', iVelocidade: 'iVelocidade',
        iVelocidadeV: 'iVelocidadeV', iDirecaoV: 'iDirecaoV', iTemperatura: 'iTemperatura', iHumidade: 'iHumidade',
      }
    } else {
      this.p = {
        bearing: 0, tempo: 1, size: 2, size_cross: 3, is_out: 4,
        restante: 5, setAltura: 6, setFluxo: 7, setLargura: 8,
        setVelocidade: 9, setVelocidadeV: 10, setDirecaoV: 11,
        setTemperatura: 12, setUmidade: 13, iApp: 14, iSobre: 15,
        iFora: 16, iLatlng: 17, iAltura: 18, iFluxo: 19, iVelocidade: 20,
        iVelocidadeV: 21, iDirecaoV: 22, iTemperatura: 23, iHumidade: 24,
      }
    }
  }

  updateWidth = (item) => {
    if (item) {
      for (let i = 0; i < item.length; i++) {
        if (item[i]) {
          let opt = { weight: item[i].largura * 2 ** (this.mymap.getZoom() - 17) };
          if (item[i].item.options) {
            item[i].item.setStyle(opt);
          } else {
            for (let index = 0; index < item[i].item.length; index++) {
              item[i].item[index].setStyle(opt);
            }
          }
        }
      }
    }
  };

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeTab = name => (event, newValue) => {
    let value = newValue ? newValue : event.target.value;
    let repeat = this.state[name] === newValue;
    this.setState({
      [name]: value,
    }, () => {
      this.showBarChart(false);
      if (!repeat && (name === 'tab' || name === 'tabSubMenuV' || name === 'tabSubMenuD')) {
        this.changeType(value);
        this.setState({ showChart: false });
      } else if (!repeat && name === 'tabMenu') {
        this.play(false);
        this.changeTabMenu(newValue);
        this.changeType(this.state.tab);
      }
    });

  };

  handleCheck = id => (event) => {
    let temp = this.state.checkConfig;
    temp[id] = !temp[id];
    this.setState({
      checkConfig: temp,
    });
  };

  changeTabMenu = (type) => {
    if (type === '0' || type === '2') {
      this.updateMap('area', this.featureGroupPolyS);
      this.updateMap('caminho', this.featureGroupLS);
      this.updateMap('aplicacao', this.fGAPPS[this.state.tab]);

      this.mymap.removeLayer(this.featureGroupI);

      this.play(false);
      if (this.state.updateValuesAnimation) this.showStaticData(true);
      this.updateWidth(this.selectedItem);
    } else if (type === '1') {
      this.updateMap('area', this.featureGroupPoly);
      this.updateMap('caminho', this.featureGroupL);
      this.updateMap('aplicacao', this.fGAPP[this.state.tab]);

      this.featureGroupI.addTo(this.mymap);

      if (this.state.updateValuesAnimation) this.showExe(true);
      this.showBarChart(false);
    }
  };

  changeSettingsMargemDown = (value, setValue, margemDown, limiteDown, margemUp) => {
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemUp = this.state[margemUp];
    if (margemUp) {
      temp_setValue = (Number(temp_margemUp.text) - Number(value)) / 2 + Number(value);
    }
    let temp_margemDown = this.state[margemDown];
    temp_margemDown.text = value;
    if (!isNaN(value) &&
      Number(value) >= 0 &&
      (temp_setValue - Number(value)) >= 0) {
      temp_margemDown.valid = true;
      temp_margemDown.value = (temp_setValue - Number(value));
      let temp_limiteDown = this.state[limiteDown];
      let temp = (temp_setValue - this.state[margemDown].value - this.state[limiteDown].value);
      if (temp < 0) {
        temp_limiteDown.value = temp === 0 ? 0 : this.state[limiteDown].value + temp;
        if (temp_limiteDown.valid) temp_limiteDown.text = value - temp_limiteDown.value;
      }
      temp_limiteDown.text = (temp_setValue - temp_margemDown.value - temp_limiteDown.value);
      let update = { [margemDown]: temp_margemDown, [limiteDown]: temp_limiteDown, [setValue]: temp_setValue };
      if (margemUp) {
        temp_margemUp.value = Number(temp_margemUp.text) - temp_setValue;
        update[margemUp] = temp_margemUp;
      }
      this.setState(update);
    } else {
      temp_margemDown.valid = false;
      this.setState({ [margemDown]: temp_margemDown });
    }
  }

  changeSettingsMargemUp = (value, setValue, margemUp, limiteUp, limitMax, margemDown) => {
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemDown = this.state[margemDown];
    if (margemDown) {
      temp_setValue = (Number(value) - Number(temp_margemDown.text)) / 2 + Number(temp_margemDown.text);
    }
    let temp_margemUp = this.state[margemUp];
    temp_margemUp.text = value;
    if (!isNaN(value) &&
      ((limitMax && (temp_setValue + Number(value)) <= limitMax) || !limitMax) &&
      (Number(value) - temp_setValue) >= 0) {
      temp_margemUp.value = (Number(value) - temp_setValue);
      temp_margemUp.valid = true;
      let temp_limiteUp = this.state[limiteUp];
      if (limitMax && (temp_setValue + temp_margemUp.value + temp_limiteUp.value) > limitMax) {
        temp_limiteUp.value = limitMax - temp_margemUp.value;
      }
      temp_limiteUp.text = temp_setValue + temp_margemUp.value + temp_limiteUp.value;
      let update = { [margemUp]: temp_margemUp, [limiteUp]: temp_limiteUp, [setValue]: temp_setValue };
      if (margemDown) {
        temp_margemDown.value = temp_setValue - Number(temp_margemDown.text);
        update[margemDown] = temp_margemDown;
      }
      this.setState(update);
    } else {
      temp_margemUp.valid = false;
      this.setState({ [margemUp]: temp_margemUp });
    }
  }

  changeSettingsLimitDown = (value, setValue, margemDown, limiteDown) => {
    let temp_limitDown = this.state[limiteDown];
    temp_limitDown.text = value;
    if (!isNaN(value) &&
      Number(value) >= 0 &&
      Number(value) <= (Number(this.state[setValue]) - Number(this.state[margemDown].value))) {
      temp_limitDown.value = (Number(this.state[setValue]) - Number(this.state[margemDown].value) - Number(value))
      temp_limitDown.text = value;
      temp_limitDown.valid = true;
      this.setState({ [limiteDown]: temp_limitDown });
    } else {
      temp_limitDown.valid = false;
      this.setState({ [limiteDown]: temp_limitDown });
    }
  }

  changeSettingsLimitUp = (value, setValue, margemUp, limiteUp, limitMax) => {
    let temp_value = Number(value);
    let temp_setValue = Number(this.state[setValue]);
    let temp_margemUp = Number(this.state[margemUp].value);
    let temp_limiteUp = this.state[limiteUp];
    temp_limiteUp.text = value;
    if (!isNaN(value) &&
      ((limitMax && (temp_value) <= limitMax) || !limitMax) &&
      (temp_value) >= (temp_margemUp + temp_setValue)) {

      temp_limiteUp.value = (temp_value - (temp_setValue + temp_margemUp));
      temp_limiteUp.valid = true;
      this.setState({ [limiteUp]: temp_limiteUp });
    } else {
      temp_limiteUp.valid = false;
      this.setState({ [limiteUp]: temp_limiteUp });
    }
  }
  updateUrl = (guid) => {
    window.history.replaceState(null, "Travicar", "/app/qualidade/" + guid);
  };

  checkJob = () => {
    this.setState({ progress: true }, () => {

      let guid = this.props.guid;
      let apiUrl = Config.server + '/job_check/analise/' + guid;
      let method = 'GET';

      let options = {
        method: method,
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.task && result.task.id) {
              if (result.task.status === 'Processing') {
                this.setState({ progress: false });
                this.loadTask(true)
              } else {
                this.getTask(result.task.id, result.data);
              }
            } else {
              this.setState({ progress: false });
            }
            if (result.job && result.job.id) {
              this.setState({ id_job: result.job.id, title: result.job.title });
            }
            if (result.guid_link) {
              this.updateUrl(result.guid_link);
            }
          },
          (error) => {
            this.setState({ progress: false });
            if (error.status === 405) {
              notification.open({
                message: this.props.res.PERMISSAO,
                description: this.props.res.PERMISSAO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              this.props.link('/login');
            } else if (error.status === 400) {
              error.json().then(
                (errCode) => {
                  notification.open({
                    message: this.props.res[errCode.code],
                    icon: <MaterialIcon icon="error" className="text-danger" />
                  });
                });
            } else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatC')) {
      dF.classList.toggle("div-listFloatC");
    } else {
      dF.classList.remove("div-listFloatC");
    }
  };

  showGoto = () => {
    if (this.state.goto) {
      this.setState({ goto: false });
    } else {
      this.setState({ goto: true, lat: this.round_coord(this.mymap.getCenter().lat), lng: this.round_coord(this.mymap.getCenter().lng) });
    }
  };
  goto = (asd) => {
    this.setState({ goto: false });
    this.mymap.setView([this.state.lat, this.state.lng]);
  };
  round_coord = (coord) => {
    return coord.toFixed(7);
  };

  handleStackClick = (tipo) => {

  };

  changeType = (type) => {
    let item = null, id = null;
    if (((this.state.tab === '4' && type === '0') || (this.state.tabSubMenuV === '0' && type === '4')) && !this.acc["divVen"]) {
      id = '4';
      document.getElementById("divVen").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.velocidadeVentoS : this.velocidadeVento;
    } else if (((this.state.tab === '4' && type === '1') || (this.state.tabSubMenuV === '1' && type === '4')) && !this.acc["divVenD"]) {
      id = '5';
      document.getElementById("divVenD").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.direcaoVentoS : this.direcaoVento;
    } else if (((this.state.tab === '5' && type === '0') || (this.state.tabSubMenuD === '0' && type === '5')) && !this.acc["divDeltaT"]) {
      id = '6';
      document.getElementById("divDeltaT").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.deltaTS : this.deltaT;
    } else if (((this.state.tab === '5' && type === '1') || (this.state.tabSubMenuD === '1' && type === '5')) && !this.acc["divTemp"]) {
      id = '7';
      document.getElementById("divTemp").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.temperaturaS : this.temperatura;
    } else if (((this.state.tab === '5' && type === '2') || (this.state.tabSubMenuD === '2' && type === '5')) && !this.acc["divHum"]) {
      id = '8';
      document.getElementById("divHum").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.humidadeS : this.humidade;
    } else if (type === '1' && !this.acc["divAlt"]) {
      id = '1';
      document.getElementById("divAlt").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.alturaS : this.altura;
    } else if (type === '2' && !this.acc["divFlo"]) {
      id = '2';
      document.getElementById("divFlo").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.fluxoS : this.fluxo;
    } else if (type === '3' && !this.acc["divVel"]) {
      id = '3';
      document.getElementById("divVel").scrollIntoView({ behavior: "smooth", block: "start" });
      item = this.state.tabMenu === '0' || this.state.tabMenu === '2' ? this.velocidadeS : this.velocidade;
    } else if (type === '0' && !this.acc["divApp"]) {
      id = '0';
      document.getElementById("divApp").scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (item) {
      this.selectedItem = item;
      this.updateWidth(this.selectedItem);
    }
    if (id) {
      if (this.state.tabMenu === '0' || this.state.tabMenu === '2') {
        this.updateMap('aplicacao', this.fGAPPS[id]);
      } else {
        this.updateMap('aplicacao', this.fGAPP[id]);
      }
    }
  };

  //upload de arquivos TXT e zjob
  loadFile = file => {
    let filePath = file.target.files[0];
    document.getElementById('contained-button-file').value = '';
    if (filePath) {
      let ext = fileName => fileName.split(".").pop();

      if (ext(filePath.name.toLowerCase()) === 'zjob') {
        this.uploadFile(filePath);
      }
    }
  };

  uploadFile = (file) => {
    this.setState({ progress: true });

    let apiUrl = Config.server + '/analise';
    let method = 'POST';

    const formData = new FormData();//URLSearchParams

    formData.append('file', file, 'teste.file');

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.task) {
            let id = result.task;
            result.task = { id: id };
            this.useData(result);
          } else {
            this.setState({ progress: false });
            notification.open({
              message: this.props.res.ARQUIVO_INVALIDO,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 400) {
            notification.open({
              message: this.props.res[error.statusText],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  useData = (result) => {
    const id_job = this.state.id_job
    this.clear(true, () => {
      localStorage.setItem('check-task', result.task);

      let alturaValue = result.data.alturaSet;
      let alturaMargemDown = this.state.alturaMargemDown;
      alturaMargemDown.text = alturaValue - alturaMargemDown.value;
      let alturaMargemUp = this.state.alturaMargemUp;
      alturaMargemUp.text = alturaValue + alturaMargemUp.value;
      let alturaLimiteDown = this.state.alturaLimiteDown;
      alturaLimiteDown.text = alturaValue - alturaMargemDown.value - alturaLimiteDown.value;
      let alturaLimiteUp = this.state.alturaLimiteUp;
      alturaLimiteUp.text = alturaValue + alturaMargemUp.value + alturaLimiteUp.value;

      let fluxoValue = result.data.fluxoSet;
      let fluxoMargemDown = this.state.fluxoMargemDown;
      fluxoMargemDown.text = fluxoValue - fluxoMargemDown.value;
      let fluxoMargemUp = this.state.fluxoMargemUp;
      fluxoMargemUp.text = fluxoValue + fluxoMargemUp.value;
      let fluxoLimiteDown = this.state.fluxoLimiteDown;
      fluxoLimiteDown.text = fluxoValue - fluxoMargemDown.value - fluxoLimiteDown.value;
      let fluxoLimiteUp = this.state.fluxoLimiteUp;
      fluxoLimiteUp.text = fluxoValue + fluxoMargemUp.value + fluxoLimiteUp.value;

      let velocidadeValue = result.data.velocidadeSet;
      let velocidadeMargemDown = this.state.velocidadeMargemDown;
      velocidadeMargemDown.text = velocidadeValue - velocidadeMargemDown.value;
      let velocidadeMargemUp = this.state.velocidadeMargemUp;
      velocidadeMargemUp.text = velocidadeValue + velocidadeMargemUp.value;
      let velocidadeLimiteDown = this.state.velocidadeLimiteDown;
      velocidadeLimiteDown.text = velocidadeValue - velocidadeMargemDown.value - velocidadeLimiteDown.value;
      let velocidadeLimiteUp = this.state.velocidadeLimiteUp;
      velocidadeLimiteUp.text = velocidadeValue + velocidadeMargemUp.value + velocidadeLimiteUp.value;

      let checkConfig = this.state.checkConfig;
      checkConfig[4] = result.data.weather.length > 0;

      let polys = [];
      for (let i = 0; i < result.data.shapes.length; i++) {
        const element = result.data.shapes[i];

        polys.push({
          guid: element.guid,
          name: element.name,
          points: element.latlngarray,
          type: element.type,
        });
      }
      this.polygonsDefault = polys;
      //this.onCloseEditMap(polys, false);
      let consumption_clima_api = 0;
      let limit_clima_api = 0;
      if (result.data.compConfig) {
        consumption_clima_api = result.data.compConfig.consumption_clima_api;
        limit_clima_api = result.data.compConfig.limit_clima_api;
      }
      let tabMenuItens = this.state.tabMenuItens;
      if (limit_clima_api > consumption_clima_api && result.data.weather.length === 0) {
        tabMenuItens[0].active = false;
      }
      this.setState({
        task: result.task.id,
        title_sk: result.data.title,
        larguraSet: result.data.larguraSet,
        alturaSet: alturaValue,
        alturaMargemDown: alturaMargemDown,
        alturaMargemUp: alturaMargemUp,
        alturaLimiteDown: alturaLimiteDown,
        alturaLimiteUp: alturaLimiteUp,
        fluxoSet: fluxoValue,
        fluxoMargemDown: fluxoMargemDown,
        fluxoMargemUp: fluxoMargemUp,
        fluxoLimiteDown: fluxoLimiteDown,
        fluxoLimiteUp: fluxoLimiteUp,
        velocidadeSet: velocidadeValue,
        velocidadeMargemDown: velocidadeMargemDown,
        velocidadeMargemUp: velocidadeMargemUp,
        velocidadeLimiteDown: velocidadeLimiteDown,
        velocidadeLimiteUp: velocidadeLimiteUp,
        larguraDefault: result.data.larguraSet,
        alturaDefault: alturaValue,
        fluxoDefault: fluxoValue,
        velocidadeDefault: velocidadeValue,

        cliente: result.data.cliente,
        aeronave: result.data.aeronave,

        fonstes: result.data.weather,
        limitWeather: limit_clima_api,
        limitConsumption: consumption_clima_api,
        tabMenuItens,
        checkConfig: checkConfig,
        tipoFonte: checkConfig[4] ? 'ESTACAO' : 'EXTERNO',

        state: 1,
        progress: false
      }, this.getAreas(id_job));
    });
  };

  play = (go) => {
    if (go && !this.state.play) {
      if (this.state.end) {
        this.setState({ end: false, tiro: 0 });
        this.featureGroupL.clearLayers();
        this.featureAtual.aplicacao.clearLayers();

        if (this.marker.marker) {
          this.featureGroupI.removeLayer(this.marker.marker);
        }

        this.exe(this.state.file);
      } else {
        this.pathMap(this.state.data, this.iPlay, this.size);
      }
    }
    this.setState({ play: go });
  };

  polyMap = (poly) => {
    let myStyle = this.styles.area;
    let points = poly.poly;
    if (!points)
      points = JSON.parse(poly.points);
    let myLayer = L.polygon(points, myStyle);
    myLayer._leaflet_id = poly.guid;
    myLayer.addTo(this.featureGroupPoly);
    if (this.state.state === 1 || this.state.state === 0) {
      myLayer.bindTooltip(poly.name,
        { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' }
      ).openTooltip();
    }
  };
  restante = 0;//TODO: fix para a animage
  montaStaticData = (result) => {
    let tabMenuItens = this.state.tabMenuItens;
    tabMenuItens[0].active = true;
    tabMenuItens[1].active = true;

    let tabItens = this.state.tabItens;
    tabItens[1].active = result.altura.length > 0 ? true : false;
    tabItens[2].active = result.fluxo.length > 0 ? true : false;
    tabItens[3].active = result.velocidade.length > 0 ? true : false;

    let tabItensVento = this.state.tabItensVento;
    tabItensVento[0].active = result.vento_velocidade.length > 0 ? true : false;
    tabItensVento[1].active = result.vento_angulo.length > 0 ? true : false;

    let tabItensDeltaT = this.state.tabItensDeltaT;
    tabItensDeltaT[1].active = result.temperatura.length > 0 ? true : false;
    tabItensDeltaT[2].active = result.humidade.length > 0 ? true : false;
    tabItensDeltaT[0].active = tabItensDeltaT[1].active && tabItensDeltaT[2].active;

    tabItens[4].active = tabItensVento[0].active || tabItensVento[1].active;
    tabItens[5].active = tabItensDeltaT[0].active || tabItensDeltaT[1].active || tabItensDeltaT[2].active;

    /*
    Índice de acerto: Aplicada interna / Aplicada * 100
    Índice de falha: (Talhão - (Aplicada interna -Sobreposta interna)) / Talhão * 100
    Índice de perda: (Sobreposta interna + (Aplicada - Aplicada interna - Aplicação carreador)) / Aplicada * 100
    Índice de uniformidade: (Aplicada interna - Sobreposta interna) / Talhão * 100
     */
    this.restante = result.total.restante;//TODO: fix para a animage
    const restanteCalc = result.total.restante - result.total.in + result.total.over;
    const cfluxo = result.total.fluxo.correto + (result.total.fluxo.aceitavel.up + result.total.fluxo.aceitavel.down) / 2;
    const tfluxo = result.total.fluxo.correto + result.total.fluxo.aceitavel.up + result.total.fluxo.aceitavel.down + result.total.fluxo.inaceitavel.up + result.total.fluxo.inaceitavel.down;
    const cAltura = result.total.altura.correto + (result.total.altura.aceitavel.up + result.total.altura.aceitavel.down) / 2;
    const tAltura = result.total.altura.correto + result.total.altura.aceitavel.up + result.total.altura.aceitavel.down + result.total.altura.inaceitavel.up + result.total.altura.inaceitavel.down;
    const cVelocidade = result.total.velocidade.correto + (result.total.velocidade.aceitavel.up + result.total.velocidade.aceitavel.down) / 2;
    const tVelocidade = result.total.velocidade.correto + result.total.velocidade.aceitavel.up + result.total.velocidade.aceitavel.down + result.total.velocidade.inaceitavel.up + result.total.velocidade.inaceitavel.down;
    const cVelocidadeV = result.total.vento_velocidade.correto + (result.total.vento_velocidade.aceitavel.up + result.total.vento_velocidade.aceitavel.down) / 2;
    const tVelocidadeV = result.total.vento_velocidade.correto + result.total.vento_velocidade.aceitavel.up + result.total.vento_velocidade.aceitavel.down + result.total.vento_velocidade.inaceitavel.up + result.total.vento_velocidade.inaceitavel.down;
    const cDirecaoV = (result.total.vento_angulo.correto.left + result.total.vento_angulo.correto.right) + (result.total.vento_angulo.aceitavel.left.up + result.total.vento_angulo.aceitavel.left.down) + (result.total.vento_angulo.aceitavel.right.up + result.total.vento_angulo.aceitavel.right.down) / 2;
    const tDirecaoV = (result.total.vento_angulo.correto.left + result.total.vento_angulo.correto.right) + (result.total.vento_angulo.aceitavel.left.up + result.total.vento_angulo.aceitavel.left.down + result.total.vento_angulo.inaceitavel.left.up + result.total.vento_angulo.inaceitavel.left.down) + (result.total.vento_angulo.aceitavel.right.up + result.total.vento_angulo.aceitavel.right.down + result.total.vento_angulo.inaceitavel.right.up + result.total.vento_angulo.inaceitavel.right.down);
    const cDeltaT = result.total.deltaT.correto + (result.total.deltaT.aceitavel.up + result.total.deltaT.aceitavel.down) / 2;
    const tDeltaT = result.total.deltaT.correto + result.total.deltaT.aceitavel.up + result.total.deltaT.aceitavel.down + result.total.deltaT.inaceitavel.up + result.total.deltaT.inaceitavel.down;
    const cTemperatura = result.total.temperatura.correto + (result.total.temperatura.aceitavel.up + result.total.temperatura.aceitavel.down) / 2;
    const tTemperatura = result.total.temperatura.correto + result.total.temperatura.aceitavel.up + result.total.temperatura.aceitavel.down + result.total.temperatura.inaceitavel.up + result.total.temperatura.inaceitavel.down;
    const cHumidade = result.total.humidade.correto + (result.total.humidade.aceitavel.up + result.total.humidade.aceitavel.down) / 2;
    const tHumidade = result.total.humidade.correto + result.total.humidade.aceitavel.up + result.total.humidade.aceitavel.down + result.total.humidade.inaceitavel.up + result.total.humidade.inaceitavel.down;


    let temp_uni = Math.round((result.total.in - result.total.over) / result.total.area * 100);
    let temp_falha = Math.round((result.total.area - (result.total.in - result.total.over)) / result.total.area * 100);
    this.setState({
      polygons: result.polygons,
      timeS: result.total.tempo,
      areaS: result.total.app,
      corretoS: result.total.in,
      foraS: result.total.out,
      sobreS: result.total.over,

      restanteS: (restanteCalc > 0) ? restanteCalc : 0,
      tabMenu: '0',
      tabMenuItens: tabMenuItens,
      tabItens: tabItens,
      tabItensVento: tabItensVento,
      tabItensDeltaT: tabItensDeltaT,
      tabSubMenuD: tabItensDeltaT[0].active ? '0' : '1',
      gAcerto: Math.round(result.total.in / result.total.app * 100),
      gFalha: temp_falha < 100 ? temp_falha : 100,
      gPerda: Math.round((result.total.over + (result.total.app - result.total.in)) / result.total.app * 100),
      gUniformidade: temp_uni < 100 ? temp_uni : 100,

      gfluxo: Math.round(cfluxo / tfluxo * 100),
      gAltura: Math.round(cAltura / tAltura * 100),
      gVelocidade: Math.round(cVelocidade / tVelocidade * 100),
      gVelocidadeV: Math.round(cVelocidadeV / tVelocidadeV * 100),
      gDirecaoV: Math.round(cDirecaoV / tDirecaoV * 100),
      gDeltaT: Math.round(cDeltaT / tDeltaT * 100),
      gTemperatura: Math.round(cTemperatura / tTemperatura * 100),
      gHumidade: Math.round(cHumidade / tHumidade * 100),
    });


    this.updatePieChart([result.total.in - result.total.over, result.total.out, result.total.over], 'aplicacao', '0');
    this.updatePieChart([result.total.in - result.total.over, (restanteCalc > 0) ? restanteCalc : 0, result.total.over], 'area', '0');
    this.updatePieChart([
      result.total.altura.correto,
      result.total.altura.aceitavel.down,
      result.total.altura.aceitavel.up,
      result.total.altura.inaceitavel.down,
      result.total.altura.inaceitavel.up
    ], 'altura', '0');
    this.updatePieChart([
      result.total.fluxo.correto,
      result.total.fluxo.aceitavel.down,
      result.total.fluxo.aceitavel.up,
      result.total.fluxo.inaceitavel.down,
      result.total.fluxo.inaceitavel.up
    ], 'fluxo', '0');
    this.updatePieChart([
      result.total.velocidade.correto,
      result.total.velocidade.aceitavel.down,
      result.total.velocidade.aceitavel.up,
      result.total.velocidade.inaceitavel.down,
      result.total.velocidade.inaceitavel.up
    ], 'velocidade', '0');
    this.updatePieChart([
      result.total.vento_velocidade.correto,
      result.total.vento_velocidade.aceitavel.down,
      result.total.vento_velocidade.aceitavel.up,
      result.total.vento_velocidade.inaceitavel.down,
      result.total.vento_velocidade.inaceitavel.up
    ], 'velocidadeVento', '0');
    this.updatePieChart([
      result.total.vento_angulo ? result.total.vento_angulo.correto.left + result.total.vento_angulo.correto.right : 0,
      result.total.vento_angulo ? result.total.vento_angulo.aceitavel.left.down + result.total.vento_angulo.aceitavel.right.down : 0,
      result.total.vento_angulo ? result.total.vento_angulo.aceitavel.left.up + result.total.vento_angulo.aceitavel.right.up : 0,
      result.total.vento_angulo ? result.total.vento_angulo.inaceitavel.left.down + result.total.vento_angulo.inaceitavel.right.down : 0,
      result.total.vento_angulo ? result.total.vento_angulo.inaceitavel.left.up + result.total.vento_angulo.inaceitavel.right.up : 0
    ], 'direcaoVento', '0');
    this.updatePieChart([
      result.total.deltaT.correto,
      result.total.deltaT.aceitavel.down + result.total.deltaT.aceitavel.up,
      0,
      result.total.deltaT.inaceitavel.down + result.total.deltaT.inaceitavel.up,
      0,
    ], 'deltaT', '0');
    this.updatePieChart([
      result.total.temperatura.correto,
      result.total.temperatura.aceitavel.down,
      result.total.temperatura.aceitavel.up,
      result.total.temperatura.inaceitavel.down,
      result.total.temperatura.inaceitavel.up
    ], 'temperatura', '0');
    this.updatePieChart([
      result.total.humidade.correto,
      result.total.humidade.aceitavel.down,
      result.total.humidade.aceitavel.up,
      result.total.humidade.inaceitavel.down,
      result.total.humidade.inaceitavel.up
    ], 'humidade', '0');

    for (let i = 0; i < result.polygons.length; i++) {
      const element = result.polygons[i];
      let poly = JSON.parse(element.points);

      let myStyle = this.styles.area_static;
      let myLayer = L.polygon(poly, myStyle);

      this.polyNames[element.guid + '_h'] = element.name;
      myLayer._leaflet_id = element.guid + '_h';

      //myLayer.addTo(this.fGAPPS['0']); //fG de aplicacao
      //myLayer.addTo(this.fGAPPS['1']); //fG de altura
      //myLayer.addTo(this.fGAPPS['2']); //fG de fluxo
      //myLayer.addTo(this.fGAPPS['3']); //fG de velocidade
      //myLayer.addTo(this.fGAPPS['4']); //fG de velocidade vento
      //myLayer.addTo(this.fGAPPS['5']); //fG de direcao vento
      //myLayer.addTo(this.fGAPPS['6']); //fG de deltaT
      //myLayer.addTo(this.fGAPPS['7']); //fG de temperatura
      //myLayer.addTo(this.fGAPPS['8']); //fG de humidade

      myLayer = L.polygon(poly, this.styles.area);
      this.polyNames[element.guid] = element.name;
      myLayer._leaflet_id = element.guid;
      myLayer.addTo(this.featureGroupPolyS);//fG de area

      myLayer.bindTooltip(element.name,
        { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' }
      ).openTooltip();
    }

    //linha do caminho
    let line = new L.polyline(result.path, this.styles.linha);
    line.addTo(this.featureGroupLS);
    //polygon de aplicacao correto
    for (let i = 0; i < result.app.length; i++) {
      const element = result.app[i];
      if (element.right.points.length > 1) {
        let right = JSON.parse(JSON.stringify(element.right.points));
        right.reverse();
        let left = JSON.parse(JSON.stringify(element.left.points));
        const latlng = left.concat(right);
        const poly = L.polygon(latlng, this.styles.correto_static);
        poly._leaflet_id = `ctiro${i}`;
        if (poly) {
          const pop = this.props.res.AREA + ': ' + (L.GeometryUtil.geodesicArea(poly.getLatLngs()[0]) / 10000).toFixed(3) + ' ha';
          poly.bindPopup(pop);
        }
        poly.addTo(this.fGAPPS['0']);
      }
    }
    //polygon de aplicacao fora da area
    for (let i = 0; i < result.fora.length; i++) {
      const element = result.fora[i];
      if (element.right.points.length > 1) {
        let right = JSON.parse(JSON.stringify(element.right.points));
        right.reverse();
        let left = JSON.parse(JSON.stringify(element.left.points));
        const latlng = left.concat(right);
        const poly = L.polygon(latlng, this.styles.fora_static);
        poly._leaflet_id = `ftiro${i}`;
        if (poly) {
          const pop = this.props.res.AREA + ': ' + (L.GeometryUtil.geodesicArea(poly.getLatLngs()[0]) / 10000).toFixed(3) + ' ha';
          poly.bindPopup(pop);
        }
        poly.addTo(this.fGAPPS['0']);
      }
    }
    //polygon de aplicacao sobreposicao
    for (let i = 0; i < result.sobre.length; i++) {
      const element = result.sobre[i];
      if (element.right_points.length > 1) {
        const latlng = element.left_points.concat(element.right_points);
        const poly = L.polygon(latlng, this.styles.sobre_static);
        poly._leaflet_id = `stiro${i}`;
        if (poly) {
          const pop = this.props.res.AREA + ': ' + (L.GeometryUtil.geodesicArea(poly.getLatLngs()[0]) / 10000).toFixed(3) + ' ha';
          poly.bindPopup(pop);

          /*poly.on('click', () => {
            console.log('right',right.map(i => ([i[1],i[0]])));
            console.log('left',left.map(i => ([i[1],i[0]])));
          });*/
        }
        poly.addTo(this.fGAPPS['0']);
      }
    }
    //polygon de area nao aplicada
    if (result.missing) {
      for (let i = 0; i < result.missing.length; i++) {
        const element = result.missing[i];
        const poly = L.polygon(element.points, this.styles.area_static);
        //let myLayer = L.geoJSON(element.poly, { style: this.styles.area_static });
        if (poly) {
          const pop = this.props.res.AREA + ': ' + (element.area / 10000).toFixed(3) + ' ha';
          poly.bindPopup(pop);
        }
        poly.addTo(this.fGAPPS['0']);
      }
    }

    this.updateMap('area', this.featureGroupPolyS);
    this.updateMap('aplicacao', this.fGAPPS['0']);
    //this.featureGroupLS.addTo(this.mymap);
    this.updateMap('caminho', this.featureGroupLS);

    //polygon de altura da aplicacao 
    this.mapLines(result.altura, 'altura', this.alturaS, '1');

    //polygon de fluxo da aplicacao 
    this.mapLines(result.fluxo, 'fluxo', this.fluxoS, '2');

    //polygon de velocidade da aplicacao 
    this.mapLines(result.velocidade, 'velocidade', this.velocidadeS, '3');

    //polygon de velocidade do vento da aplicacao 
    this.mapLines(result.vento_velocidade, 'velocidadeVento', this.velocidadeVentoS, '4');

    //polygon de velocidade do vento da aplicacao 
    this.mapLines(result.vento_angulo, 'direcaoVento', this.direcaoVentoS, '5');
    //polygon de velocidade do vento da aplicacao 
    this.mapLines(result.deltaT, 'deltaT', this.deltaTS, '6');
    //polygon de velocidade do vento da aplicacao 
    this.mapLines(result.temperatura, 'temperatura', this.temperaturaS, '7');
    //polygon de velocidade do vento da aplicacao 
    this.mapLines(result.humidade, 'humidade', this.humidadeS, '8');


    this.mymap.fitBounds(this.featureGroupLS.getBounds());
  };

  mapLines = (itens, type, rvar, fg) => {
    rvar.length = 0;
    for (let i = 0; i < itens.length; i++) {
      let valores = { index: i, setValue: [], value: [], color: [], b: [], type: type };
      if (type === 'direcaoVento') {
        valores.plane_direction = [];
        valores.wind_direction = [];
      }
      let line = [];
      for (let j = 0; j < itens[i].item.length; j++) {
        const element = itens[i].item[j];
        const last = L.polyline(element.points, {
          color: element.color,
          weight: element.weight * 2 ** (this.mymap.getZoom() - 17),
          lineCap: 'butt',
          opacity: 1,
          smoothFactor: 1
        });
        rvar.push({ index: rvar.length, item: [last], largura: element.weight });
        for (let k = 0; k < element.valor.length; k++) {
          valores.setValue.push(element.setValor[k]);
          valores.value.push(element.valor[k]);
          valores.color.push(element.color);
          valores.b.push('');
          if (type === 'direcaoVento') {
            if (element.plane_direction) {
              valores.plane_direction.push(element.plane_direction[k]);
            }
            if (element.wind_direction) {
              valores.wind_direction.push(element.wind_direction[k]);
            } else {
              valores.wind_direction.push(element.direcao[k]);
            }
          }
        }
        last._leaflet_id = `vtiro/${i}/${j}`;
        line.push(last);
        last.on("click", () => {
          this.showBarChart(true, line, valores);
        });
        last.addTo(this.fGAPPS[fg]);
      }
    }
  }

  showStaticData = (show) => {
    if (show) {
      this.updatePieChart([this.state.corretoS - this.state.sobreS, this.state.foraS, this.state.sobreS], 'aplicacao', '0');
      this.updatePieChart([this.state.corretoS - this.state.sobreS, this.state.restanteS, this.state.sobreS], 'area', '0');
      this.updatePieChart([
        this.state.corretoAltS,
        this.state.aceitavelAltInfS,
        this.state.aceitavelAltSupS,
        this.state.incorretaAltInfS,
        this.state.incorretaAltSupS
      ], 'altura', '0');
      this.updatePieChart([
        this.state.corretoFlxS,
        this.state.aceitavelFlxInfS,
        this.state.aceitavelFlxSupS,
        this.state.incorretaFlxInfS,
        this.state.incorretaFlxSupS
      ], 'fluxo', '0');
      this.updatePieChart([
        this.state.corretoVlcS,
        this.state.aceitavelVlcInfS,
        this.state.aceitavelVlcSupS,
        this.state.incorretaVlcInfS,
        this.state.incorretaVlcSupS
      ], 'velocidade', '0');
    }
  };

  lineAltura = null;
  lineFluxo = null;
  lineVelocidade = null;
  lineVelocidadeVento = null;
  lineDirecaoVento = null;
  lineDeltaT = null;
  lineTemperatura = null;
  lineHumidade = null;
  pathMap = (path, i) => {
    let fastProgress = 0;
    let size = 0;
    let temp_size = this.state.area;
    let size_sobre = this.state.sobre;
    let temp_size_correto = this.state.correto + size_sobre;
    let temp_size_fora = this.state.fora;
    let polyCorrect = null;
    let polyFora = null;
    let polySobre = [];

    const run = (path, i, size) => {
      let { pontos, app, fora, sobre, altura, fluxo, velocidade, vento_velocidade, vento_angulo/*, deltaT, temoeratura, humidade*/ } = path;
      let line = path.path;
      let stateUpdate = {};
      let stateUpdateRun;

      if (!line || line.length === 0) {
        this.play(false);
        //this.setState({ end: true });
        return;
      }
      let pTime = Date.now();
      this.iPlay = i;
      if (i < line.length - 2) {
        let to = line[i + 1];
        let time = (pontos[i + 1][this.p.tempo] - pontos[i][this.p.tempo]) > 2 ? 200 : (pontos[i + 1][this.p.tempo] - pontos[i][this.p.tempo]) * 1000;
        if (time / this.state.sTime > 0) {
          this.moveMarker(to, time / this.state.sTime, pontos[i][this.p.bearing]);
        }
        pTime = (Date.now() - pTime);
        let point = line[i + 1];
        this.line.setLatLngs(this.line._latlngs.concat([point]));

        if (pontos[line[i + 1][2]][this.p.iApp].length > 0 || pontos[line[i + 1][2]][this.p.iFora].length > 0) {
          temp_size = size + pontos[line[i + 1][2]][this.p.size];

          //monta linha altura, fluxo e velocidade
          if (!this.lineAltura || !this.lineFluxo || !this.lineVelocidade) {
            this.lineAltura = { index: this.altura.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.altura.length + 1) };
            this.altura.push(this.lineAltura);
            this.lineFluxo = { index: this.fluxo.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.fluxo.length + 1) };
            this.fluxo.push(this.lineFluxo);
            this.lineVelocidade = { index: this.velocidade.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.velocidade.length + 1) };
            this.velocidade.push(this.lineVelocidade);
            this.lineVelocidadeVento = { index: this.velocidadeVento.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.velocidadeVento.length + 1) };
            this.velocidadeVento.push(this.lineVelocidadeVento);
            this.lineDirecaoVento = { index: this.direcaoVento.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.direcaoVento.length + 1) };
            this.direcaoVento.push(this.lineDirecaoVento);
            this.lineTemperatura = { index: this.temperatura.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.temperatura.length + 1) };
            this.temperatura.push(this.lineTemperatura);
            this.lineHumidade = { index: this.humidade.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.humidade.length + 1) };
            this.humidade.push(this.lineHumidade);
            this.lineDeltaT = { index: this.deltaT.length, item: [], largura: pontos[line[i + 1][2]][this.p.setLargura], title: this.props.res.TIRO + ' ' + (this.deltaT.length + 1) };
            this.deltaT.push(this.lineDeltaT);
            point = [point, point];
          }
          //monta polygon aplicacao
          if (pontos[line[i + 1][2]][this.p.iApp].length > 0) {
            let appIndex = pontos[line[i + 1][2]][this.p.iApp];
            let appPointLeft = app[appIndex[0]].left.points[appIndex[1]];
            let appPointRight = app[appIndex[0]].right.points[appIndex[1]];
            polyCorrect = null;
            if (polyFora) {
              let tempArr = polyFora._latlngs;
              tempArr[0].unshift(new L.LatLng(appPointLeft[0], appPointLeft[1]));
              tempArr[0].push(new L.LatLng(appPointRight[0], appPointRight[1]));
              polyFora.setLatLngs(tempArr);
            } else {
              if (appIndex[1] > 0) {
                let firstForaPointLeft = app[appIndex[0]].left.points[appIndex[1] - 1];
                let firstForaPointRight = app[appIndex[0]].right.points[appIndex[1] - 1];
                let tempArr = [new L.LatLng(firstForaPointLeft[0], firstForaPointLeft[1])];
                tempArr.push(new L.LatLng(firstForaPointRight[0], firstForaPointRight[1]));
                tempArr.unshift(new L.LatLng(appPointLeft[0], appPointLeft[1]));
                tempArr.push(new L.LatLng(appPointRight[0], appPointRight[1]));
                polyFora = this.aplMapCorrect(tempArr);
              }
            }
            temp_size_correto = temp_size - temp_size_fora;
            if (polyFora) {
              const pop = this.props.res.AREA + ': ' + temp_size_correto.toFixed(2) + ' ha';
              polyFora.setPopupContent(pop);
            }
          } else {
            let foraIndex = pontos[line[i + 1][2]][this.p.iFora];
            let foraPointLeft = fora[foraIndex[0]].left.points[foraIndex[1]];
            let foraPointRight = fora[foraIndex[0]].right.points[foraIndex[1]];
            polyFora = null;
            if (polyCorrect) {
              let tempArr = polyCorrect._latlngs;
              tempArr[0].unshift(new L.LatLng(foraPointLeft[0], foraPointLeft[1]));
              tempArr[0].push(new L.LatLng(foraPointRight[0], foraPointRight[1]));
              polyCorrect.setLatLngs(tempArr);
            } else {
              if (foraIndex[1] > 0) {
                let firstForaPointLeft = fora[foraIndex[0]].left.points[foraIndex[1] - 1];
                let firstForaPointRight = fora[foraIndex[0]].right.points[foraIndex[1] - 1];
                let tempArr = [new L.LatLng(firstForaPointLeft[0], firstForaPointLeft[1])];
                tempArr.push(new L.LatLng(firstForaPointRight[0], firstForaPointRight[1]));
                tempArr.unshift(new L.LatLng(foraPointLeft[0], foraPointLeft[1]));
                tempArr.push(new L.LatLng(foraPointRight[0], foraPointRight[1]));
                polyCorrect = this.aplMapFora(tempArr);
              }
            }
            temp_size_fora = temp_size - temp_size_correto;
            if (polyCorrect) {
              const pop = this.props.res.AREA + ': ' + temp_size_fora.toFixed(2) + ' ha';
              polyCorrect.setPopupContent(pop);
            }
          }
          if (pontos[line[i][2]][this.p.iSobre].length > 0) {
            //monta polygon de sobreposicao aplicacao
            let temp_polySobre = [];
            for (let k = 0; k < pontos[line[i][2]][this.p.iSobre].length; k++) {
              let item_sobre = sobre[pontos[line[i][2]][this.p.iSobre][k][0]];
              let current_polySobre = polySobre.filter((item) => item.faixa === item_sobre.faixa);

              let tempArr = (current_polySobre.length > 0) ? current_polySobre[0].poly._latlngs : [[]];

              for (let j = pontos[line[i][2]][this.p.iSobre][k][1]; j < item_sobre.left_points.length; j++) {
                const points_over = item_sobre.left_points[j];
                tempArr[0].push(new L.LatLng(points_over[0], points_over[1]));
                if (points_over[2] !== item_sobre.left_points[pontos[line[i][2]][this.p.iSobre][k][1]][2]) break;
              }
              let pass = false;
              for (let j = 0; j < item_sobre.right_points.length; j++) {
                const points_over = item_sobre.right_points[j];
                if (points_over[2] === item_sobre.left_points[pontos[line[i][2]][this.p.iSobre][k][1]][2]) {
                  tempArr[0].unshift(new L.LatLng(points_over[0], points_over[1]));
                  pass = true;
                }
                if ((points_over[2] < item_sobre.left_points[pontos[line[i][2]][this.p.iSobre][k][1]][2]) ||
                  (pass && points_over[2] !== item_sobre.left_points[pontos[line[i][2]][this.p.iSobre][k][1]][2])) break;
              }

              if (current_polySobre.length > 0) {
                current_polySobre[0].poly.setLatLngs(tempArr);
                temp_polySobre.push(current_polySobre[0]);
              } else {
                let p = this.aplMapsobre(tempArr);
                temp_polySobre.push({ faixa: item_sobre.faixa, poly: p });

                const pop = this.props.res.AREA + ': ' + size_sobre.toFixed(2) + ' ha';
                p.setPopupContent(pop);
              }

              let temp_size = pontos[line[i][2]][this.p.size_cross].filter((item) => (item.faixa === item_sobre.faixa_cross));
              if (temp_size.length > 0) {
                size_sobre += temp_size[0].size;
              }
            }
            polySobre = temp_polySobre;
          } else {
            polySobre = [];
          }
          if (this.state.updateValuesAnimation) {
            this.updatePieChart([temp_size_correto - size_sobre, temp_size_fora, size_sobre], 'aplicacao', this.state.tabMenu);
            this.updatePieChart([temp_size_correto - size_sobre, this.restante - temp_size_correto, size_sobre], 'area', this.state.tabMenu);
          }
          //altura
          if (altura.length > 0) {
            let alturaIndex = pontos[line[i + 1][2]][this.p.iAltura];
            if (alturaIndex.length > 0) {
              this.lineAltura.setValue = pontos[line[i + 1][2]][this.p.setAltura];
              if (!this.lineAltura.value) { this.lineAltura.value = [] };
              let tempAltura = Math.round(altura[alturaIndex[0]].item[alturaIndex[1]].valor[alturaIndex[3]] * 10) / 10;
              this.lineAltura.value.push(tempAltura);
              this.lineAltura.fGAPP_ID = '1';
              this.updateColorPath(this.lineAltura, point, this.lineAltura.setValue, tempAltura, altura[alturaIndex[0]].item[alturaIndex[1]].color, pontos[line[i + 1][2]][this.p.setLargura], this.altura.length - 1);

              if (this.state.updateValuesAnimation) {
                stateUpdate.count_altura = this.state.count_altura + 1;
                stateUpdate.atual_altura = tempAltura;
                stateUpdate.media_altura = this.state.media_altura === 0 ? tempAltura : ((this.state.media_altura * this.state.count_altura) + tempAltura) / (this.state.count_altura + 1);

                let min = this.state.alturaSet - this.state.alturaMargemDown.value - this.state.alturaLimiteDown.value;
                let minT = this.state.alturaSet - this.state.alturaMargemDown.value;
                let maxT = this.state.alturaSet + this.state.alturaMargemUp.value;
                let max = this.state.alturaSet + this.state.alturaMargemUp.value + this.state.alturaLimiteUp.value;

                if (tempAltura >= minT && tempAltura <= maxT) stateUpdate.corretoAlt = this.state.corretoAlt + (temp_size / 10000);
                else if (tempAltura >= min && tempAltura < minT) stateUpdate.aceitavelAltInf = this.state.aceitavelAltInf + (temp_size / 10000);
                else if (tempAltura > maxT && tempAltura <= max) stateUpdate.aceitavelAltSup = this.state.aceitavelAltSup + (temp_size / 10000);
                else if (tempAltura < min) stateUpdate.incorretaAltInf = this.state.incorretaAltInf + (temp_size / 10000);
                else if (tempAltura > max) stateUpdate.incorretaAltSup = this.state.incorretaAltSup + (temp_size / 10000);

                this.updatePieChart([
                  this.state.corretoAlt,
                  this.state.aceitavelAltInf,
                  this.state.aceitavelAltSup,
                  this.state.incorretaAltInf,
                  this.state.incorretaAltSup
                ], 'altura', this.state.tabMenu);
              }
            }
          }
          //fluxo
          if (fluxo.length > 0) {
            let fluxoIndex = pontos[line[i + 1][2]][this.p.iFluxo];
            if (fluxoIndex.length > 0) {
              this.lineFluxo.setValue = pontos[line[i + 1][2]][this.p.setFluxo];
              if (!this.lineFluxo.value) { this.lineFluxo.value = [] };
              let tempFluxo = Math.round(fluxo[fluxoIndex[0]].item[fluxoIndex[1]].valor[fluxoIndex[3]] * 10) / 10;
              this.lineFluxo.value.push(tempFluxo);
              this.lineFluxo.fGAPP_ID = '2';
              this.updateColorPath(this.lineFluxo, point, this.lineFluxo.setValue, tempFluxo, fluxo[fluxoIndex[0]].item[fluxoIndex[1]].color, pontos[line[i + 1][2]][this.p.setLargura], this.fluxo.length - 1);

              if (this.state.updateValuesAnimation) {
                stateUpdate.count_fluxo = this.state.count_fluxo + 1;
                stateUpdate.atual_fluxo = tempFluxo;
                stateUpdate.media_fluxo = this.state.media_fluxo === 0 ? tempFluxo : ((this.state.media_fluxo * this.state.count_fluxo) + tempFluxo) / (this.state.count_fluxo + 1);

                let min = this.state.fluxoSet - this.state.fluxoMargemDown.value - this.state.fluxoLimiteDown.value;
                let minT = this.state.fluxoSet - this.state.fluxoMargemDown.value;
                let maxT = this.state.fluxoSet + this.state.fluxoMargemUp.value;
                let max = this.state.fluxoSet + this.state.fluxoMargemUp.value + this.state.fluxoLimiteUp.value;

                if (tempFluxo >= minT && tempFluxo <= maxT) stateUpdate.corretoFlx = this.state.corretoFlx + (temp_size / 1000);
                else if (tempFluxo > max) stateUpdate.incorretaFlxSup = this.state.incorretaFlxSup + (temp_size / 1000);
                else if (tempFluxo > maxT) stateUpdate.aceitavelFlxSup = this.state.aceitavelFlxSup + (temp_size / 1000);
                else if (tempFluxo < min) stateUpdate.incorretaFlxInf = this.state.incorretaFlxInf + (temp_size / 1000);
                else if (tempFluxo < minT) stateUpdate.aceitavelFlxInf = this.state.aceitavelFlxInf + (temp_size / 1000);

                this.updatePieChart([
                  this.state.corretoFlx,
                  this.state.aceitavelFlxInf,
                  this.state.aceitavelFlxSup,
                  this.state.incorretaFlxInf,
                  this.state.incorretaFlxSup
                ], 'fluxo', this.state.tabMenu);
              }
            }
          }
          //velocidade
          if (velocidade.length > 0) {
            let velocidadeIndex = pontos[line[i + 1][2]][this.p.iVelocidade];
            if (velocidadeIndex.length > 0) {
              this.lineVelocidade.setValue = pontos[line[i + 1][2]][this.p.setVelocidade];
              if (!this.lineVelocidade.value) { this.lineVelocidade.value = [] };
              let tempVelocidade = Math.round(velocidade[velocidadeIndex[0]].item[velocidadeIndex[1]].valor[velocidadeIndex[3]] * 10) / 10;
              this.lineVelocidade.value.push(tempVelocidade);
              this.lineVelocidade.fGAPP_ID = '3';
              this.updateColorPath(this.lineVelocidade, point, this.lineVelocidade.setValue, tempVelocidade, velocidade[velocidadeIndex[0]].item[velocidadeIndex[1]].color, pontos[line[i + 1][2]][this.p.setLargura], this.velocidade.length - 1);

              if (this.state.updateValuesAnimation) {
                stateUpdate.count_velocidade = this.state.count_velocidade + 1;
                stateUpdate.atual_velocidade = tempVelocidade;
                stateUpdate.media_velocidade = this.state.media_velocidade === 0 ? tempVelocidade : ((this.state.media_velocidade * this.state.count_velocidade) + tempVelocidade) / (this.state.count_velocidade + 1);

                let min = this.state.velocidadeSet - this.state.velocidadeMargemDown.value - this.state.velocidadeLimiteDown.value;
                let minT = this.state.velocidadeSet - this.state.velocidadeMargemDown.value;
                let maxT = this.state.velocidadeSet + this.state.velocidadeMargemUp.value;
                let max = this.state.velocidadeSet + this.state.velocidadeMargemUp.value + this.state.velocidadeLimiteUp.value;

                if (tempVelocidade >= minT && tempVelocidade <= maxT) stateUpdate.corretoVlc = this.state.corretoVlc + (temp_size / 1000);
                else if (tempVelocidade > max) stateUpdate.incorretaVlcSup = this.state.incorretaVlcSup + (temp_size / 1000);
                else if (tempVelocidade > maxT) stateUpdate.aceitavelVlcSup = this.state.aceitavelVlcSup + (temp_size / 1000);
                else if (tempVelocidade < min) stateUpdate.incorretaVlcInf = this.state.incorretaVlcInf + (temp_size / 1000);
                else if (tempVelocidade < minT) stateUpdate.aceitavelVlcInf = this.state.aceitavelVlcInf + (temp_size / 1000);

                this.updatePieChart([
                  this.state.corretoVlc,
                  this.state.aceitavelVlcInf,
                  this.state.aceitavelVlcSup,
                  this.state.incorretaVlcInf,
                  this.state.incorretaVlcSup
                ], 'velocidade', this.state.tabMenu);
              }
            }
          }
          //velocidade do vento
          if (vento_velocidade.length > 0) {
            let velocidadeVIndex = pontos[line[i + 1][2]][this.p.iVelocidadeV];
            if (velocidadeVIndex.length > 0) {
              this.lineVelocidadeVento.setValue = 0;//pontos[line[i + 1][2]].setVelocidade;
              if (!this.lineVelocidadeVento.value) { this.lineVelocidadeVento.value = [] };
              let tempVelocidadeVento = Math.round(vento_velocidade[velocidadeVIndex[0]].item[velocidadeVIndex[1]].valor[velocidadeVIndex[3]] * 10) / 10;
              this.lineVelocidadeVento.value.push(tempVelocidadeVento);
              this.lineVelocidadeVento.fGAPP_ID = '4';
              this.updateColorPath(this.lineVelocidadeVento, point, this.lineVelocidadeVento.setValue, tempVelocidadeVento,
                vento_velocidade[velocidadeVIndex[0]].item[velocidadeVIndex[1]].color, pontos[line[i + 1][2]][this.p.setLargura], this.velocidadeVento.length - 1);

              if (this.state.updateValuesAnimation) {
                stateUpdate.count_velocidade_vento = this.state.count_velocidade_vento + 1;
                stateUpdate.atual_velocidade_vento = tempVelocidadeVento;
                stateUpdate.media_velocidade_vento = this.state.media_velocidade_vento === 0 ?
                  tempVelocidadeVento :
                  ((this.state.media_velocidade_vento * this.state.count_velocidade_vento) + tempVelocidadeVento) / (this.state.count_velocidade_vento + 1);

                let min = this.state.velocidadeVSet - this.state.velocidadeVMargemDown.value - this.state.velocidadeVLimiteDown.value;
                let minT = this.state.velocidadeVSet - this.state.velocidadeVMargemDown.value;
                let maxT = this.state.velocidadeVSet + this.state.velocidadeVMargemUp.value;
                let max = this.state.velocidadeVSet + this.state.velocidadeVMargemUp.value + this.state.velocidadeVLimiteUp.value;

                if (tempVelocidadeVento >= minT && tempVelocidadeVento <= maxT)
                  stateUpdate.corretoVlcV = this.state.corretoVlcV + (temp_size / 1000);
                else if (tempVelocidadeVento > max)
                  stateUpdate.incorretaVlcVSup = this.state.incorretaVlcVSup + (temp_size / 1000);
                else if (tempVelocidadeVento > maxT)
                  stateUpdate.aceitavelVlcVSup = this.state.aceitavelVlcVSup + (temp_size / 1000);
                else if (tempVelocidadeVento < min)
                  stateUpdate.incorretaVlcVInf = this.state.incorretaVlcVInf + (temp_size / 1000);
                else if (tempVelocidadeVento < minT)
                  stateUpdate.aceitavelVlcVInf = this.state.aceitavelVlcVInf + (temp_size / 1000);

                this.updatePieChart([
                  stateUpdate.corretoVlcV,
                  stateUpdate.aceitavelVlcVInf,
                  stateUpdate.aceitavelVlcVSup,
                  stateUpdate.incorretaVlcVInf,
                  stateUpdate.incorretaVlcVSup
                ], 'velocidadeVento', this.state.tabMenu);
              }
            }
          }

          //direcao do vento
          if (vento_angulo.length > 0) {
            let direcaoVIndex = pontos[line[i + 1][2]][this.p.iDirecaoV];
            if (direcaoVIndex.length > 0) {
              this.lineDirecaoVento.setValue = 0;//pontos[line[i + 1][2]].setVelocidade;
              if (!this.lineDirecaoVento.value) { this.lineDirecaoVento.value = [] };
              let tempDirecaoVento = Math.round(vento_angulo[direcaoVIndex[0]].item[direcaoVIndex[1]].valor[direcaoVIndex[3]] * 10) / 10;
              this.lineDirecaoVento.value.push(tempDirecaoVento);
              this.lineDirecaoVento.fGAPP_ID = '5';
              this.updateColorPath(this.lineDirecaoVento, point, this.lineDirecaoVento.setValue, tempDirecaoVento, vento_angulo[direcaoVIndex[0]].item[direcaoVIndex[1]].color, pontos[line[i + 1][2]][this.p.setLargura], this.direcaoVento.length - 1);

              if (this.state.updateValuesAnimation) {
                stateUpdate.windAngle = vento_angulo[direcaoVIndex[0]].item[direcaoVIndex[1]].direcao[direcaoVIndex[3]];
                stateUpdate.planeAngle = pontos[i][this.p.bearing];

                stateUpdate.count_angulo_vento = this.state.count_angulo_vento + 1;
                stateUpdate.atual_velocidade_vento = tempDirecaoVento;
                stateUpdate.media_angulo_vento = this.state.media_angulo_vento === 0 ?
                  tempDirecaoVento :
                  ((this.state.media_angulo_vento * this.state.count_angulo_vento) + tempDirecaoVento) / (this.state.count_angulo_vento + 1);

                let min = this.state.anguloVSet - this.state.anguloVMargemDown.value - this.state.anguloVLimiteDown.value;
                let minT = this.state.anguloVSet - this.state.anguloVMargemDown.value;
                let maxT = this.state.anguloVSet + this.state.anguloVMargemUp.value;
                let max = this.state.anguloVSet + this.state.anguloVMargemUp.value + this.state.anguloVLimiteUp.value;

                if (tempDirecaoVento >= minT && tempDirecaoVento <= maxT)
                  stateUpdate.corretoAngV = this.state.corretoAngV + (temp_size / 1000);
                else if (tempDirecaoVento > max)
                  stateUpdate.incorretaAngVSup = this.state.incorretaAngVSup + (temp_size / 1000);
                else if (tempDirecaoVento > maxT)
                  stateUpdate.aceitavelAngVSup = this.state.aceitavelAngVSup + (temp_size / 1000);
                else if (tempDirecaoVento < min)
                  stateUpdate.incorretaAngVInf = this.state.incorretaAngVInf + (temp_size / 1000);
                else if (tempDirecaoVento < minT)
                  stateUpdate.aceitavelAngVInf = this.state.aceitavelAngVInf + (temp_size / 1000);

                this.updatePieChart([
                  this.state.corretoAngV,
                  this.state.aceitavelAngVInf,
                  this.state.aceitavelAngVSup,
                  this.state.incorretaAngVInf,
                  this.state.incorretaAngVSup
                ], 'direcaoVento', this.state.tabMenu);
              }
            }
          }

          if (this.state.updateValuesAnimation) {
            stateUpdate.area = temp_size;
            stateUpdate.fora = temp_size_fora;
            stateUpdate.correto = temp_size_correto - size_sobre;
            stateUpdate.sobre = size_sobre;
            stateUpdate.atual_angulo_vento = pontos[i][this.p.bearing];///TODO: mudar para o angulo do vento*

            stateUpdateRun = () => {
              if (this.state.showChart) {
                if (this.state.tab === '1') {
                  this.updateBarChart(null);
                } else if (this.state.tab === '2') {
                  this.updateBarChart(null);
                } else if (this.state.tab === '3') {
                  this.updateBarChart(null);
                } else if (this.state.tab === '4') {
                  this.updateBarChart(null);
                }
              }
            };
          }
        } else {
          if (this.state.fastProgress) {
            if (++fastProgress <= 5) {
              run(path, ++i, size);
              return;
            } else {
              fastProgress = 0;
            }
          }
          polyFora = null;
          polyCorrect = null;
          this.lineAltura = null;
          this.lineFluxo = null;
          this.lineVelocidade = null;
          if (temp_size > 0) {
            size = temp_size;
            temp_size = 0;
          }
        }
        if (this.state.updateValuesAnimation) {
          stateUpdate.restante = this.restante - this.state.correto;
          stateUpdate.time = pontos[line[i][2]][this.p.tempo] ? pontos[line[i][2]][this.p.tempo] : 0;

          this.setState(stateUpdate, () => { if (stateUpdateRun) stateUpdateRun() });
        }
        setTimeout(() => {
          if (this.run && line.length - 2 > i && this.current_run === this.new_run && this.state.play) {
            run(path, ++i, size);
          } else if (line.length - 20 < i) {
            this.play(false);
            this.setState({ end: true });
          }
          this.current_run = this.new_run;
        },
          (time - pTime) / this.state.sTime);
      }
    }
    run(path, i, size);
  };

  moveMarker = (pos, time, angle) => {
    this.marker.marker.slideTo(pos, {
      duration: time,
      rotationAngle: angle,
      keepAtCenter: this.state.follow
    });
  };

  falhaMap = (poly) => {
    let myStyle = {
      color: this.cores.falha,
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao',
    };

    let myLayer = L.geoJSON(poly, { style: myStyle });
    myLayer.addTo(this.fGAPP['0']);
  };

  aplMapCorrect = (poly) => {
    let myStyle = this.styles.correto;

    let myLayer = L.polygon(poly, myStyle);

    let area = L.GeometryUtil.geodesicArea(myLayer.getLatLngs()[0]);
    const pop = this.props.res.AREA + ': ' + area.toFixed(2) + ' ha';
    myLayer.bindPopup(pop);

    myLayer.addTo(this.fGAPP['0']);

    return myLayer;
  };

  aplMapFora = (poly) => {
    let myStyle = this.styles.fora;
    let myLayer = L.polygon(poly, myStyle);

    let area = L.GeometryUtil.geodesicArea(myLayer.getLatLngs()[0]);
    const pop = this.props.res.AREA + ': ' + area.toFixed(2) + ' ha';
    myLayer.bindPopup(pop);

    myLayer.addTo(this.fGAPP['0']);

    return myLayer;
  };

  aplMapsobre = (poly) => {
    let myStyle = this.styles.sobre;

    let myLayer = L.polygon(poly, myStyle);
    myLayer.addTo(this.fGAPP['0']);

    return myLayer;
  };

  updateTask = () => {
    this.setState({ progress: true });
    let task = this.state.task;
    let apiUrl = Config.server + '/analise';

    if (!this.state.alturaMargemDown.valid ||
      !this.state.alturaMargemUp.valid ||
      !this.state.alturaLimiteDown.valid ||
      !this.state.alturaLimiteUp.valid ||
      !this.state.fluxoMargemDown.valid ||
      !this.state.fluxoMargemUp.valid ||
      !this.state.fluxoLimiteDown.valid ||
      !this.state.fluxoLimiteUp.valid ||
      !this.state.velocidadeMargemDown.valid ||
      !this.state.velocidadeMargemUp.valid ||
      !this.state.velocidadeLimiteDown.valid ||
      !this.state.velocidadeLimiteUp.valid ||
      (this.state.checkConfig[4] &&
        (!this.state.velocidadeVMargemDown.valid ||
          !this.state.velocidadeVMargemUp.valid ||
          !this.state.velocidadeVLimiteDown.valid ||
          !this.state.velocidadeVLimiteUp.valid ||
          (!this.state.espelharAnguloV && !this.state.anguloVMargemDown.valid) ||
          !this.state.anguloVMargemUp.valid ||
          (!this.state.espelharAnguloV && !this.state.anguloVLimiteDown.valid) ||
          !this.state.anguloVLimiteUp.valid ||
          !this.state.temperaturaMargemDown.valid ||
          !this.state.temperaturaMargemUp.valid ||
          !this.state.temperaturaLimiteDown.valid ||
          !this.state.temperaturaLimiteUp.valid ||
          !this.state.UmidadeMargemDown.valid ||
          !this.state.UmidadeMargemUp.valid ||
          !this.state.UmidadeLimiteDown.valid ||
          !this.state.UmidadeLimiteUp.valid))) {
      notification.open({
        message: this.props.res.CONFIG_INVALID,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }


    const formData = new URLSearchParams();
    formData.append('task', task);
    formData.append('polygons', JSON.stringify(this.state.polygons));

    formData.append('aeronave', this.state.aeronave);
    formData.append('cliente', this.state.cliente);
    formData.append('piloto', this.state.piloto);
    formData.append('cultura', this.state.cultura);
    formData.append('nome', this.state.nome);

    formData.append('larguraDefault', this.state.larguraDefault);
    formData.append('alturaDefault', this.state.alturaDefault);
    formData.append('fluxoDefault', this.state.fluxoDefault);
    formData.append('velocidadeDefault', this.state.velocidadeDefault);

    formData.append('larguraSet', this.state.checkConfig[0] ? this.state.larguraSet : 0);
    formData.append('alturaSet', this.state.checkConfig[1] ? this.state.alturaSet : 0);
    formData.append('fluxoSet', this.state.checkConfig[2] ? this.state.fluxoSet : 0);
    formData.append('velocidadeSet', this.state.checkConfig[3] ? this.state.velocidadeSet : 0);


    //formData.append('alturaMargem', this.state.alturaMargem);
    //formData.append('alturaLimite', this.state.alturaLimite);

    formData.append('alturaMargemUp', this.state.alturaMargemUp.value);
    formData.append('alturaMargemDown', this.state.alturaMargemDown.value);
    formData.append('alturaLimiteUp', this.state.alturaLimiteUp.value);
    formData.append('alturaLimiteDown', this.state.alturaLimiteDown.value);

    //formData.append('fluxoMargem', this.state.fluxoMargem);
    //formData.append('fluxoLimite', this.state.fluxoLimite);

    formData.append('fluxoMargemUp', this.state.fluxoMargemUp.value);
    formData.append('fluxoMargemDown', this.state.fluxoMargemDown.value);
    formData.append('fluxoLimiteUp', this.state.fluxoLimiteUp.value);
    formData.append('fluxoLimiteDown', this.state.fluxoLimiteDown.value);

    formData.append('velocidadeMargemUp', this.state.velocidadeMargemUp.value);
    formData.append('velocidadeMargemDown', this.state.velocidadeMargemDown.value);
    formData.append('velocidadeLimiteUp', this.state.velocidadeLimiteUp.value);
    formData.append('velocidadeLimiteDown', this.state.velocidadeLimiteDown.value);

    if (this.state.checkConfig[4]) {
      formData.append('velocidadeVSet', this.state.velocidadeVSet);
      formData.append('anguloVSetRight', 90);
      formData.append('anguloVSetLeft', 270);
      formData.append('temperaturaSet', this.state.temperaturaSet);
      formData.append('UmidadeSet', this.state.UmidadeSet);

      if (this.state.tipoFonte === 'EXTERNO') {
        formData.append('tipoFonte', 'api');
      } else {
        formData.append('tipoFonte', 'station');
        formData.append('fonteClima', this.state.fonteClima);
      }
      formData.append('velocidadeVMargemUp', this.state.velocidadeVMargemUp.value);
      formData.append('velocidadeVMargemDown', this.state.velocidadeVMargemDown.value);
      formData.append('velocidadeVLimiteUp', this.state.velocidadeVLimiteUp.value);
      formData.append('velocidadeVLimiteDown', this.state.velocidadeVLimiteDown.value);

      formData.append('anguloVMargemDown', this.state.anguloVMargemDown.value);
      formData.append('anguloVMargemUp', this.state.espelharAnguloV ? this.state.anguloVMargemDown.value : this.state.anguloVMargemUp.value);
      formData.append('anguloVLimiteDown', this.state.anguloVLimiteDown.value);
      formData.append('anguloVLimiteUp', this.state.espelharAnguloV ? this.state.anguloVLimiteDown.value : this.state.anguloVLimiteUp.value);

      formData.append('temperaturaMargemUp', this.state.temperaturaMargemUp.value);
      formData.append('temperaturaMargemDown', this.state.temperaturaMargemDown.value);
      formData.append('temperaturaLimiteUp', this.state.temperaturaLimiteUp.value);
      formData.append('temperaturaLimiteDown', this.state.temperaturaLimiteDown.value);

      formData.append('UmidadeMargemUp', this.state.UmidadeMargemUp.value);
      formData.append('UmidadeMargemDown', this.state.UmidadeMargemDown.value);
      formData.append('UmidadeLimiteUp', this.state.UmidadeLimiteUp.value);
      formData.append('UmidadeLimiteDown', this.state.UmidadeLimiteDown.value);
    }

    const options = {
      method: 'PUT',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          if (result) {
            this.setState({ loading: true });
            setTimeout(() => {
              this.loadTask(true);
            }, 200);
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 400) {
            notification.open({
              message: this.props.res[error.statusText],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  loadTask = (open) => {
    let apiUrl = Config.server + '/task/analise'

    if (this.props.guid) {
      apiUrl += '/' + this.props.guid
    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (this.state.openTask || open || this.state.loading) {
            setTimeout(() => {
              if (this.state.openTask) {
                this.loadTask(true);
              }
            }, 3000);
            this.setState({
              itensTask: result,
              openTask: open
            });
          } else {
            this.setState({ itensTask: result });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  toJSON = async (length, contentType, body, update) => {
    let loaded = 0;

    const reader = body.getReader(); // `ReadableStreamDefaultReader`
    const chunks = [];

    async function read() {
      const { done, value } = await reader.read();

      if (done) {
        const buffer = new Uint8Array(loaded);
        let offset = 0;
        for (const chunk of chunks) {
          buffer.set(chunk, offset);
          offset += chunk.length;
        }

        const arrayBuffer = buffer.buffer;

        if (contentType && contentType.includes('application/zip')) {
          const zip = new jszip();
          const zipContents = await zip.loadAsync(arrayBuffer);
          const jsonFile = zipContents.files['result.json'];
          if (jsonFile) {
            const jsonString = await jsonFile.async('string');
            const jsonData = JSON.parse(jsonString);
            return jsonData;
          } else {
            throw new Error('JSON file not found inside ZIP');
          }
        } else if (contentType && contentType.includes('application/json')) {
          // Process as JSON if content type is JSON
          const jsonData = JSON.parse(new TextDecoder().decode(arrayBuffer));
          return jsonData;
        }
      }

      chunks.push(value);
      loaded += value.length;

      update && update(loaded, length);

      return read(); // read the next chunk
    }

    return read();
  }

  getTask = async (task, data) => {
    if (task) {
      let tempTask = task;
      let apiUrl = Config.server + '/analise/' + task;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      let result;
      if (data) {
        result = { data: data };
      } else {
        this.setState({ progress: true, progressValue: 0, openTask: false });
        try {
          const response = await fetch(apiUrl, options);
          result = await this.toJSON(
            response.headers.get('content-length'),
            response.headers.get('Content-Type'),
            response.body,
            (loaded, length) => {
              this.setState({ progressValue: (100 * loaded / length).toFixed() });
            }
          );
        } catch (error) {
          this.setState({ progress: false });
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.ERRO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          return;
        }
      }

      if (result) {
        if (result.cantRun) {
          this.setState({ task: null, progress: false, progressValue: 0 });
        } else if (result.msg === "not ready.") {
          this.setState({ progress: false, progressValue: 0 });
          this.loadTask(true);
        } else {
          if (result.app) {
            this.useType(isNaN(result.pontos[0].bearing) ? 'N' : 'C');

            const lSet = result.data.larguraSet > 0 ? result.data.larguraSet : result.data.larguraDefault > 0 ? result.data.larguraDefault : this.state.larguraSet;
            const aSet = result.data.alturaSet > 0 ? result.data.alturaSet : result.data.alturaDefault > 0 ? result.data.alturaDefault : this.state.alturaSet;
            const fSet = result.data.fluxoSet > 0 ? result.data.fluxoSet : result.data.fluxoDefault > 0 ? result.data.fluxoDefault : this.state.fluxoSet;
            const vSet = result.data.velocidadeSet > 0 ? result.data.velocidadeSet : result.data.velocidadeDefault > 0 ? result.data.velocidadeDefault : this.state.velocidadeSet;
            const vvSet = result.data.velocidadeVSet > 0 ? result.data.velocidadeVSet : 10;
            //const dvSetDown = result.data.anguloVSetDown > 0 ? result.data.anguloVSetDown : 90;
            //const dvSetUp = result.data.anguloVSetUp > 0 ? result.data.anguloVSetUp : 270;
            const tSet = result.data.temperaturaSet > 0 ? result.data.temperaturaSet : 22.5;
            const hSet = result.data.UmidadeSet > 0 ? result.data.UmidadeSet : 52.5;

            localStorage.setItem('check-task', tempTask);
            let idJob = this.state.id_job;
            let titleJob = this.state.title;
            let checkConfig = this.state.checkConfig;
            checkConfig[0] = result.data.larguraSet > 0;
            checkConfig[1] = result.data.alturaSet > 0;
            checkConfig[2] = result.data.fluxoSet > 0;
            checkConfig[3] = result.data.velocidadeSet > 0;
            checkConfig[4] = result.data.velocidadeVSet > 0;
            /*checkConfig[5] = vvSet > 0;
            checkConfig[6] = vvSet > 0;
            checkConfig[7] = vvSet > 0;
            checkConfig[9] = vvSet > 0;*/

            this.clear(true, () => {
              let state = {
                progress: false, progressValue: 0,

                id_job: idJob,
                title: titleJob,
                task: tempTask,
                title_sk: result.title,
                state: 3,
                showLimit: false,
                larguraSet: lSet,
                alturaSet: aSet ? Number(aSet) : 0,
                fluxoSet: fSet,
                velocidadeSet: vSet,

                checkConfig: checkConfig,
                //fonstes: result.data.weatherdata,
                tipoFonte: result.data.sourceType === 'station' ? 'ESTACAO' : 'EXTERNO',
                fonteClima: !isNaN(result.data.sourceId) ? result.data.sourceId : 0,

                cultura: result.data.cultura,
                nome: result.data.nome,
                aeronave: result.data.aeronave,
                cliente: result.data.cliente,
                piloto: result.data.piloto,

                gCultura: result.data.cultura,
                gNome: result.data.nome,
                gAeronave: result.data.aeronave,
                gCliente: result.data.cliente,
                gPiloto: result.data.piloto,

                dateIni: tools.formatDateTime(new Date(result.dateIni)),
                dateFim: tools.formatDateTime(new Date(result.dateFim)),
                area_talhoes: (result.total.area ? result.total.area : 0),
                distancia_percorrida: (result.total.distancia ? result.total.distancia : 0),

                //altura
                media_alturaS: (result.total.altura.media ? result.total.altura.media : 0),
                corretoAltS: (result.total.altura.correto ? result.total.altura.correto : 0),
                aceitavelAltInfS: (result.total.altura.aceitavel.down ? result.total.altura.aceitavel.down : 0),
                aceitavelAltSupS: (result.total.altura.aceitavel.up ? result.total.altura.aceitavel.up : 0),
                incorretaAltInfS: (result.total.altura.inaceitavel.down ? result.total.altura.inaceitavel.down : 0),
                incorretaAltSupS: (result.total.altura.inaceitavel.up ? result.total.altura.inaceitavel.up : 0),

                alturaMargemDown: {
                  value: result.data.alturaMargemDown,
                  text: aSet - result.data.alturaMargemDown,
                  valid: true
                },
                alturaMargemUp: {
                  value: result.data.alturaMargemUp,
                  text: aSet + result.data.alturaMargemUp,
                  valid: true
                },
                alturaLimiteDown: {
                  value: result.data.alturaLimiteDown,
                  text: aSet - result.data.alturaMargemDown - result.data.alturaLimiteDown,
                  valid: true
                },
                alturaLimiteUp: {
                  value: result.data.alturaLimiteUp,
                  text: aSet + result.data.alturaMargemUp + result.data.alturaLimiteUp,
                  valid: true
                },
                //fluxo
                media_fluxoS: (result.total.fluxo.media ? result.total.fluxo.media : 0),
                corretoFlxS: (result.total.fluxo.correto ? result.total.fluxo.correto : 0),
                aceitavelFlxInfS: (result.total.fluxo.aceitavel.down ? result.total.fluxo.aceitavel.down : 0),
                aceitavelFlxSupS: (result.total.fluxo.aceitavel.up ? result.total.fluxo.aceitavel.up : 0),
                incorretaFlxInfS: (result.total.fluxo.inaceitavel.down ? result.total.fluxo.inaceitavel.down : 0),
                incorretaFlxSupS: (result.total.fluxo.inaceitavel.up ? result.total.fluxo.inaceitavel.up : 0),

                fluxoMargemDown: {
                  value: result.data.fluxoMargemDown,
                  text: fSet - result.data.fluxoMargemDown,
                  valid: true
                },
                fluxoMargemUp: {
                  value: result.data.fluxoMargemUp,
                  text: fSet + result.data.fluxoMargemUp,
                  valid: true
                },
                fluxoLimiteDown: {
                  value: result.data.fluxoLimiteDown,
                  text: fSet - result.data.fluxoMargemDown - result.data.fluxoLimiteDown,
                  valid: true
                },
                fluxoLimiteUp: {
                  value: result.data.fluxoLimiteUp,
                  text: fSet + result.data.fluxoMargemUp + result.data.fluxoLimiteUp,
                  valid: true
                },
                //velocidade
                media_velocidadeS: (result.total.velocidade.media ? result.total.velocidade.media : 0),
                corretoVlcS: (result.total.velocidade.correto ? result.total.velocidade.correto : 0),
                aceitavelVlcInfS: (result.total.velocidade.aceitavel.down ? result.total.velocidade.aceitavel.down : 0),
                aceitavelVlcSupS: (result.total.velocidade.aceitavel.up ? result.total.velocidade.aceitavel.up : 0),
                incorretaVlcInfS: (result.total.velocidade.inaceitavel.down ? result.total.velocidade.inaceitavel.down : 0),
                incorretaVlcSupS: (result.total.velocidade.inaceitavel.up ? result.total.velocidade.inaceitavel.up : 0),

                velocidadeMargemDown: {
                  value: result.data.velocidadeMargemDown,
                  text: vSet - result.data.velocidadeMargemDown,
                  valid: true
                },
                velocidadeMargemUp: {
                  value: result.data.velocidadeMargemUp,
                  text: vSet + result.data.velocidadeMargemUp,
                  valid: true
                },
                velocidadeLimiteDown: {
                  value: result.data.velocidadeLimiteDown,
                  text: vSet - result.data.velocidadeMargemDown - result.data.velocidadeLimiteDown,
                  valid: true
                },
                velocidadeLimiteUp: {
                  value: result.data.velocidadeLimiteUp,
                  text: vSet + result.data.velocidadeMargemUp + result.data.velocidadeLimiteUp,
                  valid: true
                },

                progress: false,
              };
              if (result.id_job) {
                state.id_job = result.id_job;
              }

              if (checkConfig[4]) {
                state = {
                  ...state, ...{
                    //velocidade vento
                    media_velocidade_ventoS: (result.total.vento_velocidade.media ? result.total.vento_velocidade.media : 0),
                    corretoVlcVS: (result.total.vento_velocidade.correto ? result.total.vento_velocidade.correto : 0),
                    aceitavelVlcVInfS: (result.total.vento_velocidade.aceitavel.down ? result.total.vento_velocidade.aceitavel.down : 0),
                    aceitavelVlcVSupS: (result.total.vento_velocidade.aceitavel.up ? result.total.vento_velocidade.aceitavel.up : 0),
                    incorretaVlcVInfS: (result.total.vento_velocidade.inaceitavel.down ? result.total.vento_velocidade.inaceitavel.down : 0),
                    incorretaVlcVSupS: (result.total.vento_velocidade.inaceitavel.up ? result.total.vento_velocidade.inaceitavel.up : 0),

                    velocidadeVSet: vvSet,
                    velocidadeVMargemDown: {
                      value: result.data.velocidadeVMargemDown,
                      text: vvSet - result.data.velocidadeVMargemDown,
                      valid: true
                    },
                    velocidadeVMargemUp: {
                      value: result.data.velocidadeVMargemUp,
                      text: vvSet + result.data.velocidadeVMargemUp,
                      valid: true
                    },
                    velocidadeVLimiteDown: {
                      value: result.data.velocidadeVLimiteDown,
                      text: vvSet - result.data.velocidadeVMargemDown - result.data.velocidadeVLimiteDown,
                      valid: true
                    },
                    velocidadeVLimiteUp: {
                      value: result.data.velocidadeVLimiteUp,
                      text: vvSet + result.data.velocidadeVMargemUp + result.data.velocidadeVLimiteUp,
                      valid: true
                    },

                    //angulo vento                          
                    //media_angulo_ventoS: (result.total.vento_angulo.media ? result.total.vento_angulo.media : 0),
                    corretoAngVS: (result.total.vento_angulo.correto.left ? result.total.vento_angulo.correto.left + result.total.vento_angulo.correto.right : 0),
                    aceitavelAngVInfS: (result.total.vento_angulo.aceitavel.left ? result.total.vento_angulo.aceitavel.left.down + result.total.vento_angulo.aceitavel.right.down : 0),
                    aceitavelAngVSupS: (result.total.vento_angulo.aceitavel.left ? result.total.vento_angulo.aceitavel.left.up + result.total.vento_angulo.aceitavel.right.up : 0),
                    incorretaAngVInfS: (result.total.vento_angulo.inaceitavel.left ? result.total.vento_angulo.inaceitavel.left.down + result.total.vento_angulo.inaceitavel.right.down : 0),
                    incorretaAngVSupS: (result.total.vento_angulo.inaceitavel.left ? result.total.vento_angulo.inaceitavel.left.up + result.total.vento_angulo.inaceitavel.right.up : 0),

                    anguloVMargemDown: {
                      value: result.data.anguloVMargemDown,
                      text: result.data.anguloVMargemDown,
                      valid: true
                    },
                    anguloVMargemUp: {
                      value: result.data.anguloVMargemUp,
                      text: result.data.anguloVMargemUp,
                      valid: true
                    },
                    anguloVLimiteDown: {
                      value: result.data.anguloVLimiteDown,
                      text: result.data.anguloVMargemDown + result.data.anguloVLimiteDown,
                      valid: true
                    },
                    anguloVLimiteUp: {
                      value: result.data.anguloVLimiteUp,
                      text: result.data.anguloVMargemUp + result.data.anguloVLimiteUp,
                      valid: true
                    },
                    media_angulo_up: result.total.vento_angulo.media.up,
                    media_angulo_down: result.total.vento_angulo.media.down,
                    //delta-t
                    media_DeltaTS: (result.total.deltaT.media),
                    corretoDeltaT: (result.total.deltaT.correto ? result.total.deltaT.correto : 0),
                    aceitavelDeltaTInfS: (result.total.deltaT.aceitavel.down ? result.total.deltaT.aceitavel.down : 0),
                    aceitavelDeltaTSupS: (result.total.deltaT.aceitavel.up ? result.total.deltaT.aceitavel.up : 0),
                    incorretaDeltaTInfS: (result.total.deltaT.inaceitavel.down ? result.total.deltaT.inaceitavel.down : 0),
                    incorretaDeltaTSupS: (result.total.deltaT.inaceitavel.up ? result.total.deltaT.inaceitavel.up : 0),

                    //temperatura
                    media_temperaturaS: (result.total.temperatura.media ? result.total.temperatura.media : 0),
                    corretoTempVS: (result.total.temperatura.correto ? result.total.temperatura.correto : 0),
                    aceitavelTempInfS: (result.total.temperatura.aceitavel.down ? result.total.temperatura.aceitavel.down : 0),
                    aceitavelTempSupS: (result.total.temperatura.aceitavel.up ? result.total.temperatura.aceitavel.up : 0),
                    incorretaTempInfS: (result.total.temperatura.inaceitavel.down ? result.total.temperatura.inaceitavel.down : 0),
                    incorretaTempSupS: (result.total.temperatura.inaceitavel.up ? result.total.temperatura.inaceitavel.up : 0),

                    temperaturaSet: tSet,
                    temperaturaMargemDown: {
                      value: result.data.temperaturaMargemDown,
                      text: tSet - result.data.temperaturaMargemDown,
                      valid: true
                    },
                    temperaturaMargemUp: {
                      value: result.data.temperaturaMargemUp,
                      text: tSet + result.data.temperaturaMargemUp,
                      valid: true
                    },
                    temperaturaLimiteDown: {
                      value: result.data.temperaturaLimiteDown,
                      text: tSet - result.data.temperaturaMargemDown - result.data.temperaturaLimiteDown,
                      valid: true
                    },
                    temperaturaLimiteUp: {
                      value: result.data.temperaturaLimiteUp,
                      text: tSet + result.data.temperaturaMargemUp + result.data.temperaturaLimiteUp,
                      valid: true
                    },

                    //humidade
                    media_UmidadeS: (result.total.humidade.media ? result.total.humidade.media : 0),
                    corretoUmiVS: (result.total.humidade.correto ? result.total.humidade.correto : 0),
                    aceitavelUmiInfS: (result.total.humidade.aceitavel.down ? result.total.humidade.aceitavel.down : 0),
                    aceitavelUmiSupS: (result.total.humidade.aceitavel.up ? result.total.humidade.aceitavel.up : 0),
                    incorretaUmiInfS: (result.total.humidade.inaceitavel.down ? result.total.humidade.inaceitavel.down : 0),
                    incorretaUmiSupS: (result.total.humidade.inaceitavel.up ? result.total.humidade.inaceitavel.up : 0),

                    UmidadeSet: hSet,
                    UmidadeMargemDown: {
                      value: result.data.UmidadeMargemDown,
                      text: hSet - result.data.UmidadeMargemDown,
                      valid: true
                    },
                    UmidadeMargemUp: {
                      value: result.data.UmidadeMargemUp,
                      text: hSet + result.data.UmidadeMargemUp,
                      valid: true
                    },
                    UmidadeLimiteDown: {
                      value: result.data.UmidadeLimiteDown,
                      text: hSet - result.data.UmidadeMargemDown - result.data.UmidadeLimiteDown,
                      valid: true
                    },
                    UmidadeLimiteUp: {
                      value: result.data.UmidadeLimiteUp,
                      text: hSet + result.data.UmidadeMargemUp + result.data.UmidadeLimiteUp,
                      valid: true
                    },
                  }
                };
              }
              this.setState(state);
              this.loadResult(result);
            });
          } else if (result.data) {
            this.setState({ progress: false, progressValue: 0 });
            result.task = { id: task };
            this.useData(result);
          }
        }
      }
    }
  };
  getTask2 = async (task, data) => {
    if (task) {
      let tempData = data;
      let tempTask = task;
      let apiUrl = Config.server + '/analise/' + task;
      let method = 'GET';

      let options = {
        method: method,
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then(async (res) => {
          if (res.status !== 200) throw res;
          else {
            const contentType = res.headers.get('Content-Type');
            if (!contentType) {
              throw res;
            } else if (contentType.includes('application/json')) {
              return await res.json();
            } else if (contentType.includes('application/zip')) {
              const arrayBuffer = await res.arrayBuffer();
              const zip = new jszip();
              const zipContents = await zip.loadAsync(arrayBuffer);
              const jsonFile = zipContents.files['result.json'];
              if (jsonFile) {
                const jsonString = await jsonFile.async('string');
                return JSON.parse(jsonString);
              } else {
                throw res;
              }
            } else {
              throw res;
            }
          }
        })
        .then(
          (result) => {
            if (result) {
              if (result.cantRun) {
                this.setState({ task: null, progress: false, progressValue: 0 });
              } else if (result.msg === "not ready.") {
                this.setState({ progress: false, progressValue: 0 });
                this.loadTask(true);
              } else {
                if (result.app) {
                  this.useType(isNaN(result.pontos[0].bearing) ? 'N' : 'C');

                  const lSet = result.data.larguraSet > 0 ? result.data.larguraSet : result.data.larguraDefault > 0 ? result.data.larguraDefault : this.state.larguraSet;
                  const aSet = result.data.alturaSet > 0 ? result.data.alturaSet : result.data.alturaDefault > 0 ? result.data.alturaDefault : this.state.alturaSet;
                  const fSet = result.data.fluxoSet > 0 ? result.data.fluxoSet : result.data.fluxoDefault > 0 ? result.data.fluxoDefault : this.state.fluxoSet;
                  const vSet = result.data.velocidadeSet > 0 ? result.data.velocidadeSet : result.data.velocidadeDefault > 0 ? result.data.velocidadeDefault : this.state.velocidadeSet;
                  const vvSet = result.data.velocidadeVSet > 0 ? result.data.velocidadeVSet : 10;
                  //const dvSetDown = result.data.anguloVSetDown > 0 ? result.data.anguloVSetDown : 90;
                  //const dvSetUp = result.data.anguloVSetUp > 0 ? result.data.anguloVSetUp : 270;
                  const tSet = result.data.temperaturaSet > 0 ? result.data.temperaturaSet : 22.5;
                  const hSet = result.data.UmidadeSet > 0 ? result.data.UmidadeSet : 52.5;

                  localStorage.setItem('check-task', tempTask);
                  let idJob = this.state.id_job;
                  let titleJob = this.state.title;
                  let checkConfig = this.state.checkConfig;
                  checkConfig[0] = result.data.larguraSet > 0;
                  checkConfig[1] = result.data.alturaSet > 0;
                  checkConfig[2] = result.data.fluxoSet > 0;
                  checkConfig[3] = result.data.velocidadeSet > 0;
                  checkConfig[4] = result.data.velocidadeVSet > 0;
                  /*checkConfig[5] = vvSet > 0;
                  checkConfig[6] = vvSet > 0;
                  checkConfig[7] = vvSet > 0;
                  checkConfig[9] = vvSet > 0;*/

                  this.clear(true, () => {
                    let state = {
                      progress: false, progressValue: 0,

                      id_job: idJob,
                      title: titleJob,
                      task: tempTask,
                      title_sk: result.title,
                      state: 3,
                      showLimit: false,
                      larguraSet: lSet,
                      alturaSet: aSet ? Number(aSet) : 0,
                      fluxoSet: fSet,
                      velocidadeSet: vSet,

                      checkConfig: checkConfig,
                      //fonstes: result.data.weatherdata,
                      tipoFonte: result.data.sourceType === 'station' ? 'ESTACAO' : 'EXTERNO',
                      fonteClima: !isNaN(result.data.sourceId) ? result.data.sourceId : 0,

                      cultura: result.data.cultura,
                      nome: result.data.nome,
                      aeronave: result.data.aeronave,
                      cliente: result.data.cliente,
                      piloto: result.data.piloto,

                      gCultura: result.data.cultura,
                      gNome: result.data.nome,
                      gAeronave: result.data.aeronave,
                      gCliente: result.data.cliente,
                      gPiloto: result.data.piloto,

                      dateIni: tools.formatDateTime(new Date(result.dateIni)),
                      dateFim: tools.formatDateTime(new Date(result.dateFim)),
                      area_talhoes: (result.total.area ? result.total.area : 0),
                      distancia_percorrida: (result.total.distancia ? result.total.distancia : 0),

                      //altura
                      media_alturaS: (result.total.altura.media ? result.total.altura.media : 0),
                      corretoAltS: (result.total.altura.correto ? result.total.altura.correto : 0),
                      aceitavelAltInfS: (result.total.altura.aceitavel.down ? result.total.altura.aceitavel.down : 0),
                      aceitavelAltSupS: (result.total.altura.aceitavel.up ? result.total.altura.aceitavel.up : 0),
                      incorretaAltInfS: (result.total.altura.inaceitavel.down ? result.total.altura.inaceitavel.down : 0),
                      incorretaAltSupS: (result.total.altura.inaceitavel.up ? result.total.altura.inaceitavel.up : 0),

                      alturaMargemDown: {
                        value: result.data.alturaMargemDown,
                        text: aSet - result.data.alturaMargemDown,
                        valid: true
                      },
                      alturaMargemUp: {
                        value: result.data.alturaMargemUp,
                        text: aSet + result.data.alturaMargemUp,
                        valid: true
                      },
                      alturaLimiteDown: {
                        value: result.data.alturaLimiteDown,
                        text: aSet - result.data.alturaMargemDown - result.data.alturaLimiteDown,
                        valid: true
                      },
                      alturaLimiteUp: {
                        value: result.data.alturaLimiteUp,
                        text: aSet + result.data.alturaMargemUp + result.data.alturaLimiteUp,
                        valid: true
                      },
                      //fluxo
                      media_fluxoS: (result.total.fluxo.media ? result.total.fluxo.media : 0),
                      corretoFlxS: (result.total.fluxo.correto ? result.total.fluxo.correto : 0),
                      aceitavelFlxInfS: (result.total.fluxo.aceitavel.down ? result.total.fluxo.aceitavel.down : 0),
                      aceitavelFlxSupS: (result.total.fluxo.aceitavel.up ? result.total.fluxo.aceitavel.up : 0),
                      incorretaFlxInfS: (result.total.fluxo.inaceitavel.down ? result.total.fluxo.inaceitavel.down : 0),
                      incorretaFlxSupS: (result.total.fluxo.inaceitavel.up ? result.total.fluxo.inaceitavel.up : 0),

                      fluxoMargemDown: {
                        value: result.data.fluxoMargemDown,
                        text: fSet - result.data.fluxoMargemDown,
                        valid: true
                      },
                      fluxoMargemUp: {
                        value: result.data.fluxoMargemUp,
                        text: fSet + result.data.fluxoMargemUp,
                        valid: true
                      },
                      fluxoLimiteDown: {
                        value: result.data.fluxoLimiteDown,
                        text: fSet - result.data.fluxoMargemDown - result.data.fluxoLimiteDown,
                        valid: true
                      },
                      fluxoLimiteUp: {
                        value: result.data.fluxoLimiteUp,
                        text: fSet + result.data.fluxoMargemUp + result.data.fluxoLimiteUp,
                        valid: true
                      },
                      //velocidade
                      media_velocidadeS: (result.total.velocidade.media ? result.total.velocidade.media : 0),
                      corretoVlcS: (result.total.velocidade.correto ? result.total.velocidade.correto : 0),
                      aceitavelVlcInfS: (result.total.velocidade.aceitavel.down ? result.total.velocidade.aceitavel.down : 0),
                      aceitavelVlcSupS: (result.total.velocidade.aceitavel.up ? result.total.velocidade.aceitavel.up : 0),
                      incorretaVlcInfS: (result.total.velocidade.inaceitavel.down ? result.total.velocidade.inaceitavel.down : 0),
                      incorretaVlcSupS: (result.total.velocidade.inaceitavel.up ? result.total.velocidade.inaceitavel.up : 0),

                      velocidadeMargemDown: {
                        value: result.data.velocidadeMargemDown,
                        text: vSet - result.data.velocidadeMargemDown,
                        valid: true
                      },
                      velocidadeMargemUp: {
                        value: result.data.velocidadeMargemUp,
                        text: vSet + result.data.velocidadeMargemUp,
                        valid: true
                      },
                      velocidadeLimiteDown: {
                        value: result.data.velocidadeLimiteDown,
                        text: vSet - result.data.velocidadeMargemDown - result.data.velocidadeLimiteDown,
                        valid: true
                      },
                      velocidadeLimiteUp: {
                        value: result.data.velocidadeLimiteUp,
                        text: vSet + result.data.velocidadeMargemUp + result.data.velocidadeLimiteUp,
                        valid: true
                      },

                      progress: false,
                    };
                    if (result.id_job) {
                      state.id_job = result.id_job;
                    }

                    if (checkConfig[4]) {
                      state = {
                        ...state, ...{
                          //velocidade vento
                          media_velocidade_ventoS: (result.total.vento_velocidade.media ? result.total.vento_velocidade.media : 0),
                          corretoVlcVS: (result.total.vento_velocidade.correto ? result.total.vento_velocidade.correto : 0),
                          aceitavelVlcVInfS: (result.total.vento_velocidade.aceitavel.down ? result.total.vento_velocidade.aceitavel.down : 0),
                          aceitavelVlcVSupS: (result.total.vento_velocidade.aceitavel.up ? result.total.vento_velocidade.aceitavel.up : 0),
                          incorretaVlcVInfS: (result.total.vento_velocidade.inaceitavel.down ? result.total.vento_velocidade.inaceitavel.down : 0),
                          incorretaVlcVSupS: (result.total.vento_velocidade.inaceitavel.up ? result.total.vento_velocidade.inaceitavel.up : 0),

                          velocidadeVSet: vvSet,
                          velocidadeVMargemDown: {
                            value: result.data.velocidadeVMargemDown,
                            text: vvSet - result.data.velocidadeVMargemDown,
                            valid: true
                          },
                          velocidadeVMargemUp: {
                            value: result.data.velocidadeVMargemUp,
                            text: vvSet + result.data.velocidadeVMargemUp,
                            valid: true
                          },
                          velocidadeVLimiteDown: {
                            value: result.data.velocidadeVLimiteDown,
                            text: vvSet - result.data.velocidadeVMargemDown - result.data.velocidadeVLimiteDown,
                            valid: true
                          },
                          velocidadeVLimiteUp: {
                            value: result.data.velocidadeVLimiteUp,
                            text: vvSet + result.data.velocidadeVMargemUp + result.data.velocidadeVLimiteUp,
                            valid: true
                          },

                          //angulo vento                          
                          //media_angulo_ventoS: (result.total.vento_angulo.media ? result.total.vento_angulo.media : 0),
                          corretoAngVS: (result.total.vento_angulo.correto.left ? result.total.vento_angulo.correto.left + result.total.vento_angulo.correto.right : 0),
                          aceitavelAngVInfS: (result.total.vento_angulo.aceitavel.left ? result.total.vento_angulo.aceitavel.left.down + result.total.vento_angulo.aceitavel.right.down : 0),
                          aceitavelAngVSupS: (result.total.vento_angulo.aceitavel.left ? result.total.vento_angulo.aceitavel.left.up + result.total.vento_angulo.aceitavel.right.up : 0),
                          incorretaAngVInfS: (result.total.vento_angulo.inaceitavel.left ? result.total.vento_angulo.inaceitavel.left.down + result.total.vento_angulo.inaceitavel.right.down : 0),
                          incorretaAngVSupS: (result.total.vento_angulo.inaceitavel.left ? result.total.vento_angulo.inaceitavel.left.up + result.total.vento_angulo.inaceitavel.right.up : 0),

                          anguloVMargemDown: {
                            value: result.data.anguloVMargemDown,
                            text: result.data.anguloVMargemDown,
                            valid: true
                          },
                          anguloVMargemUp: {
                            value: result.data.anguloVMargemUp,
                            text: result.data.anguloVMargemUp,
                            valid: true
                          },
                          anguloVLimiteDown: {
                            value: result.data.anguloVLimiteDown,
                            text: result.data.anguloVMargemDown + result.data.anguloVLimiteDown,
                            valid: true
                          },
                          anguloVLimiteUp: {
                            value: result.data.anguloVLimiteUp,
                            text: result.data.anguloVMargemUp + result.data.anguloVLimiteUp,
                            valid: true
                          },
                          media_angulo_up: result.total.vento_angulo.media.up,
                          media_angulo_down: result.total.vento_angulo.media.down,
                          //delta-t
                          media_DeltaTS: (result.total.deltaT.media),
                          corretoDeltaT: (result.total.deltaT.correto ? result.total.deltaT.correto : 0),
                          aceitavelDeltaTInfS: (result.total.deltaT.aceitavel.down ? result.total.deltaT.aceitavel.down : 0),
                          aceitavelDeltaTSupS: (result.total.deltaT.aceitavel.up ? result.total.deltaT.aceitavel.up : 0),
                          incorretaDeltaTInfS: (result.total.deltaT.inaceitavel.down ? result.total.deltaT.inaceitavel.down : 0),
                          incorretaDeltaTSupS: (result.total.deltaT.inaceitavel.up ? result.total.deltaT.inaceitavel.up : 0),

                          //temperatura
                          media_temperaturaS: (result.total.temperatura.media ? result.total.temperatura.media : 0),
                          corretoTempVS: (result.total.temperatura.correto ? result.total.temperatura.correto : 0),
                          aceitavelTempInfS: (result.total.temperatura.aceitavel.down ? result.total.temperatura.aceitavel.down : 0),
                          aceitavelTempSupS: (result.total.temperatura.aceitavel.up ? result.total.temperatura.aceitavel.up : 0),
                          incorretaTempInfS: (result.total.temperatura.inaceitavel.down ? result.total.temperatura.inaceitavel.down : 0),
                          incorretaTempSupS: (result.total.temperatura.inaceitavel.up ? result.total.temperatura.inaceitavel.up : 0),

                          temperaturaSet: tSet,
                          temperaturaMargemDown: {
                            value: result.data.temperaturaMargemDown,
                            text: tSet - result.data.temperaturaMargemDown,
                            valid: true
                          },
                          temperaturaMargemUp: {
                            value: result.data.temperaturaMargemUp,
                            text: tSet + result.data.temperaturaMargemUp,
                            valid: true
                          },
                          temperaturaLimiteDown: {
                            value: result.data.temperaturaLimiteDown,
                            text: tSet - result.data.temperaturaMargemDown - result.data.temperaturaLimiteDown,
                            valid: true
                          },
                          temperaturaLimiteUp: {
                            value: result.data.temperaturaLimiteUp,
                            text: tSet + result.data.temperaturaMargemUp + result.data.temperaturaLimiteUp,
                            valid: true
                          },

                          //humidade
                          media_UmidadeS: (result.total.humidade.media ? result.total.humidade.media : 0),
                          corretoUmiVS: (result.total.humidade.correto ? result.total.humidade.correto : 0),
                          aceitavelUmiInfS: (result.total.humidade.aceitavel.down ? result.total.humidade.aceitavel.down : 0),
                          aceitavelUmiSupS: (result.total.humidade.aceitavel.up ? result.total.humidade.aceitavel.up : 0),
                          incorretaUmiInfS: (result.total.humidade.inaceitavel.down ? result.total.humidade.inaceitavel.down : 0),
                          incorretaUmiSupS: (result.total.humidade.inaceitavel.up ? result.total.humidade.inaceitavel.up : 0),

                          UmidadeSet: hSet,
                          UmidadeMargemDown: {
                            value: result.data.UmidadeMargemDown,
                            text: hSet - result.data.UmidadeMargemDown,
                            valid: true
                          },
                          UmidadeMargemUp: {
                            value: result.data.UmidadeMargemUp,
                            text: hSet + result.data.UmidadeMargemUp,
                            valid: true
                          },
                          UmidadeLimiteDown: {
                            value: result.data.UmidadeLimiteDown,
                            text: hSet - result.data.UmidadeMargemDown - result.data.UmidadeLimiteDown,
                            valid: true
                          },
                          UmidadeLimiteUp: {
                            value: result.data.UmidadeLimiteUp,
                            text: hSet + result.data.UmidadeMargemUp + result.data.UmidadeLimiteUp,
                            valid: true
                          },
                        }
                      };
                    }
                    this.setState(state);
                    this.loadResult(result);
                  });
                } else if (tempData) {
                  this.setState({ progress: false, progressValue: 0 });
                  result.task = { id: task };
                  this.useData({ data: tempData });
                }
              }
            }
          },
          (error) => {
            this.setState({ progress: false });
            if (error.status === 405) {
              notification.open({
                message: this.props.res.PERMISSAO,
                description: this.props.res.PERMISSAO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              console.log(error);
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  getAreas = (id_job) => {
    if (!id_job) {
      this.onCloseEditMap(this.polygonsDefault, false);
    } else {
      let apiUrl = Config.server + '/job_area/' + id_job + '/area';
      let method = 'GET';

      let options = {
        method: method,
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            //this.setState({ progress: false });
            if (result.length > 0) {
              this.onCloseEditMap(result, false);
            } else {
              this.onCloseEditMap(this.polygonsDefault, false);
            }
          },
          (error) => {
            this.setState({ progress: false });
            if (error.status === 405) {
              notification.open({
                message: this.props.res.PERMISSAO,
                description: this.props.res.PERMISSAO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              console.log(error);
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  loadResult = (result) => {
    this.setState({ data: result })
    this.exe(result);
    this.montaStaticData(result);
  };

  exe = (result) => {
    for (let i = 0; i < result.polygons.length; i++) {
      const element = result.polygons[i];
      let poly = JSON.parse(element.points);
      //this.poly.push(turf.polygon([this.geoInvertArray(poly)]));
      this.polyMap({ poly: poly, type: element.type, guid: element.guid });
    }

    let myStyle = this.styles.linha;
    if (result.pontos.length === 0) return;
    let p1 = new L.LatLng(result.path[0][0], result.path[0][1]);
    //let p2 = new L.LatLng(result.path[1][1], result.path[1][0]);
    this.line = new L.polyline([result.path[0], result.path[1]], myStyle);
    this.line.addTo(this.featureGroupL);

    let dIcon = null;
    let c = JSON.parse(localStorage.getItem('c-estimate'));
    if (c && c.base && c.base.length > 0) {
      dIcon = L.icon({
        iconUrl: Marks[11].img,
        iconAnchor: [13, 33]
      });
      this.marker.base = L.marker(p1, { icon: dIcon, pane: 'marks' });

    }

    dIcon = L.icon({
      iconUrl: Img_mark,
      iconAnchor: [21, 14],
      iconSize: [43.2, 28.8]
    });
    this.marker.marker = L.marker(p1, { icon: dIcon, pane: 'marks' });

    if (this.marker.base) {
      this.marker.base.addTo(this.featureGroupI);
    }

    this.marker.marker.addTo(this.featureGroupI);

    if (Object.keys(this.featureGroupPoly._layers).length > 0) {
      this.mymap.fitBounds(this.featureGroupPoly.getBounds());
    } else {
      this.mymap.fitBounds(this.featureGroupI.getBounds());
    }
    this.pathMap(result, 1, this.size);

  };
  showExe = (show) => {
    if (show) {
      this.updatePieChart([this.state.correto - this.state.sobre, this.state.fora, this.state.sobre], 'aplicacao', '1');
      this.updatePieChart([this.state.correto - this.state.sobre, this.state.restante, this.state.sobre], 'area', '1');
      this.updatePieChart([this.state.corretoAlt, this.state.aceitavelAltInf, this.state.aceitavelAltSup, this.state.incorretaAltInf, this.state.incorretaAltSup], 'altura', '1');
      this.updatePieChart([this.state.corretoFlx, this.state.aceitavelFlxInf, this.state.aceitavelFlxSup, this.state.incorretaFlxInf, this.state.incorretaFlxSup], 'fluxo', '1');
      this.updatePieChart([this.state.corretoVlc, this.state.aceitavelVlcInf, this.state.aceitavelVlcSup, this.state.incorretaVlcInf, this.state.incorretaVlcSup], 'velocidade', '1');
    }
  };

  clear = (state, call) => {
    this.mymap.removeLayer(this.featureGroupI);
    this.featureGroupI.clearLayers();
    this.mymap.removeLayer(this.featureGroupL);
    this.featureGroupL.clearLayers();
    this.mymap.removeLayer(this.fGAPP['1']);
    this.fGAPP['1'].clearLayers();
    this.mymap.removeLayer(this.fGAPP['2']);
    this.fGAPP['2'].clearLayers();
    this.mymap.removeLayer(this.fGAPP['3']);
    this.fGAPP['3'].clearLayers();
    this.mymap.removeLayer(this.fGAPP['0']);
    this.fGAPP['0'].clearLayers();
    this.mymap.removeLayer(this.featureGroupPoly);
    this.featureGroupPoly.clearLayers();
    this.mymap.removeLayer(this.featureGroupLS);
    this.featureGroupLS.clearLayers();
    this.mymap.removeLayer(this.fGAPPS['1']);
    this.fGAPPS['1'].clearLayers();
    this.mymap.removeLayer(this.fGAPPS['2']);
    this.fGAPPS['2'].clearLayers();
    this.mymap.removeLayer(this.fGAPPS['3']);
    this.fGAPPS['3'].clearLayers();
    this.mymap.removeLayer(this.fGAPPS['0']);
    this.fGAPPS['0'].clearLayers();
    this.mymap.removeLayer(this.featureGroupPolyS);
    this.featureGroupPolyS.clearLayers();
    this.featureGroupTemp.clearLayers();

    this.featureAtual = {
      caminho: null,
      aplicacao: null,
      area: null,
      //icone: null
    };
    this.marker = {
      base: null,
      marker: null,
      angle: 45
    };
    this.marker_heat = null;
    this.run = true;
    this.current_run = 0;
    this.new_run = 0;
    this.iPlay = 1;
    this.line = null;
    this.altura = [];
    this.fluxo = [];
    this.alturaS = [];
    this.fluxoS = [];
    this.selectedPath = null;

    this.lineClick = false;

    this.updatePieChart([0, 0, 0], 'aplicacao', '0');
    this.updatePieChart([0, 0, 0], 'area', '0');
    this.updatePieChart([0, 0, 0, 0, 0], 'altura', '0');
    this.updatePieChart([0, 0, 0, 0, 0], 'fluxo', '0');

    const temp = {
      id_job: this.state.id_job,
      title: this.state.title,
      task: this.state.task
    }
    if (state) {
      this.setState(JSON.parse(JSON.stringify(this.base)), () => {
        this.setState(temp, () => {
          if (call) {
            call();
          }
        });
      });
    } else {
      if (call) {
        call();
      }
    }
    this.loadTask(false);
  };

  updateColorPath = (path, latlng, setValue, value, color, largura, index) => {
    const updateGraph = () => {
      if (!path.valores || index !== path.valores.index) {
        let valores = null;
        valores = {
          index: index,
          setValue: [setValue],
          value: [value],
          color: [color],
          b: ['']
        };
        path.valores = valores;
      } else {
        path.valores.setValue.push(setValue);
        path.valores.value.push(value);
        path.valores.color.push(color);
        path.valores.b.push('');
      }
    }

    //let color = this.getColor(value, margin, setValue, 2, 10, 'hex');
    let last = path.item.length > 0 ? path.item[path.item.length - 1] : null;

    if (last) {
      let last_latlng = last._latlngs;
      last_latlng.push(new L.LatLng(latlng[0], latlng[1]));
      last.setLatLngs(last_latlng);
      if (last.options.color === color) {
        updateGraph();
      }
    }

    if (!last || last.options.color !== color) {
      let temp_ll = last ? [last._latlngs[last._latlngs.length - 2], last._latlngs[last._latlngs.length - 1]] : null;
      let last_latlng = temp_ll ? temp_ll : latlng;
      last = L.polyline(last_latlng, {
        color: color,
        weight: largura * 2 ** (this.mymap.getZoom() - 17),
        lineCap: 'butt',
        opacity: 1,
        smoothFactor: 1
      });
      path.item.push(last);

      last.addTo(this.fGAPP[path.fGAPP_ID]);
      updateGraph();

      last.on("click", () => {
        this.showBarChart(true, path.item, path.valores);
      });
    }
  };

  showBarChart = (show, item, valores) => {
    if (show) {
      this.barChart.resetZoom();
      this.selectedPath = { item: item, valores: valores };
      this.setState({ showChart: show, barTitle: 'Tiro ' + valores.index, chartIndex: valores.index });
      if (valores.setValue) {
        this.updateBarChart(valores);
      }
      this.lineClick = true;
    } else if (!this.lineClick) {
      this.removeMarkerHeat();
      this.setState({ showChart: show });
    } else {
      this.lineClick = false;
    }
  };
  //buffer 
  limitPieChart = { last: Date.now() };
  bufferPieChart = (values, variavel, tipo) => {
    let limitTime = 500; //ms de intervalo entre o update da mesma variavel
    let limitLast = 50;  //ms de intervalo entre todos os update
    if (this.limitPieChart[variavel] && this.limitPieChart[variavel].timeout) {
      clearTimeout(this.limitPieChart[variavel].timeout);//reseta o timeout caso chame novamamente
    }
    //verifica se esta dentro do limitTime da mesma [variavel] e limitLast de todos os charts
    let newDate = Date.now();
    let canRunC = this.limitPieChart[variavel] && ((this.limitPieChart[variavel].lapsetime + limitTime) < newDate);
    let canRunL = this.limitPieChart[variavel] && ((this.limitPieChart.last + limitLast) < newDate);
    if (!this.limitPieChart[variavel] || (canRunL && canRunC)) {
      if (!this.limitPieChart[variavel]) {
        this.limitPieChart[variavel] = {};
      }
      this.limitPieChart[variavel].lapsetime = newDate;// + (limitLast * 2);
      this.limitPieChart.last = newDate;
      return true;
    } else {
      //timeout caso seja ultima vez chamado
      this.limitPieChart[variavel].timeout = setTimeout(() => {
        this.updatePieChart(values, variavel, tipo);
      }, canRunL ? limitLast : limitTime);
      return false;
    }
  };
  updatePieChart = (values, variavel, tipo, animation) => {
    if (this.bufferPieChart(values, variavel, tipo)) {
      this.pieChartData[tipo === '1' ? 'anime' : 'info'] = values;
      this.pieChart[variavel].data.datasets[0].data = values;
      this.pieChart[variavel].update();
      let v = 0;
      for (let i = 0; i < values.length; i++) {
        v += values[i];
      }
      let update = this.state.legend;
      for (let i = 0; i < values.length; i++) {
        update[variavel]['data' + (i + 1)] = v === 0 ? 0 : Math.round(100 * values[i] / v);
      }
      this.setState({ legend: update });
    }
  };
  createPieChart = (canvas, variavel, tipo) => {
    this.pieChartData[tipo === '1' ? 'anime' : 'info'] = [0, 0, 0];
    let ctx = document.getElementById(canvas).getContext('2d');
    let colors;
    //if (variavel === 'altura' || variavel === 'fluxo' || variavel === 'velocidade') {
    if (variavel !== 'aplicacao' && variavel !== 'area') {
      colors = [
        this.styles.stripColor.correto,
        this.styles.stripColor.aceitavelInf,
        this.styles.stripColor.aceitavelSup,
        this.styles.stripColor.incorretaInf,
        this.styles.stripColor.incorretaSup,
      ];
    } else {
      colors = [
        this.styles.correto_static.color,
        variavel === 'aplicacao' ? this.styles.fora_static.color : this.styles.area_static.color,
        this.styles.sobre_static.light_color,
      ];
    }

    this.pieChart[variavel] = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: false,
        datasets: [{
          backgroundColor: colors,
          data: colors.map((item, key) => (0))
        }]
      }
    });
  };

  updateBarChart = (valores) => {
    if (valores && valores.b.length > 0) {
      this.barChart.data.datasets[1].data = valores.value;
      this.barChart.data.datasets[0].data = valores.setValue;
      this.barChart.data.datasets[1].backgroundColor = valores.color;
      this.barChart.data.labels = valores.b;
    }

    this.barChart.update();
  };
  createBarChart = () => {
    var ctx = document.getElementById("barChart").getContext('2d');
    this.barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          type: 'line',  // override the default type
          data: [],
          borderColor: 'rgb(0, 0, 0)',
          borderWidth: 1
        }, {
          data: [],
          backgroundColor: [],
        }]
      },
      options: {
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              modifierKey: 'ctrl',
            },
            zoom: {
              drag: {
                enabled: true
              },
              mode: 'x',
            },
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          X: {
            grid: {
              display: false,
            },
          }
        },
        maintainAspectRatio: false,
        onHover: (evt, item) => {
          if (item.length) {
            this.hoverBar(item[0]);
          }
        },
        onClick: (evt, item) => {
          if (item.length) {
            //console.log(">data", item[0]._index);
          }
        }
      }
    });
  };
  hoverBar = (item) => {
    if (item) {
      this.removeMarkerHeat();
      let value = 0
      let cood = null;
      let total = 0;
      let plane_direction = 0
      let wind_direction = 0;
      for (let i = 0; i < this.selectedPath.item.length; i++) {
        let n = this.selectedPath.item[i]._latlngs.length - 1;
        total += n;
        if (item.index <= total) {
          total -= n;
          cood = this.selectedPath.item[i]._latlngs[item.index - total];
          if (this.selectedPath.valores.setValue) {
            value = this.selectedPath.valores.value[item.index];
          }
          if (this.selectedPath.valores.type === 'direcaoVento') {
            plane_direction = this.selectedPath.valores.plane_direction[item.index];
            wind_direction = this.selectedPath.valores.wind_direction[item.index];
          }
          break;
        }
        total -= 1;
      }

      let up = null;
      let down = null;
      if (this.selectedPath.valores.type === 'altura') {
        up = this.state.alturaMargemUp.value;
        down = this.state.alturaMargemDown.value;
      } else if (this.selectedPath.valores.type === 'fluxo') {
        up = this.state.fluxoMargemUp.value;
        down = this.state.fluxoMargemDown.value;
      } else if (this.selectedPath.valores.type === 'velocidade') {
        up = this.state.velocidadeMargemUp.value;
        down = this.state.velocidadeMargemDown.value;
      } else if (this.selectedPath.valores.type === 'velocidadeVento') {
        up = this.state.velocidadeVMargemUp.value;
        down = this.state.velocidadeVMargemDown.value;
      } else if (this.selectedPath.valores.type === 'direcaoVento') {
        up = this.state.anguloVMargemUp.value;
        down = this.state.anguloVMargemDown.value;

        this.updatePlaneGraph(plane_direction, wind_direction);

      } else if (this.selectedPath.valores.type === 'temperatura') {
        up = this.state.temperaturaMargemUp.value;
        down = this.state.temperaturaMargemDown.value;
      } else if (this.selectedPath.valores.type === 'humidade') {
        up = this.state.UmidadeMargemUp.value;
        down = this.state.UmidadeMargemDown.value;
      } else if (this.selectedPath.valores.type === 'deltaT') {
        up = 2;
        down = 8;
      }

      let icon = value > up.value ? Img_up
        : value < down.value ? Img_down
          : Img_fine;
      let dIcon = L.icon({
        iconUrl: icon,
        iconSize: [30, 48],
        iconAnchor: [18, 50]
      });
      this.marker_heat = L.marker(cood, { icon: dIcon, pane: 'marks' });
      this.marker_heat.addTo(this.featureGroupTemp);
    }
  };

  updatePlaneGraph = (plane_direction, wind_direction) => {
    this.setState({ angulo_plane: plane_direction, angulo_wind: wind_direction })
  };

  removeMarkerHeat = () => {
    if (this.marker_heat) {
      this.featureGroupTemp.removeLayer(this.marker_heat);
    }
  };

  downloadRel = () => {
    let apiUrl = Config.server + '/relanalise';
    let method = 'POST';

    const formData = new URLSearchParams();
    formData.append('culture', this.props.res.LINGUA);
    formData.append('task', this.state.task);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.blob();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          let file_name = "ALS" + (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).replace(/[^a-z0-9_]/gi, "") + '.pdf';
          if (result.type === 'application/pdf' && result.size > 0) {
            saveAs(result, file_name);
          } else {
            console.log(result);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  getAuto = (api, variavel) => {
    let apiUrl = Config.server + '/' + api;
    let method = 'GET';

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            this.setState({ [variavel]: result });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  geojsonToLeafletArray = (poly) => {
    let array = [];
    for (let i = 0; i < poly.length; i++) {
      const element = poly[i];
      if (element) {
        array = array.concat([[element[1], element[0]]]);
      }
    }
    return array
  };
  callNew = () => {
    this.setState({
      clear: true,
      new: {
        msg: this.props.res.NOVO_ANALISE_CONFIRM,
        func: () => {
          this.clear(true, () => {
            //this.setState({ id_job: this.state.id_job, task: this.state.task });
          }
          );
          localStorage.setItem('check-task', 'null');
        }
      }
    });
  };

  updateMap = (type, featureGroup) => {
    if (this.featureAtual[type]) this.mymap.removeLayer(this.featureAtual[type]);
    this.featureAtual[type] = featureGroup;
    let types = this.state.listTypes.filter(item => item.type === type);
    if (types.length > 0 && types[0].active) {
      this.featureAtual[type].addTo(this.mymap);

      if (type === 'area' || type === 'aplicacao') this.checkTitleMap(this.state.listTypes);
    }
  };

  setStack = (item) => {
    let list = this.state.listTypes;
    item.active = !item.active;
    this.setState({ listTypes: list });
    //remove ou add layers no mapa
    if (item.type !== 'name') {
      if (item.active) {
        this.featureAtual[item.type].addTo(this.mymap);
      } else {
        this.mymap.removeLayer(this.featureAtual[item.type]);
      }
    }
    //remove ou add label no mapa
    if (item.type === 'area' || item.type === 'name') this.checkTitleMap(list);
  };

  checkTitleMap = (list) => {
    //let show = list[2].active || list[1].active ? (list[2].active ? 1 : 2) : 0;
    for (let tag in this.polyNames) {
      if (list[2].active && !tag.includes('_h')) {
        const myLayer = this.mymap._layers[tag];
        if (myLayer !== undefined) {
          if (list[3].active) {
            myLayer.unbindTooltip();
            myLayer.bindTooltip(this.polyNames[tag],
              { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' }
            ).openTooltip();
          } else {
            myLayer.unbindTooltip();
          }
        }
      }
    }
  }

  onCloseEditMap = (itens, move = true) => {
    this.changeTabMenu(this.state.tabMenu);
    if (!itens || !(itens instanceof Array) || itens.length === 0) {
      this.setState({ showForm: false });
      return;
    }
    this.clear(false, () => {
      this.featureGroupPoly.addTo(this.mymap);
      for (let i = 0; i < itens.length; i++) {
        this.polyMap(itens[i]);
      }
      if (itens.length > 0) this.mymap.fitBounds(this.featureGroupPoly.getBounds());
      let state = { showForm: false };
      if (itens && itens.length > 0) state.polygons = itens;
      this.setState(state, () => {
        if (move) document.getElementById("divAreas").scrollIntoView({ behavior: "smooth", block: "start" });
      });
    });
  };

  getBackgroud = () => {
    if (this.typeMapItem === 'analise') {
      return null;
    } else {
      if (this.featureAtual['area']) this.mymap.removeLayer(this.featureAtual['area']);
      if (this.featureAtual['caminho']) this.mymap.removeLayer(this.featureAtual['caminho']);
      if (this.featureAtual['aplicacao']) this.mymap.removeLayer(this.featureAtual['aplicacao']);

      let type = this.state.tabMenu;
      let tab = this.state.tab;
      let poly = null;
      let path = null;
      let app = null;
      let back = [];
      if (type === '0' || type === '2') {
        poly = this.featureGroupPolyS;
        path = this.featureGroupLS;
        if (tab === '0') {
          app = this.fGAPPS[this.state.tab];
        } else {
          for (let item in this.fGAPPS[this.state.tab]._layers) {
            if (item.includes('_h')) back.push(this.fGAPPS[this.state.tab]._layers[item]);
          }
          if (tab === '1') {
            app = this.alturaS;
          } else if (tab === '2') {
            app = this.fluxoS;
          } else if (tab === '3') {
            app = this.velocidadeS;
          }
        }
      } else if (type === '1') {
        poly = this.featureGroupPoly;
        path = this.this.featureGroupL;
        if (tab === '0') {
          app = this.this.fGAPP[this.state.tab];
        } else {
          for (let item in this.fGAPP[this.state.tab]._layers) {
            if (item.includes('_h')) back.push(this.fGAPP[this.state.tab]._layers[item]);
          }
          if (tab === '1') {
            app = this.altura;
          } else if (tab === '2') {
            app = this.fluxo;
          } else if (tab === '3') {
            app = this.velocidade;
          }
        }
      }

      return { poly, back, path, app, type: tab, largura: this.state.larguraSet };
    }
  };

  showMapItem = (type) => {
    this.typeMapItem = type;
    this.setState({ showForm: true })
  };

  showAutoComplete = (divName) => () => {
    let dF = document.getElementById(divName);
    if (!dF.className.includes('autoDiv')) {
      dF.classList.remove("sr-only");
      dF.classList.toggle("autoDiv");
    } else {
      dF.classList.remove("autoDiv");
      dF.classList.toggle("sr-only");
    }
  };

  limitChar = (string, limit) => {
    let ret = string;
    let size = SPW(string, { font: "Arial", size: 16 });
    if (size > limit) {
      ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
    }
    return ret;
  };

  updateAccrodion = (div, is) => {
    this.acc[div] = !is;
  };

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupTemp.removeLayer(this.mymap._layers['regua']);
    this.featureGroupTemp.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupTemp.removeLayer(this.mymap._layers['reguaM2']);
    this.ruler_p.second.lat = null;
    this.ruler_p.second.lng = null;
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.mymap.dragging.enable();
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      if (this.drawCreateToolbar) {
        this.drawCreateToolbar.disable();
      }
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  };

  completeRuler = () => {
    if (this.drawCreateToolbar) {
      this.drawCreateToolbar.disable();
    }

    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupTemp);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('mousedown', (e) => {
      this.mymap.dragging.disable();
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupTemp);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('mousedown', (e) => {
      this.mymap.dragging.disable();
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupTemp);
  };

  cancelRuler = () => {
    if (this.ruler_p.first.lat) {
      this.regua();
    }
  };

  task_title = '...';
  checkTitle = (task, i) => {
    if (this.task_title !== task.job_title) {
      if (this.state.itensTask.length - 1 === i) {
        this.task_title = '...';
      } else {
        this.task_title = task.job_title;
      }
      return true;
    }
    if (this.state.itensTask.length - 1 === i) {
      this.task_title = '...';
    }
    return false;
  };

  render() {
    const { res, link } = this.props;
    const styleTitle = { marginTop: '1rem', width: 'auto', height: '2.3rem', marginBottom: '-1.5rem', textDecoration: 'underline', textAlign: 'center' };

    return (
      <div className="gridEst maxH">
        <div className="divContent">
          <div className="containerTitle">
            <div><h1>{res.QUALIDADE}</h1></div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" color="primary" style={{ marginRight: '.4rem' }} disabled={this.state.state !== 1 || this.state.loading} className="popUpButton" onClick={event => this.updateTask()} title={res.GERAR_ANALISE}>
                <SvgIcon viewBox="-2 0 26 26" className="iconArrow"
                  style={{ color: 'white', width: '2rem', height: '2rem' }}>
                  <path id="polygon"
                    d="M3.5 18.99l11 .01c.67 0 1.27-.33 1.63-.84L20.5 12l-4.37-6.16c-.36-.51-.96-.84-1.63-.84l-11 .01L8.34 12 3.5 18.99z"
                  />
                </SvgIcon>
              </Button>
              <Button variant="contained" color="primary" style={{ marginRight: '.4rem' }} disabled={this.state.state !== 3} className="popUpButton" onClick={event => { this.downloadRel(); }} title={res.BAIXAR_RELATORIO}>
                <SvgIcon viewBox="0 0 1000 1000" className="iconArrow"
                  style={{ color: 'white', transform: 'rotate(180deg)', width: '2rem', height: '2rem' }}>
                  <path id="polygon" d="M326.156 867.15l462.329 0c20.0054,0 29.8386,-9.83318 29.8386,-29.8047l0 -633.189c0,-20.0054 -9.83318,-29.8386 -29.8386,-29.8386l-462.329 0c-19.8359,0 -29.8386,9.83318 -29.8386,29.8386l0 633.189c0,19.9715 10.0027,29.8047 29.8386,29.8047zm166.52 -540.486l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm-63.5087 0l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm0 63.0001l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm63.5087 0l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm-63.5087 66.3231l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm63.5087 0l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm68.8322 165.503l98.1622 55.8457c9.83318,-17.3267 14.648,-35.8402 14.648,-55.8457 0,-31.161 -10.986,-57.8123 -32.992,-79.6487 -22.006,-22.006 -48.4877,-32.992 -79.8183,-32.992 -31.161,0 -57.8462,10.986 -79.8522,32.992 -22.006,21.8364 -32.992,48.4877 -32.992,79.6487 0,31.0254 10.986,57.5071 32.992,79.6826 22.006,22.1755 48.6912,33.1615 79.8522,33.1615l0 -112.844zm20.9887 31.3305l9.83318 112.336c34.5178,-3.32294 61.8473,-18.8187 81.9883,-46.8263l-91.8215 -65.5093zm-237.319 163.163l0 -587.99 426.828 0 0 587.99 -426.828 0zm-96.6703 4.84877l47.8096 0 0 -44.0119 -31.161 0 0 -504.306 31.161 0 0 -46.0125 -47.8096 0c-16.852,0 -25.3289,8.68032 -25.3289,26.0071l0 542.825c0,16.9877 8.47688,25.4984 25.3289,25.4984z" />
                </SvgIcon>
              </Button>
              <Button variant="contained" color="primary" disabled={this.state.itensTask.length === 0 && !this.state.loading} className="popUpButton" onClick={event => this.loadTask(true)} title={res.EXIBIR_ANALISE}>
                <MaterialIcon icon="settings" className="iconMap highlightSVG" />
              </Button>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Dialog
            open={this.state.clear}
            onClose={() => this.setState({ clear: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.new.msg}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => this.state.new.func()} color="primary">
                {res.SIM}
              </Button>
              <Button onClick={() => this.setState({ clear: false })} color="primary" autoFocus>
                {res.NAO}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog onClose={event => this.setState({ openTask: false })} aria-labelledby="simple-dialog-title" open={this.state.openTask}>
            <DialogTitle id="simple-dialog-title">Processando</DialogTitle>
            <List>
              {this.state.itensTask.map((task, i) => (
                <>
                  {this.checkTitle(task, i) &&
                    <div style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)', padding: '0 1rem', fontSize: '14px', backgroundColor: '#f2f2f2' }}>{task.job_title}</div>
                  }
                  <ListItem button onClick={(event) => task.progress === 100 && this.getTask(task.id)} key={task.id}>
                    <ListItemText primary={task.title} />
                    <div style={{ height: '1rem', width: '2.5rem', textAlign: 'right', alignSelf: 'baseline', marginTop: '.3rem' }}>{task.progress + '%'}</div>
                  </ListItem>
                </>
              ))}
            </List>
          </Dialog>
          <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
            {this.state.progressValue > 0 ?
              <CircularProgress style={{ scale: '2' }} variant="determinate" value={this.state.progressValue} color="inherit" disableShrink /> :
              <CircularProgress color="inherit" disableShrink />
            }
          </Backd>
          <Dialog onClose={this.onCloseEditMap} fullWidth={true} maxWidth='lg' aria-labelledby="simple-dialog-title" open={this.state.showForm}>
            <MapItem link={link} res={res} type={this.typeMapItem} itens={this.state.polygons} getBackgroud={this.getBackgroud} idJob={this.state.id_job} onClose={this.onCloseEditMap} />
          </Dialog>
          <div className="rowMapCO maxVH">
            <div id="divFloat" className="div-list_p divContentList list-p">
              <div>
                <div style={{ marginTop: '-.9rem', height: '5rem', padding: '1rem' }}>
                  <Tab value={this.state.tabMenu} handleChange={this.handleChangeTab} name="tabMenu" itens={this.state.tabMenuItens} />
                </div>
              </div>
              <div className="div-list_h">
                <div style={{ paddingLeft: '.5rem' }} className="div-list_box">
                  <div style={{ width: 'calc(100% - .7rem)', height: '100%', position: 'absolute', zIndex: 10, backgroundColor: 'white', display: this.state.task ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <div style={{ marginBottom: '.5rem', fontWeight: 'bold' }}> {res.IMPORTAR_JOB + ' (.zjob)'} </div>
                      <label htmlFor="contained-button-file">
                        <Button component="span" variant="contained" color="primary" style={{ marginRight: '.4rem' }} className="popUpButton" title={res.IMPORTAR_JOB}>
                          <SvgIcon viewBox="0 0 1000 1000" className="iconArrow"
                            style={{ color: 'white', transform: 'rotate(180deg)', width: '2rem', height: '2rem' }}>
                            <path id="polygon" d="M122.513 646.499c3.66376,13.0115 11.813,21.1608 24.4821,24.3451l273.344 0 36.1582 0.47937 25.5093 0 -0.513611 13.662 0 107.002c4.17737,9.51892 
                            9.69012,14.8605 16.504,16.1959 6.84814,1.16418 14.5181,3.97192 23.0098,8.14929 8.14929,-4.17737 15.4768,-6.98511 22.1537,-8.14929 6.67694,-1.33539 12.3267,-6.84814 
                            16.8464,-16.6752l0 -106.523 0 -13.662 25.5093 0 35.6446 -0.47937 273.344 0c12.3267,-3.18439 20.5102,-11.3337 24.4821,-24.3451l-0.47937 -88.8204 -365.828 0 -6.84814 
                            -69.3374 0.992981 10.1695 3.01318 -106.009 16.1616 0 4.34857 145.66 4.31433 -145.66 16.1616 0 4.34857 145.66 4.00616 -145.66 15.9904 0 4.48553 145.66 4.34857 
                            -145.66 10.3407 0c0.992981,-0.513611 1.81476,-0.992981 2.67078,-1.33539 0.821777,-0.342407 1.64355,-0.650574 2.63653,-0.992981l4.34857 147.988 4.48553 
                            -156.822c0.684814,-0.992981 1.19842,-2.67078 1.849,-5.34155l-1.33539 -6.33453 3.83496 -143.16 -18.0106 0 2.84198 102.174 -0.513611 -1.50659 -11.6418 0 3.32135 
                            -100.668 -18.49 0 2.97894 100.668 -12.3267 0 3.01318 -100.668 -18.0106 0 2.84198 100.668 -12.1555 0 2.97894 -100.668 -18.1476 0 2.49957 100.668 -12.1897 0 2.84198 
                            -100.668 -18.0106 0 2.84198 100.668 -1.81476 0c-4.00616,-1.67779 -7.36175,-4.17737 -10.3407,-7.32751l2.97894 -93.3402 -18.1476 0 2.49957 92.8266c-3.01318,3.32135 
                            -6.50574,5.82092 -10.8543,7.84112l-0.821777 0 2.32837 -100.668 -17.9764 0 2.80774 100.668 -12.1555 0 3.01318 -100.668 -18.1818 0 3.01318 100.668 -12.6691 0 2.80774 
                            -100.668 -17.9764 0 2.97894 100.668 -12.3267 0 3.01318 -100.668 -18.1818 0 3.01318 100.668 -12.1555 0 -2.49957 8.8341 1.98596 -7.32751 2.84198 -102.174 -18.0106 0 
                            4.34857 143.16 -1.849 6.33453c-0.342407,-1.33539 0.342407,0.513611 1.849,5.34155l4.99914 156.822 4.31433 -147.988c0,0 1.67779,0.821777 4.82794,2.32837l10.8543 0 4.31433 
                            145.66 4.51977 -145.66 15.9904 0 4.48553 145.66 3.83496 -145.66 16.6752 0 3.83496 145.66 4.34857 -145.66 16.1616 0 2.97894 100.668 -6.33453 64.5095 -365.828 0 
                            -0.47937 88.8204zm19.6542 -419.997l9.17651 311.659 8.8341 -311.659 -18.0106 0zm24.8245 0l8.8341 311.659 9.17651 -311.659 -18.0106 0zm24.3451 0l9.31347 311.659 8.8341 
                            -311.659 -18.1476 0zm24.9957 0l8.8341 311.659 9.17651 -311.659 -18.0106 0zm24.3451 0l9.31347 311.659 8.8341 -311.659 -18.1476 0zm24.9957 0l8.8341 311.659 9.14227 
                            -311.659 -17.9764 0zm24.3109 0l9.34771 311.659 8.8341 -311.659 -18.1818 0zm24.5163 0l9.17651 311.659 9.31347 -311.659 -18.49 0zm24.8245 0l8.8341 311.659 9.34771 
                            -311.659 -18.1818 0zm24.5163 0l9.14227 311.659 9.34771 -311.659 -18.49 0zm135.148 228.488l1.50659 -62.4893 4.34857 0 -5.85516 62.4893zm37.6648 -62.4893l4.86218 0 
                            1.98596 72.3164 -6.84814 -72.3164zm110.837 -9.65588c0,-0.342407 0.171204,-0.513611 0.513611,-0.513611l-0.513611 0.513611zm12.1897 -156.343l9.31347 311.659 8.6629 
                            -311.659 -17.9764 0zm24.8245 0l8.8341 311.659 9.31347 -311.659 -18.1476 0zm24.4821 0l9.34771 311.659 9.17651 -311.659 -18.5242 0zm24.8588 0l9.31347 311.659 8.8341 
                            -311.659 -18.1476 0zm24.4821 0l9.17651 311.659 9.31347 -311.659 -18.49 0zm24.8245 0l9.34771 311.659 8.8341 -311.659 -18.1818 0zm24.0027 0l9.17651 311.659 8.8341 
                            -311.659 -18.0106 0zm24.8588 0l9.31347 311.659 8.8341 -311.659 -18.1476 0zm24.4821 0l9.17651 311.659 9.31347 -311.659 -18.49 0zm24.8245 0l8.8341 311.659 9.34771 
                            -311.659 -18.1818 0z" />
                          </SvgIcon>
                        </Button>
                      </label>
                    </div>
                  </div>
                  <div className="div-list_itens" style={{ display: this.state.tabMenu === '0' || this.state.tabMenu === '1' ? 'block' : 'none' }}>
                    <div className="notTooBig">
                      <div>
                        <h2 title={
                          this.state.title_sk && this.state.title === '...' ?
                            this.state.title_sk : this.state.title
                        } >{
                            (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).length > 30 ?
                              (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).substring(0, 27) + '...' :
                              (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title)}</h2>
                      </div>
                      {this.state.title !== '...' && this.state.title !== this.state.title_sk &&
                        <div style={{ textAlign: 'right', marginTop: '-1rem' }}>
                          <h4 style={{ color: '#bcbcbc' }}>{this.state.title_sk}</h4>
                        </div>
                      }
                      <Accordion title={'Informações Gerais'}>
                        <div>
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem', gap: '.5rem' }}>
                            <TextField
                              label={res.NAME_OS}
                              value={this.state.gNome}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <TextField
                              label={res.AERONAVE}
                              value={this.state.gAeronave}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <TextField
                              label={res.CLIENTE}
                              value={this.state.gCliente}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <TextField
                              label={res.PILOTO}
                              value={this.state.gPiloto}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <TextField
                              label={res.CULTURA}
                              value={this.state.gCultura}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <TextField
                              label={res.STARTDATE}
                              value={this.state.dateIni}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              label={res.ENDDATE}
                              value={this.state.dateFim}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              label={res.AREA_TOTAL_TALHOES}
                              value={this.state.area_talhoes.toFixed(2) + ' ha'}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              label={res.DISTANCIA_PERCORRIDA}
                              value={this.state.distancia_percorrida.toFixed(2) + ' km'}
                              size="small"
                              margin="dense"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </div>
                        </div>
                      </Accordion>
                      <Accordion title={'Índice de Aplicação'}>
                        <div style={{ marginTop: '10px' }}>
                          <div style={{ marginBottom: '1rem' }}><h4 style={styleTitle}>{res.APLICACAO.toUpperCase()}</h4></div>
                          <Slider res={res} text={res.INDICE_ACERTO} value={this.state.gAcerto} />
                          <Slider res={res} text={res.INDICE_UNIFORMIDADE} value={this.state.gUniformidade} />
                          <Slider res={res} text={res.INDICE_FALHA} value={this.state.gFalha} inverted={true} />
                          <Slider res={res} text={res.INDICE_PERDA} value={this.state.gPerda} inverted={true} />
                          <div style={{ marginBottom: '1rem' }}><h4 style={styleTitle}>{"COMPLEMENTO".toUpperCase()}</h4></div>
                          {!isNaN(this.state.gfluxo) ? <Slider res={res} text={res.INDICE_FLUXO} value={this.state.gfluxo} /> : null}
                          {!isNaN(this.state.gAltura) ? <Slider res={res} text={res.INDICE_ALTURA} value={this.state.gAltura} /> : null}
                          {!isNaN(this.state.gVelocidade) ? <Slider res={res} text={res.INDICE_VELOCIDADE} value={this.state.gVelocidade} /> : null}
                          <div style={{ marginBottom: '1rem' }}><h4 style={styleTitle}>{"CLIMA".toUpperCase()}</h4></div>
                          {!isNaN(this.state.gVelocidadeV) ? <Slider res={res} text={res.INDICE_VELOCIDADE_VENTO} value={this.state.gVelocidadeV} /> : null}
                          {!isNaN(this.state.gDirecaoV) ? <Slider res={res} text={res.INDICE_DIRECAO_VENTO} value={this.state.gDirecaoV} /> : null}
                          {!isNaN(this.state.gDeltaT) ? <Slider res={res} text={res.INDICE_DELTA_T} value={this.state.gDeltaT} /> : null}
                          {!isNaN(this.state.gTemperatura) ? <Slider res={res} text={res.INDICE_TEMPERATURA} value={this.state.gTemperatura} /> : null}
                          {!isNaN(this.state.gHumidade) ? <Slider res={res} text={res.INDICE_HUMIDADE} value={this.state.gHumidade} /> : null}
                        </div>
                      </Accordion>
                      <Accordion id="divApp" title={'Informação de Aplicação'} onUpdate={(is) => this.updateAccrodion("divApp", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                margin="normal"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.RESTANTE}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.restanteS : this.state.restante).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.area_static.color, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.ACERTO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoS : this.state.correto).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.correto_static.light_color, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.SOBREPOSTO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.sobreS : this.state.sobre).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.sobre_static.light_color, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.FALHA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.foraS : this.state.fora).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.fora_static.color, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '12.5rem', padding: '2rem', paddingTop: '0.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <span>{res.AREA}</span>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.correto_static.light_color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.area.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.area_static.color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.area.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.sobre_static.light_color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.area.data3}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart2"></canvas>
                              <span>{res.APLICACAO}</span>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.correto_static.light_color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.aplicacao.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.fora_static.color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.aplicacao.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.sobre_static.light_color, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.aplicacao.data3}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart1"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divAlt" disabled={!this.state.tabItens[1].active} title={'Informação de Altura'} onUpdate={(is) => this.updateAccrodion("divAlt", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <BarHeat values={[
                            Number(this.state.alturaSet) - this.state.alturaMargemDown.value - this.state.alturaLimiteDown.value,
                            Number(this.state.alturaSet) - this.state.alturaMargemDown.value,
                            Number(this.state.alturaSet),
                            Number(this.state.alturaSet) + this.state.alturaMargemUp.value,
                            Number(this.state.alturaSet) + this.state.alturaMargemUp.value + this.state.alturaLimiteUp.value
                          ]} atual={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_alturaS : this.state.atual_altura)} />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.ALTURA_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_alturaS : this.state.media_altura).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_ALTURA_CORRETA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoAltS : this.state.corretoAlt).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_ALTURA_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelAltInfS : this.state.aceitavelAltInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_ALTURA_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelAltSupS : this.state.aceitavelAltSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_ALTURA_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaAltInfS : this.state.incorretaAltInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_ALTURA_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaAltSupS : this.state.incorretaAltSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.altura.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.altura.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.altura.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.altura.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.altura.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart3"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divFlo" disabled={!this.state.tabItens[2].active} title={'Informação de Fluxo'} onUpdate={(is) => this.updateAccrodion("divFlo", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <BarHeat values={[
                            Number(this.state.fluxoSet) - this.state.fluxoMargemDown.value - this.state.fluxoLimiteDown.value,
                            Number(this.state.fluxoSet) - this.state.fluxoMargemDown.value,
                            Number(this.state.fluxoSet),
                            Number(this.state.fluxoSet) + this.state.fluxoMargemUp.value,
                            Number(this.state.fluxoSet) + this.state.fluxoMargemUp.value + this.state.fluxoLimiteUp.value
                          ]} atual={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_fluxoS : this.state.atual_fluxo)} />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.FLUXO_MEDIO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_fluxoS : this.state.media_fluxo).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_FLUXO_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoFlxS : this.state.corretoFlx).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_FLUXO_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelFlxInfS : this.state.aceitavelFlxInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_FLUXO_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelFlxSupS : this.state.aceitavelFlxSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_FLUXO_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaFlxInfS : this.state.incorretaFlxInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_FLUXO_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaFlxSupS : this.state.incorretaFlxSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.fluxo.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.fluxo.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.fluxo.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.fluxo.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.fluxo.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart4"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divVel" disabled={!this.state.tabItens[3].active} title={'Informação de Velocidade'} onUpdate={(is) => this.updateAccrodion("divVel", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <BarHeat values={[
                            Number(this.state.velocidadeSet) - this.state.velocidadeMargemDown.value - this.state.velocidadeLimiteDown.value,
                            Number(this.state.velocidadeSet) - this.state.velocidadeMargemDown.value,
                            Number(this.state.velocidadeSet),
                            Number(this.state.velocidadeSet) + this.state.velocidadeMargemUp.value,
                            Number(this.state.velocidadeSet) + this.state.velocidadeMargemUp.value + this.state.velocidadeLimiteUp.value
                          ]} atual={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_velocidadeS : this.state.atual_velocidade)} />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.VELOCIDADE_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_velocidadeS : this.state.media_velocidade).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_CORRETA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoVlcS : this.state.corretoVlc).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelVlcInfS : this.state.aceitavelVlcInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelVlcSupS : this.state.aceitavelVlcSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaVlcInfS : this.state.incorretaVlcInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaVlcSupS : this.state.incorretaVlcSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidade.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidade.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidade.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidade.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidade.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart5"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divVen" disabled={!this.state.tabItensVento[0].active} title={'Informação de Velocidade do Vento'} onUpdate={(is) => this.updateAccrodion("divVen", is)}>
                        <div>
                          <TextField
                            id="time"
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <BarHeat values={[
                            Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value - this.state.velocidadeVLimiteDown.value,
                            Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value,
                            null,//Number(this.state.velocidadeVSet),
                            Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value,
                            Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value + this.state.velocidadeVLimiteUp.value
                          ]} grayZero={true} atual={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_velocidadeVS : this.state.atual_velocidadeV)} />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.VELOCIDADE_V_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_velocidade_ventoS : this.state.media_velocidade_vento).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_V_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoVlcVS : this.state.corretoVlcV).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_V_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelVlcVInfS : this.state.aceitavelVlcVInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_V_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelVlcVSupS : this.state.aceitavelVlcVSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_V_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaVlcVInfS : this.state.incorretaVlcVInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_VELOCIDADE_V_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaVlcVSupS : this.state.incorretaVlcVSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidadeVento.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidadeVento.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidadeVento.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidadeVento.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.velocidadeVento.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart6"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divVenD" disabled={!this.state.tabItensVento[1].active} title={'Informação de Direcao do Vento'} onUpdate={(is) => this.updateAccrodion("divVenD", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <PlaneHeat type="G" ckey="2" values={[
                            Number(this.state.anguloVLimiteDown.value),
                            Number(this.state.anguloVMargemDown.value),
                            Number(this.state.anguloVSet),
                            this.state.espelharAnguloV ? Number(this.state.anguloVMargemDown.value) : Number(this.state.anguloVMargemUp.value),
                            this.state.espelharAnguloV ? Number(this.state.anguloVLimiteDown.value) : Number(this.state.anguloVLimiteUp.value)
                          ]} limit="90" />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DIRECAO_V_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoAngVS : this.state.corretoAngV).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DIRECAO_V_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelAngVInfS : this.state.aceitavelAngVInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DIRECAO_V_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelAngVSupS : this.state.aceitavelAngVSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DIRECAO_V_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaAngVInfS : this.state.incorretaAngVInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DIRECAO_V_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaAngVSupS : this.state.incorretaAngVSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.direcaoVento.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.direcaoVento.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.direcaoVento.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.direcaoVento.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.direcaoVento.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart7"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divDeltaT" disabled={!this.state.tabItensDeltaT[0].active} title={'Informação de Delta-T (ΔT)'} onUpdate={(is) => this.updateAccrodion("divDeltaT", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <DeltaTHeat values={this.state} temperatura_values={[
                            Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value - this.state.temperaturaLimiteDown.value,
                            Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value,
                            null,//Number(this.state.temperaturaSet),
                            Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value,
                            Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value + this.state.temperaturaLimiteUp.value
                          ]} humidade_values={[
                            Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value - this.state.UmidadeLimiteDown.value,
                            Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value,
                            null,//Number(this.state.UmidadeSet),     temperatura='30' humidade='75' atual='1.8'
                            Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value,
                            Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value + this.state.UmidadeLimiteUp.value
                          ]}
                            atual={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_DeltaTS : this.state.media_DeltaT)}
                            humidade={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_UmidadeS : this.state.media_Umidade)}
                            temperatura={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_temperaturaS : this.state.media_temperatura)}
                          />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.DELTAT_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_DeltaTS : this.state.media_DeltaT).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_DELTAT_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoDeltaT : this.state.corretoDelta).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={"Área com Delta-T Aceitavel"/*res.AREA_DELTAT_ACEITAVEL_INF*/}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ?
                                    this.state.aceitavelDeltaTInfS + this.state.aceitavelDeltaTSupS :
                                    this.state.aceitavelDeltaTInf + this.state.aceitavelDeltaTSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={"Área com Delta-T Incorreta"/*res.AREA_DELTAT_INCORRETA_INF*/}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ?
                                    this.state.incorretaDeltaTInfS + this.state.incorretaDeltaTSupS :
                                    this.state.incorretaDeltaTInf + this.state.incorretaDeltaTSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.deltaT.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.deltaT.data1 + this.state.legend.deltaT.data3}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.deltaT.data4 + this.state.legend.deltaT.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart8"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divTemp" disabled={!this.state.tabItensDeltaT[1].active} title={'Informação de Temperatura'} onUpdate={(is) => this.updateAccrodion("divTemp", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.TEMPERATURA_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_temperaturaS : this.state.media_temperatura).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_TEMPERATURA_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoTempVS : this.state.corretoTempV).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_TEMPERATURA_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelTempInfS : this.state.aceitavelTempInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_TEMPERATURA_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelTempSupS : this.state.aceitavelTempSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_TEMPERATURA_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaTempInfS : this.state.incorretaTempInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_TEMPERATURA_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaTempSupS : this.state.incorretaTempSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.temperatura.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.temperatura.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.temperatura.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.temperatura.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.temperatura.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart9"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion id="divHum" disabled={!this.state.tabItensDeltaT[2].active} title={'Informação de Umidade'} onUpdate={(is) => this.updateAccrodion("divHum", is)}>
                        <div>
                          <TextField
                            label={res.TEMPO}
                            value={tools.fancyTimeFormat(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.timeS : this.state.time)}
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                          <div style={{ display: 'flex', flexFlow: 'row wrap', width: '24rem' }}>
                            <div style={{ width: this.state.data !== null ? '10rem' : 'auto' }}>
                              <TextField
                                label={res.AREA_APLICADA}
                                value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.areaS : this.state.area).toFixed(2)}
                                size="small"
                                margin="dense"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.UMIDADE_MEDIA}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.media_UmidadeS : this.state.media_Umidade).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_UMIDADE_CORRETO}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.corretoTempVS : this.state.corretoTempV).toFixed(2)}
                                  size="small"
                                  margin="dense"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '1.8rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_UMIDADE_ACEITAVEL_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelUmiInfS : this.state.aceitavelUmiInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_UMIDADE_INCORRETA_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.aceitavelUmiSupS : this.state.aceitavelUmiSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_UMIDADE_INCORRETA_INF}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaUmiInfS : this.state.incorretaUmiInf).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <TextField
                                  label={res.AREA_UMIDADE_ACEITAVEL_SUP}
                                  value={(!this.state.updateValuesAnimation || this.state.tabMenu === '0' ? this.state.incorretaUmiSupS : this.state.incorretaUmiSup).toFixed(2)}
                                  margin="normal"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                />
                                <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px', position: 'absolute', right: '.5rem', top: '2.5rem' }}></div>
                              </div>
                            </div>
                            <div style={{ width: '13.5rem', padding: '2rem', paddingTop: '6.5rem', textAlign: 'center', display: (this.state.timeS > 0) ? 'block' : 'none' }}>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.humidade.data2}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.correto, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.humidade.data1}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.aceitavelSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.humidade.data3}%</span>
                                </div>
                              </div>
                              <div style={{ width: '100%', height: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaInf, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.humidade.data4}%</span>
                                </div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}></div>
                                <div style={{ width: '3.5rem', display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '1rem', height: '1rem', backgroundColor: this.styles.stripColor.incorretaSup, borderRadius: '5px' }}></div>
                                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>{this.state.legend.humidade.data5}%</span>
                                </div>
                              </div>
                              <canvas id="pieChart10"></canvas>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                  <div className="div-list_itens" style={{ display: this.state.tabMenu === '2' ? 'block' : 'none' }}>
                    <div className="notTooBig" style={{ display: 'flex', flexDirection: 'column' }}>
                      <h2 title={this.state.title} style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)' }}>{this.state.title.length > 30 ? this.state.title.substring(0, 27) + '...' : this.state.title}</h2>
                      <Accordion title={'Informações Gerais'}>
                        <div>
                          <TextField
                            label={res.NAME_OS}
                            value={this.state.nome}
                            onChange={this.handleChange('nome')}
                            size="small"
                            margin="dense"
                            disabled={this.state.state === 3}
                            fullWidth
                            onInput={(e) => {
                              e.target.value = e.target.value.toString().slice(0, 255)
                            }}
                          />
                          <div>
                            <TextField
                              autoComplete="off"
                              label={res.AERONAVE}
                              value={this.state.aeronave}
                              onChange={this.handleChange('aeronave')}
                              disabled={this.state.state === 3}
                              fullWidth
                              onInput={(e) => {
                                e.target.value = e.target.value.toString().slice(0, 45)
                              }}
                              size="small"
                              margin="dense"
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  let item = this.state.planes.filter((item) => { return item.name.includes(this.state.plane_est) });
                                  if (item.length > 0) {
                                    this.setState({ aeronave: item[0].name });
                                  }
                                }
                              }}
                              onFocus={this.showAutoComplete("plane_div")}
                              onBlur={this.showAutoComplete("plane_div")}
                            />
                            <div id="plane_div" className="sr-only" style={{ boxShadow: '2px 2px 5px grey', width: '23.5rem' }}>
                              {this.state.planes
                                .filter((item) => { return item.name && item.name.includes(this.state.aeronave) })
                                .map((item, index) => {
                                  return <ListItem key={index} button
                                    onMouseDown={event => this.setState({ aeronave: item.name })}>
                                    <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                  </ListItem>
                                })}
                            </div>
                          </div>
                          <div>
                            <TextField
                              autoComplete="off"
                              label={res.CLIENTE}
                              value={this.state.cliente}
                              onChange={this.handleChange('cliente')}
                              disabled={this.state.state === 3}
                              fullWidth
                              onInput={(e) => {
                                e.target.value = e.target.value.toString().slice(0, 45)
                              }}
                              size="small"
                              margin="dense"
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  let item = this.state.planes.filter((item) => { return item.name.includes(this.state.plane_est) });
                                  if (item.length > 0) {
                                    this.setState({ cliente: item[0].name });
                                  }
                                }
                              }}
                              onFocus={this.showAutoComplete("client_div")}
                              onBlur={this.showAutoComplete("client_div")}
                            />
                            <div id="client_div" className="sr-only" style={{ boxShadow: '2px 2px 5px grey', width: '23.5rem' }}>
                              {this.state.clients
                                .filter((item) => { return item.name && item.name.includes(this.state.cliente) })
                                .map((item, index) => {
                                  return <ListItem key={index} button
                                    onMouseDown={event => this.setState({ cliente: item.name })}>
                                    <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                  </ListItem>
                                })}
                            </div>
                          </div>
                          <div>
                            <TextField
                              autoComplete="off"
                              label={res.PILOTO}
                              value={this.state.piloto}
                              onChange={this.handleChange('piloto')}
                              disabled={this.state.state === 3}
                              fullWidth
                              onInput={(e) => {
                                e.target.value = e.target.value.toString().slice(0, 45)
                              }}
                              size="small"
                              margin="dense"
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  let item = this.state.planes.filter((item) => { return item.name.includes(this.state.plane_est) });
                                  if (item.length > 0) {
                                    this.setState({ piloto: item[0].name });
                                  }
                                }
                              }}
                              onFocus={this.showAutoComplete("pilot_div")}
                              onBlur={this.showAutoComplete("pilot_div")}
                            />
                            <div id="pilot_div" className="sr-only" style={{ boxShadow: '2px 2px 5px grey', width: '23.5rem' }}>
                              {this.state.pilots
                                .filter((item) => { return item.name && item.name.includes(this.state.piloto) })
                                .map((item, index) => {
                                  return <ListItem key={index} button
                                    onMouseDown={event => this.setState({ piloto: item.name })}>
                                    <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                  </ListItem>
                                })}
                            </div>
                          </div>
                          <TextField
                            label={res.CULTURA}
                            value={this.state.cultura}
                            onChange={this.handleChange('cultura')}
                            size="small"
                            margin="dense"
                            disabled={this.state.state === 3}
                            fullWidth
                            onInput={(e) => {
                              e.target.value = e.target.value.toString().slice(0, 255)
                            }}
                          />
                        </div>
                      </Accordion>
                      <Accordion title={'Variáveis do Trabalho'}>
                        <div>
                          {/* faixa */}
                          <div style={{ display: 'flex' }}>
                            <TextField
                              label={res.LARGURA_FAIXA}
                              value={this.state.larguraSet}
                              onChange={this.handleChange('larguraSet')}
                              disabled={!this.state.checkConfig[0] || this.state.state === 3}
                              type="number"
                              className="mb-4 notTooClose"
                              size="small"
                              margin="dense"
                              style={{ width: '11rem' }}
                            />
                            <div style={{ paddingTop: '1rem' }}>
                              <Checkbox
                                checked={this.state.checkConfig[0]}
                                onChange={this.handleCheck(0)}
                                disabled={this.state.state === 3}
                              />
                              <span onClick={this.handleCheck(0)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                {res.MODIFICAR_LARGURA}
                              </span>
                            </div>
                          </div>
                          {/* altura */}
                          <div style={{ display: 'flex' }}>
                            <TextField
                              label={res.ALTURA_VOO}
                              value={this.state.alturaSet}
                              onChange={(event) => {
                                if (!isNaN(event.target.value) && Number(event.target.value) >= 0) {
                                  this.handleChange('alturaSet')(event);
                                  let alturaLimiteDown = this.state.alturaLimiteDown;
                                  let alturaMargemDown = this.state.alturaMargemDown;
                                  let alturaMargemUp = this.state.alturaMargemUp;
                                  let alturaLimiteUp = this.state.alturaLimiteUp;
                                  let value = Number(event.target.value);
                                  if (value < Number(this.state.alturaSet)) {
                                    if ((value - this.state.alturaMargemDown.value) < 0) {
                                      alturaMargemDown.value -= 1;
                                      if (alturaMargemDown.valid) alturaMargemDown.text = value - alturaMargemDown.value;
                                    } else if ((value - this.state.alturaMargemDown.value - this.state.alturaLimiteDown.value) < 0) {
                                      alturaLimiteDown.value -= 1;
                                      if (alturaLimiteDown.valid) alturaLimiteDown.text = value - alturaLimiteDown.value;
                                    }
                                  }
                                  alturaMargemUp.text = (value + alturaMargemUp.value);
                                  alturaLimiteUp.text = (value + alturaMargemUp.value + alturaLimiteUp.value);
                                  alturaMargemDown.text = (value - alturaMargemDown.value);
                                  alturaLimiteDown.text = (value - alturaMargemDown.value - alturaLimiteDown.value);
                                  this.setState({ alturaMargemDown: alturaMargemDown, alturaLimiteDown: alturaLimiteDown });
                                }
                              }}
                              disabled={!this.state.checkConfig[1] || this.state.state === 3}
                              type="number"
                              className="mb-4 notTooClose"
                              size="small"
                              margin="dense"
                              style={{ width: '11rem' }}
                            />
                            <div style={{ paddingTop: '1rem' }}>
                              <Checkbox
                                checked={this.state.checkConfig[1]}
                                onChange={this.handleCheck(1)}
                                disabled={this.state.state === 3}
                              />
                              <span onClick={this.handleCheck(1)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                {res.MODIFICAR_ALTURA}
                              </span>
                            </div>
                          </div>
                          {/* fluxo */}
                          <div style={{ display: 'flex' }}>
                            <TextField
                              label={res.FLUXO_APLICACAO}
                              value={this.state.fluxoSet}
                              onChange={(event) => {
                                if (!isNaN(event.target.value) && Number(event.target.value) >= 0) {
                                  this.handleChange('fluxoSet')(event);
                                  let fluxoLimiteDown = this.state.fluxoLimiteDown;
                                  let fluxoMargemDown = this.state.fluxoMargemDown;
                                  let fluxoMargemUp = this.state.fluxoMargemUp;
                                  let fluxoLimiteUp = this.state.fluxoLimiteUp;
                                  let value = Number(event.target.value);
                                  if (value < Number(this.state.fluxoSet)) {
                                    if ((value - this.state.fluxoMargemDown.value) < 0) {
                                      fluxoMargemDown.value -= 1;
                                      if (fluxoMargemDown.valid) fluxoMargemDown.text = value - fluxoMargemDown.value;
                                    } else if ((value - this.state.fluxoMargemDown.value - this.state.fluxoLimiteDown.value) < 0) {
                                      fluxoLimiteDown.value -= 1;
                                      if (fluxoLimiteDown.valid) fluxoLimiteDown.text = value - fluxoLimiteDown.value;
                                    }
                                  }
                                  fluxoMargemUp.text = (value + fluxoMargemUp.value);
                                  fluxoLimiteUp.text = (value + fluxoMargemUp.value + fluxoLimiteUp.value);
                                  fluxoMargemDown.text = (value - fluxoMargemDown.value);
                                  fluxoLimiteDown.text = (value - fluxoMargemDown.value - fluxoLimiteDown.value);
                                  this.setState({ fluxoMargemDown: fluxoMargemDown, fluxoLimiteDown: fluxoLimiteDown });
                                }
                              }}
                              disabled={!this.state.checkConfig[2] || this.state.state === 3}
                              type="number"
                              className="mb-4 notTooClose"
                              size="small"
                              margin="dense"
                              style={{ width: '11rem' }}
                            />
                            <div style={{ paddingTop: '1rem' }}>
                              <Checkbox
                                checked={this.state.checkConfig[2]}
                                onChange={this.handleCheck(2)}
                                disabled={this.state.state === 3}
                              />
                              <span onClick={this.handleCheck(2)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                {res.MODIFICAR_FLUXO}
                              </span>
                            </div>
                          </div>
                          {/* velocidade */}
                          <div style={{ display: 'flex' }}>
                            <TextField
                              label={res.VELOCIDADE_APLICACAO}
                              value={this.state.velocidadeSet}
                              onChange={(event) => {
                                if (!isNaN(event.target.value) && Number(event.target.value) >= 0) {
                                  this.handleChange('velocidadeSet')(event);
                                  let velocidadeLimiteDown = this.state.velocidadeLimiteDown;
                                  let velocidadeMargemDown = this.state.velocidadeMargemDown;
                                  let velocidadeMargemUp = this.state.velocidadeMargemUp;
                                  let velocidadeLimiteUp = this.state.velocidadeLimiteUp;
                                  let value = Number(event.target.value);
                                  if (value < Number(this.state.velocidadeSet)) {
                                    if ((value - this.state.velocidadeMargemDown.value) < 0) {
                                      velocidadeMargemDown.value -= 1;
                                      if (velocidadeMargemDown.valid) velocidadeMargemDown.text = value - velocidadeMargemDown.value;
                                    } else if ((value - this.state.velocidadeMargemDown.value - this.state.velocidadeLimiteDown.value) < 0) {
                                      velocidadeLimiteDown.value -= 1;
                                      if (velocidadeLimiteDown.valid) velocidadeLimiteDown.text = value - velocidadeLimiteDown.value;
                                    }
                                  }
                                  velocidadeMargemUp.text = (value + velocidadeMargemUp.value);
                                  velocidadeLimiteUp.text = (value + velocidadeMargemUp.value + velocidadeLimiteUp.value);
                                  velocidadeMargemDown.text = (value - velocidadeMargemDown.value);
                                  velocidadeLimiteDown.text = (value - velocidadeMargemDown.value - velocidadeLimiteDown.value);
                                  this.setState({ velocidadeMargemDown: velocidadeMargemDown, velocidadeLimiteDown: velocidadeLimiteDown });
                                }
                              }}
                              disabled={!this.state.checkConfig[3] || this.state.state === 3}
                              type="number"
                              className="mb-4 notTooClose"
                              size="small"
                              margin="dense"
                              style={{ width: '11rem' }}
                            />
                            <div style={{ paddingTop: '1rem' }}>
                              <Checkbox
                                checked={this.state.checkConfig[3]}
                                onChange={this.handleCheck(3)}
                                disabled={this.state.state === 3}
                              />
                              <span onClick={this.handleCheck(3)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                {res.MODIFICAR_VELOCIDADE}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion title={'Informações de Analise'}>
                        <div>
                          <div><h4 style={styleTitle}>{res.ALTURA.toUpperCase()}</h4></div>
                          <BarHeat values={[
                            Number(this.state.alturaSet) - this.state.alturaMargemDown.value - this.state.alturaLimiteDown.value,
                            Number(this.state.alturaSet) - this.state.alturaMargemDown.value,
                            Number(this.state.alturaSet),
                            Number(this.state.alturaSet) + this.state.alturaMargemUp.value,
                            Number(this.state.alturaSet) + this.state.alturaMargemUp.value + this.state.alturaLimiteUp.value
                          ]} />
                          <div>
                            <TextField
                              label={res.TOLERANCIA_ALTURA_MIN}
                              value={Math.round(this.state.alturaMargemDown.text * 1000) / 1000}
                              error={!this.state.alturaMargemDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let alturaMargemDown = this.state.alturaMargemDown;
                                alturaMargemDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  (Number(this.state.alturaSet) - Number(value)) >= 0) {
                                  alturaMargemDown.valid = true;
                                  alturaMargemDown.value = (Number(this.state.alturaSet) - Number(value));
                                  let alturaLimiteDown = this.state.alturaLimiteDown;
                                  if ((Number(this.state.alturaSet) - this.state.alturaMargemDown.value - this.state.alturaLimiteDown.value) < 0) {
                                    alturaLimiteDown.value -= 1;
                                    if (alturaLimiteDown.valid) alturaLimiteDown.text = value - alturaLimiteDown.value;
                                  }
                                  alturaLimiteDown.text = (Number(this.state.alturaSet) - alturaMargemDown.value - alturaLimiteDown.value);
                                  this.setState({ alturaMargemDown: alturaMargemDown, alturaLimiteDown: alturaLimiteDown });
                                } else {
                                  alturaMargemDown.valid = false;
                                  this.setState({ alturaMargemDown: alturaMargemDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.TOLERANCIA_ALTURA_MAX}
                              value={Math.round(this.state.alturaMargemUp.text * 1000) / 1000}
                              error={!this.state.alturaMargemUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let alturaMargemUp = this.state.alturaMargemUp;
                                alturaMargemUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value) - Number(this.state.alturaSet)) >= 0) {
                                  alturaMargemUp.value = (Number(value) - Number(this.state.alturaSet));
                                  alturaMargemUp.valid = true;
                                  let alturaLimiteUp = this.state.alturaLimiteUp;
                                  alturaLimiteUp.text = Number(this.state.alturaSet) + alturaMargemUp.value + alturaLimiteUp.value;
                                  this.setState({ alturaMargemUp: alturaMargemUp, alturaLimiteUp: alturaLimiteUp });
                                } else {
                                  alturaMargemUp.valid = false;
                                  this.setState({ alturaMargemUp: alturaMargemUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>
                          <div>
                            <TextField
                              label={res.LIMITE_ALTURA_MIN}
                              value={Math.round(this.state.alturaLimiteDown.text * 1000) / 1000}
                              error={!this.state.alturaLimiteDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let alturaLimiteDown = this.state.alturaLimiteDown;
                                alturaLimiteDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  Number(value) <= (Number(this.state.alturaSet) - Number(this.state.alturaMargemDown.value))) {
                                  alturaLimiteDown.value = (Number(this.state.alturaSet) - Number(this.state.alturaMargemDown.value) - Number(value))
                                  alturaLimiteDown.text = value;
                                  alturaLimiteDown.valid = true;
                                  this.setState({ alturaLimiteDown: alturaLimiteDown });
                                } else {
                                  alturaLimiteDown.valid = false;
                                  this.setState({ alturaLimiteDown: alturaLimiteDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.LIMITE_ALTURA_MAX}
                              value={Math.round(this.state.alturaLimiteUp.text * 1000) / 1000}
                              error={!this.state.alturaLimiteUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let alturaLimiteUp = this.state.alturaLimiteUp;
                                alturaLimiteUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value)) >= (Number(this.state.alturaMargemUp.value) + Number(this.state.alturaSet))) {
                                  alturaLimiteUp.value = (Number(value) - (Number(this.state.alturaSet) + Number(this.state.alturaMargemUp.value)));
                                  alturaLimiteUp.valid = true;
                                  this.setState({ alturaLimiteUp: alturaLimiteUp });
                                } else {
                                  alturaLimiteUp.valid = false;
                                  this.setState({ alturaLimiteUp: alturaLimiteUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>

                          <div><h4 style={styleTitle}>{res.FLUXO.toUpperCase()}</h4></div>
                          <BarHeat values={[
                            Number(this.state.fluxoSet) - this.state.fluxoMargemDown.value - this.state.fluxoLimiteDown.value,
                            Number(this.state.fluxoSet) - this.state.fluxoMargemDown.value,
                            Number(this.state.fluxoSet),
                            Number(this.state.fluxoSet) + this.state.fluxoMargemUp.value,
                            Number(this.state.fluxoSet) + this.state.fluxoMargemUp.value + this.state.fluxoLimiteUp.value
                          ]} />
                          <div>
                            <TextField
                              label={res.TOLERANCIA_FLUXO_MIN}
                              value={Math.round(this.state.fluxoMargemDown.text * 1000) / 1000}
                              error={!this.state.fluxoMargemDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let fluxoMargemDown = this.state.fluxoMargemDown;
                                fluxoMargemDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  (Number(this.state.fluxoSet) - Number(value)) >= 0) {
                                  fluxoMargemDown.valid = true;
                                  fluxoMargemDown.value = (Number(this.state.fluxoSet) - Number(value));
                                  let fluxoLimiteDown = this.state.fluxoLimiteDown;
                                  if ((Number(this.state.alturaSet) - this.state.fluxoMargemDown.value - this.state.fluxoLimiteDown.value) < 0) {
                                    fluxoLimiteDown.value -= 1;
                                    if (fluxoLimiteDown.valid) fluxoLimiteDown.text = value - fluxoLimiteDown.value;
                                  }
                                  fluxoLimiteDown.text = (Number(this.state.fluxoSet) - fluxoMargemDown.value - fluxoLimiteDown.value);
                                  this.setState({ fluxoMargemDown: fluxoMargemDown, fluxoLimiteDown: fluxoLimiteDown });
                                } else {
                                  fluxoMargemDown.valid = false;
                                  this.setState({ fluxoMargemDown: fluxoMargemDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.TOLERANCIA_FLUXO_MAX}
                              value={Math.round(this.state.fluxoMargemUp.text * 1000) / 1000}
                              error={!this.state.fluxoMargemUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let fluxoMargemUp = this.state.fluxoMargemUp;
                                fluxoMargemUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value) - Number(this.state.fluxoSet)) >= 0) {
                                  fluxoMargemUp.value = (Number(value) - Number(this.state.fluxoSet));
                                  fluxoMargemUp.valid = true;
                                  let fluxoLimiteUp = this.state.fluxoLimiteUp;
                                  fluxoLimiteUp.text = Number(this.state.fluxoSet) + fluxoMargemUp.value + fluxoLimiteUp.value;
                                  this.setState({ fluxoMargemUp: fluxoMargemUp, fluxoLimiteUp: fluxoLimiteUp });
                                } else {
                                  fluxoMargemUp.valid = false;
                                  this.setState({ fluxoMargemUp: fluxoMargemUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>
                          <div>
                            <TextField
                              label={res.LIMITE_FLUXO_MIN}
                              value={Math.round(this.state.fluxoLimiteDown.text * 1000) / 1000}
                              error={!this.state.fluxoLimiteDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let fluxoLimiteDown = this.state.fluxoLimiteDown;
                                fluxoLimiteDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  Number(value) <= (Number(this.state.fluxoSet) - Number(this.state.fluxoMargemDown.value))) {
                                  fluxoLimiteDown.value = (Number(this.state.fluxoSet) - Number(this.state.fluxoMargemDown.value) - Number(value))
                                  fluxoLimiteDown.text = value;
                                  fluxoLimiteDown.valid = true;
                                  this.setState({ fluxoLimiteDown: fluxoLimiteDown });
                                } else {
                                  fluxoLimiteDown.valid = false;
                                  this.setState({ fluxoLimiteDown: fluxoLimiteDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.LIMITE_FLUXO_MAX}
                              value={Math.round(this.state.fluxoLimiteUp.text * 1000) / 1000}
                              error={!this.state.fluxoLimiteUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let fluxoLimiteUp = this.state.fluxoLimiteUp;
                                fluxoLimiteUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value)) >= (Number(this.state.fluxoMargemUp.value) + Number(this.state.fluxoSet))) {
                                  fluxoLimiteUp.value = (Number(value) - (Number(this.state.fluxoSet) + Number(this.state.fluxoMargemUp.value)));
                                  fluxoLimiteUp.valid = true;
                                  this.setState({ fluxoLimiteUp: fluxoLimiteUp });
                                } else {
                                  fluxoLimiteUp.valid = false;
                                  this.setState({ fluxoLimiteUp: fluxoLimiteUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>

                          <div><h4 style={styleTitle}>{res.VELOCIDADE.toUpperCase()}</h4></div>
                          <BarHeat values={[
                            Number(this.state.velocidadeSet) - this.state.velocidadeMargemDown.value - this.state.velocidadeLimiteDown.value,
                            Number(this.state.velocidadeSet) - this.state.velocidadeMargemDown.value,
                            Number(this.state.velocidadeSet),
                            Number(this.state.velocidadeSet) + this.state.velocidadeMargemUp.value,
                            Number(this.state.velocidadeSet) + this.state.velocidadeMargemUp.value + this.state.velocidadeLimiteUp.value
                          ]} grayZero={true} />
                          <div>
                            <TextField
                              label={res.TOLERANCIA_VELOCIDADE_MIN}
                              value={Math.round(this.state.velocidadeMargemDown.text * 1000) / 1000}
                              error={!this.state.velocidadeMargemDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let velocidadeMargemDown = this.state.velocidadeMargemDown;
                                velocidadeMargemDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  (Number(this.state.velocidadeSet) - Number(value)) >= 0) {
                                  velocidadeMargemDown.valid = true;
                                  velocidadeMargemDown.value = (Number(this.state.velocidadeSet) - Number(value));
                                  let velocidadeLimiteDown = this.state.velocidadeLimiteDown;
                                  if ((Number(this.state.alturaSet) - this.state.velocidadeMargemDown.value - this.state.velocidadeLimiteDown.value) < 0) {
                                    velocidadeLimiteDown.value -= 1;
                                    if (velocidadeLimiteDown.valid) velocidadeLimiteDown.text = value - velocidadeLimiteDown.value;
                                  }
                                  velocidadeLimiteDown.text = (Number(this.state.velocidadeSet) - velocidadeMargemDown.value - velocidadeLimiteDown.value);
                                  this.setState({ velocidadeMargemDown: velocidadeMargemDown, velocidadeLimiteDown: velocidadeLimiteDown });
                                } else {
                                  velocidadeMargemDown.valid = false;
                                  this.setState({ velocidadeMargemDown: velocidadeMargemDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.TOLERANCIA_VELOCIDADE_MAX}
                              value={Math.round(this.state.velocidadeMargemUp.text * 1000) / 1000}
                              error={!this.state.velocidadeMargemUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let velocidadeMargemUp = this.state.velocidadeMargemUp;
                                velocidadeMargemUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value) - Number(this.state.velocidadeSet)) >= 0) {
                                  velocidadeMargemUp.value = (Number(value) - Number(this.state.velocidadeSet));
                                  velocidadeMargemUp.valid = true;
                                  let velocidadeLimiteUp = this.state.velocidadeLimiteUp;
                                  velocidadeLimiteUp.text = Number(this.state.velocidadeSet) + velocidadeMargemUp.value + velocidadeLimiteUp.value;
                                  this.setState({ velocidadeMargemUp: velocidadeMargemUp, velocidadeLimiteUp: velocidadeLimiteUp });
                                } else {
                                  velocidadeMargemUp.valid = false;
                                  this.setState({ velocidadeMargemUp: velocidadeMargemUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>
                          <div>
                            <TextField
                              label={res.LIMITE_VELOCIDADE_MIN}
                              value={Math.round(this.state.velocidadeLimiteDown.text * 1000) / 1000}
                              error={!this.state.velocidadeLimiteDown.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let velocidadeLimiteDown = this.state.velocidadeLimiteDown;
                                velocidadeLimiteDown.text = value;
                                if (!isNaN(value) &&
                                  Number(value) >= 0 &&
                                  Number(value) <= (Number(this.state.velocidadeSet) - Number(this.state.velocidadeMargemDown.value))) {
                                  velocidadeLimiteDown.value = (Number(this.state.velocidadeSet) - Number(this.state.velocidadeMargemDown.value) - Number(value))
                                  velocidadeLimiteDown.text = value;
                                  velocidadeLimiteDown.valid = true;
                                  this.setState({ velocidadeLimiteDown: velocidadeLimiteDown });
                                } else {
                                  velocidadeLimiteDown.valid = false;
                                  this.setState({ velocidadeLimiteDown: velocidadeLimiteDown });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                              style={{ marginRight: '.5rem' }}
                            />
                            <TextField
                              label={res.LIMITE_VELOCIDADE_MAX}
                              value={Math.round(this.state.velocidadeLimiteUp.text * 1000) / 1000}
                              error={!this.state.velocidadeLimiteUp.valid}
                              onChange={(event) => {
                                let value = event.target.value;
                                let velocidadeLimiteUp = this.state.velocidadeLimiteUp;
                                velocidadeLimiteUp.text = value;
                                if (!isNaN(value) &&
                                  (Number(value)) >= (Number(this.state.velocidadeMargemUp.value) + Number(this.state.velocidadeSet))) {
                                  velocidadeLimiteUp.value = (Number(value) - (Number(this.state.velocidadeSet) + Number(this.state.velocidadeMargemUp.value)));
                                  velocidadeLimiteUp.valid = true;
                                  this.setState({ velocidadeLimiteUp: velocidadeLimiteUp });
                                } else {
                                  velocidadeLimiteUp.valid = false;
                                  this.setState({ velocidadeLimiteUp: velocidadeLimiteUp });
                                }
                              }}
                              size="small"
                              margin="dense"
                              disabled={this.state.state === 3}
                              type="number"
                            />
                          </div>

                          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                            <Checkbox
                              disabled={this.state.tabMenuItens[0].active || (this.state.fonstes.length === 0 && (this.state.limitWeather - this.state.limitConsumption) <= 0)}
                              checked={this.state.checkConfig[4]}
                              onChange={this.handleCheck(4)}
                            />
                            <div style={{ display: 'inline-flex' }}>
                              <span onClick={(event) => { if (!this.state.tabMenuItens[0].active) this.handleCheck(4)(event); }} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                <h3 style={styleTitle}>{'Usar Dados Meteorológicos?'}</h3>
                              </span>
                            </div>
                          </div>
                          <div style={{ position: 'relative' }}>
                            <div style={{ display: (this.state.checkConfig[4] ? 'none' : 'block'), position: 'absolute', width: '100%', height: '100%', backgroundColor: '#ffffff87', zIndex: '1' }}></div>
                            <div><h4 style={styleTitle}>{'fonte de dados'.toUpperCase()}</h4></div>
                            <FormControl
                              style={{ padding: ".4rem 0 .4rem 0", alignItems: 'center' }}
                              fullWidth
                              component="fieldset">
                              <RadioGroup style={{ scale: '.9' }} row id="tipoFonte" aria-label="tipoFonte" name="tipoFonte" value={this.state.tipoFonte} onChange={this.handleChange('tipoFonte')}>
                                <FormControlLabel value="ESTACAO" disabled={(this.state.tabMenuItens[0].active && this.state.fonstes.length === 0 && this.state.tipoFonte !== 'ESTACAO')} control={<Radio color="primary" />} label="Estações" />
                                <FormControlLabel value="EXTERNO" disabled={(this.state.tabMenuItens[0].active && this.state.tipoFonte !== 'EXTERNO')} control={<Radio color="primary" />} label={this.state.showLimit ? `Externo (${this.state.limitConsumption}/${this.state.limitWeather})` : 'Externo'} />
                              </RadioGroup>
                            </FormControl>
                            <Select
                              value={this.state.fonteClima}
                              disabled={this.state.tabMenuItens[0].active || this.state.fonstes.length === 0}
                              style={{ width: '100%', marginBottom: '.5rem' }}
                              onChange={this.handleChange('fonteClima')}
                              inputProps={{
                                id: 'fonteClimaPup',
                              }}
                            >
                              <MenuItem key={0} value={0}>{'Mais Próximo'}</MenuItem> :
                              {this.state.fonstes.map((item, key) => {
                                return <MenuItem key={key} value={item.id}>
                                  {/* <Checkbox checked={this.state.fonteClima.indexOf(item.id) > -1} /> */}
                                  {item.name}
                                </MenuItem>;
                              })}
                            </Select>

                            <div><h4 style={styleTitle}>{res.VELOCIDADE_VENTO.toUpperCase()}</h4></div>
                            <BarHeat values={[
                              Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value - this.state.velocidadeVLimiteDown.value,
                              Number(this.state.velocidadeVSet) - this.state.velocidadeVMargemDown.value,
                              null,//Number(this.state.velocidadeVSet),
                              Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value,
                              Number(this.state.velocidadeVSet) + this.state.velocidadeVMargemUp.value + this.state.velocidadeVLimiteUp.value
                            ]} grayZero={true} />
                            <div>
                              <TextField
                                label={res.TOLERANCIA_VELOCIDADE_VENTO_MIN}
                                value={Math.round(this.state.velocidadeVMargemDown.text * 1000) / 1000}
                                error={!this.state.velocidadeVMargemDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemDown(
                                    event.target.value,
                                    'velocidadeVSet',
                                    'velocidadeVMargemDown',
                                    'velocidadeVLimiteDown',
                                    'velocidadeVMargemUp',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.TOLERANCIA_VELOCIDADE_MAX}
                                value={Math.round(this.state.velocidadeVMargemUp.text * 1000) / 1000}
                                error={!this.state.velocidadeVMargemUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemUp(
                                    event.target.value,
                                    'velocidadeVSet',
                                    'velocidadeVMargemUp',
                                    'velocidadeVLimiteUp',
                                    null,
                                    'velocidadeVMargemDown'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>
                            <div>
                              <TextField
                                label={res.LIMITE_VELOCIDADE_MIN}
                                value={Math.round(this.state.velocidadeVLimiteDown.text * 1000) / 1000}
                                error={!this.state.velocidadeVLimiteDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitDown(
                                    event.target.value,
                                    'velocidadeVSet',
                                    'velocidadeVMargemDown',
                                    'velocidadeVLimiteDown'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.LIMITE_VELOCIDADE_MAX}
                                value={Math.round(this.state.velocidadeVLimiteUp.text * 1000) / 1000}
                                error={!this.state.velocidadeVLimiteUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitUp(
                                    event.target.value,
                                    'velocidadeVSet',
                                    'velocidadeVMargemUp',
                                    'velocidadeVLimiteUp'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>

                            <div><h4 style={styleTitle}>{res.ANGULO_DESVIO_VENTO_UNI.toUpperCase()}</h4></div>
                            <PlaneHeat type="P" ckey="0" values={[
                              Number(this.state.anguloVLimiteDown.value),
                              Number(this.state.anguloVMargemDown.value),
                              Number(this.state.anguloVSet),
                              this.state.espelharAnguloV ? Number(this.state.anguloVMargemDown.value) : Number(this.state.anguloVMargemUp.value),
                              this.state.espelharAnguloV ? Number(this.state.anguloVLimiteDown.value) : Number(this.state.anguloVLimiteUp.value)
                            ]} limit="90" />
                            <div style={{ position: 'relative' }}>
                              <div style={{ position: 'absolute', right: 0, top: '-2rem' }}>
                                <span onClick={(ev) => { if (!this.state.tabMenuItens[0].active) this.setState({ espelharAnguloV: !this.state.espelharAnguloV }) }} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                  espelhar?
                                </span>
                                <Checkbox
                                  disabled={this.state.tabMenuItens[0].active}
                                  checked={this.state.espelharAnguloV}
                                  onChange={(ev) => { this.setState({ espelharAnguloV: !this.state.espelharAnguloV }) }}
                                />
                              </div>
                              <TextField
                                label={res.TOLERANCIA_ANGULO_VENTO_MIN}
                                value={Math.round(this.state.anguloVMargemDown.text * 1000) / 1000}
                                error={!this.state.anguloVMargemDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemUp(
                                    event.target.value,
                                    'anguloVSet',
                                    'anguloVMargemDown',
                                    'anguloVLimiteDown',
                                    90
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.TOLERANCIA_ANGULO_VENTO_MAX}
                                value={this.state.espelharAnguloV ?
                                  Math.round(this.state.anguloVMargemDown.text * 1000) / 1000 :
                                  Math.round(this.state.anguloVMargemUp.text * 1000) / 1000}
                                error={!this.state.anguloVMargemUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemUp(
                                    event.target.value,
                                    'anguloVSet',
                                    'anguloVMargemUp',
                                    'anguloVLimiteUp',
                                    90
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3 || this.state.espelharAnguloV}
                                type="number"
                              />
                            </div>
                            <div>
                              <TextField
                                label={res.LIMITE_ANGULO_VENTO_MIN}
                                value={Math.round(this.state.anguloVLimiteDown.text * 1000) / 1000}
                                error={!this.state.anguloVLimiteDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitUp(
                                    event.target.value,
                                    'anguloVSet',
                                    'anguloVMargemDown',
                                    'anguloVLimiteDown',
                                    90
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.LIMITE_ANGULO_VENTO_MAX}
                                value={this.state.espelharAnguloV ?
                                  Math.round(this.state.anguloVLimiteDown.text * 1000) / 1000 :
                                  Math.round(this.state.anguloVLimiteUp.text * 1000) / 1000}
                                error={!this.state.anguloVLimiteUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitUp(
                                    event.target.value,
                                    'anguloVSet',
                                    'anguloVMargemUp',
                                    'anguloVLimiteUp',
                                    90
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3 || this.state.espelharAnguloV}
                                type="number"
                              />
                            </div>

                            <div><h4 style={styleTitle}>{res.DELTA_T.toUpperCase()}</h4></div>
                            <div style={{ position: 'relative' }}>
                              <div style={{ position: 'absolute', right: 0, top: '-1.6rem' }}>
                                <span onClick={(ev) => { if (!this.state.tabMenuItens[0].active) this.setState({ showDeltat: !this.state.showDeltat }) }} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                                  exibir?
                                </span>
                                <Checkbox
                                  disabled={this.state.tabMenuItens[0].active}
                                  checked={this.state.showDeltat}
                                  onChange={(ev) => { this.setState({ showDeltat: !this.state.showDeltat }) }}
                                />
                              </div>
                            </div>
                            {this.state.showDeltat && <div>
                              <DeltaTHeat values={this.state} temperatura_values={[
                                Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value - this.state.temperaturaLimiteDown.value,
                                Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value,
                                null,//Number(this.state.temperaturaSet),
                                Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value,
                                Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value + this.state.temperaturaLimiteUp.value
                              ]} humidade_values={[
                                Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value - this.state.UmidadeLimiteDown.value,
                                Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value,
                                null,//Number(this.state.UmidadeSet),     temperatura='30' humidade='75' atual='1.8'
                                Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value,
                                Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value + this.state.UmidadeLimiteUp.value
                              ]} />
                            </div>}
                            <div><h4 style={styleTitle}>{res.TEMPERATURA_UNI.toUpperCase()}</h4></div>
                            {!this.state.showDeltat && <BarHeat values={[
                              Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value - this.state.temperaturaLimiteDown.value,
                              Number(this.state.temperaturaSet) - this.state.temperaturaMargemDown.value,
                              null,//Number(this.state.temperaturaSet),
                              Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value,
                              Number(this.state.temperaturaSet) + this.state.temperaturaMargemUp.value + this.state.temperaturaLimiteUp.value
                            ]} />}
                            <div>
                              <TextField
                                label={res.TOLERANCIA_TEMPERATURA_MIN}
                                value={Math.round(this.state.temperaturaMargemDown.text * 1000) / 1000}
                                error={!this.state.temperaturaMargemDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemDown(
                                    event.target.value,
                                    'temperaturaSet',
                                    'temperaturaMargemDown',
                                    'temperaturaLimiteDown',
                                    'temperaturaMargemUp',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.TOLERANCIA_TEMPERATURA_MAX}
                                value={Math.round(this.state.temperaturaMargemUp.text * 1000) / 1000}
                                error={!this.state.temperaturaMargemUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemUp(
                                    event.target.value,
                                    'temperaturaSet',
                                    'temperaturaMargemUp',
                                    'temperaturaLimiteUp',
                                    null,
                                    'temperaturaMargemDown',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>
                            <div>
                              <TextField
                                label={res.LIMITE_TEMPERATURA_MIN}
                                value={Math.round(this.state.temperaturaLimiteDown.text * 1000) / 1000}
                                error={!this.state.temperaturaLimiteDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitDown(
                                    event.target.value,
                                    'temperaturaSet',
                                    'temperaturaMargemDown',
                                    'temperaturaLimiteDown',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.LIMITE_TEMPERATURA_MAX}
                                value={Math.round(this.state.temperaturaLimiteUp.text * 1000) / 1000}
                                error={!this.state.temperaturaLimiteUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitUp(
                                    event.target.value,
                                    'temperaturaSet',
                                    'temperaturaMargemUp',
                                    'temperaturaLimiteUp'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>

                            <div><h4 style={styleTitle}>{res.HUMIDADE_UNI.toUpperCase()}</h4></div>
                            {!this.state.showDeltat && <BarHeat values={[
                              Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value - this.state.UmidadeLimiteDown.value,
                              Number(this.state.UmidadeSet) - this.state.UmidadeMargemDown.value,
                              null,//Number(this.state.UmidadeSet),
                              Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value,
                              Number(this.state.UmidadeSet) + this.state.UmidadeMargemUp.value + this.state.UmidadeLimiteUp.value
                            ]} />}
                            <div>
                              <TextField
                                label={res.TOLERANCIA_HUMIDADE_MIN}
                                value={Math.round(this.state.UmidadeMargemDown.text * 1000) / 1000}
                                error={!this.state.UmidadeMargemDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemDown(
                                    event.target.value,
                                    'UmidadeSet',
                                    'UmidadeMargemDown',
                                    'UmidadeLimiteDown',
                                    'UmidadeMargemUp',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.TOLERANCIA_HUMIDADE_MAX}
                                value={Math.round(this.state.UmidadeMargemUp.text * 1000) / 1000}
                                error={!this.state.UmidadeMargemUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsMargemUp(
                                    event.target.value,
                                    'UmidadeSet',
                                    'UmidadeMargemUp',
                                    'UmidadeLimiteUp',
                                    null,
                                    'UmidadeMargemDown',
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>
                            <div>
                              <TextField
                                label={res.LIMITE_HUMIDADE_MIN}
                                value={Math.round(this.state.UmidadeLimiteDown.text * 1000) / 1000}
                                error={!this.state.UmidadeLimiteDown.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitDown(
                                    event.target.value,
                                    'UmidadeSet',
                                    'UmidadeMargemDown',
                                    'UmidadeLimiteDown'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                                style={{ marginRight: '.5rem' }}
                              />
                              <TextField
                                label={res.LIMITE_HUMIDADE_MAX}
                                value={Math.round(this.state.UmidadeLimiteUp.text * 1000) / 1000}
                                error={!this.state.UmidadeLimiteUp.valid}
                                onChange={(event) => {
                                  this.changeSettingsLimitUp(
                                    event.target.value,
                                    'UmidadeSet',
                                    'UmidadeMargemUp',
                                    'UmidadeLimiteUp'
                                  );
                                }}
                                size="small"
                                margin="dense"
                                disabled={this.state.state === 3}
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <div style={{ marginTop: '.5rem', display: 'grid', gridTemplateColumns: '5rem auto 3rem 3rem', gap: '.2rem' }}>
                        <div></div>
                        <h3 style={{ marginTop: '.6rem', width: 'auto', height: '2.3rem', textAlign: 'center' }}>{res.AREAS.toUpperCase()}</h3>
                        <Button component="span" className="buttonS buttonMenu" title={res.LIMPAR} disabled={this.state.state === 3} onClick={event => this.showMapItem('analise')}>
                          <MaterialIcon icon="select_all" className="iconMap" />
                        </Button>
                        <Button component="span" className="buttonS buttonMenu" title={'Reset'} disabled={this.state.state === 3 || this.state.polygons.length === 0 || this.state.polygons === this.polygonsDefault} onClick={() => { this.onCloseEditMap(this.polygonsDefault) }}>
                          <MaterialIcon icon="close" className="iconMap" />
                        </Button>
                      </div>
                      <div id="divAreas">
                        {this.state.polygons.map((item, key) => {
                          return <div key={key} style={{ width: '100%', height: '2rem', textAlign: 'center' }}>{item.name}</div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divContent divRelative map-p">
              <div className="containerTop">
                <div className="leftTop">
                  <div style={{ marginTop: '-1rem', height: '2.9rem', display: 'flex' }}>
                    <Tab value={this.state.tab} handleChange={this.handleChangeTab} name="tab" itens={this.state.tabItens} />
                    {this.state.tab === '5' || this.state.tab === '4' ?
                      <div style={{ width: '0.1rem', height: 'calc(100% - 1rem)', backgroundColor: '#0000001f', margin: '1rem 1rem 0.5rem 1rem' }}></div>
                      : null}
                    {this.state.tab === '4' ?
                      <Tab value={this.state.tabSubMenuV} handleChange={this.handleChangeTab} name="tabSubMenuV" itens={this.state.tabItensVento} />
                      : this.state.tab === '5' ?
                        <Tab value={this.state.tabSubMenuD} handleChange={this.handleChangeTab} name="tabSubMenuD" itens={this.state.tabItensDeltaT} />
                        : null}
                  </div>
                </div>
                <div className="centerTop"></div>
                <div className="rightTop">
                  <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.regua()} title={res.REGUA}>
                    <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
                  </Button>
                  <Button className="buttonS buttonMenu" disabled={this.state.state !== 3 || !this.state.id_job} onClick={event => this.showMapItem('retoque')} title={'Retoque'}>
                    <SvgIcon viewBox="79 18 21 26" className="iconMapP" style={this.state.state !== 3 || !this.state.id_job ? { color: '#bdbdbd' } : {}}>
                      <path id="polygon" d="M 95 19 L 88 19 L 88 26 L 95 26 Z M 85 28 L 78 28 L 78 35 L 85 35 Z M 85 19 L 78 19 L 78 26 L 85 26 Z" />
                    </SvgIcon>
                  </Button>
                  <Button className={this.state.showStack ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.state !== 3} onClick={event => this.setState({ showStack: !this.state.showStack })} title={res.EXIBIR_ESCONDER}>
                    <MaterialIcon icon="layers" className="iconMap" />
                  </Button>
                </div>
              </div>
              <div className="wrapperMap">
                <div id="mapid"></div>
                <div style={{ position: 'absolute', top: 0, zIndex: 401, width: '100%', textAlign: 'center', padding: '1rem', display: this.state.tabMenu === '1' ? 'block' : 'none' }}>
                  <Button className={!this.state.play ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                    disabled={this.state.data === null}
                    onClick={event => this.play(false)}>
                    <MaterialIcon icon="pause" className="iconMap" />
                  </Button>&nbsp;
                  <Button className={(this.state.play && this.state.sTime === 1) ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                    disabled={this.state.data === null}
                    onClick={event => { this.setState({ sTime: 1 }); this.play(true); }}>
                    <MaterialIcon icon={this.state.end ? "replay" : "play_arrow"} className="iconMap" />
                  </Button>&nbsp;
                  <Button className={(this.state.play && this.state.sTime === 10) ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                    disabled={this.state.data === null || !this.state.play}
                    onClick={event => this.setState({ sTime: 10 })}>
                    <MaterialIcon icon="fast_forward" className="iconMap" />
                  </Button>&nbsp;
                  <Button className={(this.state.play && this.state.sTime === 50) ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                    disabled={this.state.data === null || !this.state.play}
                    onClick={event => this.setState({ sTime: 50 })}>
                    <SvgIcon viewBox="2 0 28 24" className="iconArrow">
                      <path d="M 4 18 l 8.5 -6 l -8.5 -6 v 12 z m 9 -12 v 12 l 8.5 -6 l -8.5 -6 Z m 9 0 v 12 l 8.5 -6 l -8.5 -6 Z" />
                    </SvgIcon>
                  </Button>&nbsp;
                  <Button className={(this.state.play && this.state.fastProgress) ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                    disabled={this.state.data === null || !this.state.play}
                    onClick={event => this.setState({ fastProgress: !this.state.fastProgress })}>
                    <SvgIcon viewBox="2 0 28 24" className="iconArrow">
                      {/*<path d="M 4 18 l 8.5 -6 l -8.5 -6 v 12 z m 9 -12 v 12 l 8.5 -6 l -8.5 -6 Z m 9 0 v 12 l 8.5 -6 l -8.5 -6 Z" />*/}
                      <path d="M 13 13 L 13 11 L 4 11 V 13 Z M 13 7 V 17 L 21.5 12 Z M 22 6 V 18 L 24 18 L 24 6 Z" />
                    </SvgIcon>
                  </Button>
                </div>
                <div className="zoomB">
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                    <MaterialIcon icon="add" className="iconMap" />
                  </Button>
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                    <MaterialIcon icon="remove" className="iconMap" />
                  </Button>
                </div>
                <div className={this.state.edit || this.state.delete ? "divContent floatDiv floatDivItens" : "sr-only"}>
                  <label className="textDiv">
                    {res.CLICK_DESFAZER}
                  </label>
                  <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.saveEdit() : this.saveDelete()}>
                    {res.SALVAR}
                  </Button>
                  <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.cancelEdit() : this.cancelDelete()}>
                    {res.CANCELAR}
                  </Button>
                </div>
                {this.state.goto &&
                  <div className="divContent floatW divS" style={{ width: '15rem' }}>
                    <div>
                      <span style={{ marginRight: '2rem' }}>{res.IRPARA}</span>
                      <Button size="small" className="popUpButton blButton" onClick={event => this.goto("poly")}>
                        <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                          <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                        </SvgIcon>
                      </Button>
                      <Button size="small" className="popUpButton blButton" onClick={event => this.goto("home")}>
                        <MaterialIcon icon="home" className="iconMap" />
                      </Button>
                    </div>
                    <TextField
                      id="lat"
                      label="LAT"
                      margin="normal"
                      size='small'
                      onChange={this.handleChange('lat')}
                      value={this.state.lat}
                      onKeyDown={e => {
                        if (e.keyCode === 13) { this.goto() }
                      }}
                      onInput={(e) => {
                        e.target.value = isNaN(e.target.value) ? this.state.lat : e.target.value.toString().slice(0, 20).trim()
                      }}
                    />
                    <TextField
                      id="lng"
                      label="LNG"
                      margin="normal"
                      onChange={this.handleChange('lng')}
                      value={this.state.lng}
                      onKeyDown={e => {
                        if (e.keyCode === 13) { this.goto() }
                      }}
                      onInput={(e) => {
                        e.target.value = isNaN(e.target.value) ? this.state.lng : e.target.value.toString().slice(0, 20).trim()
                      }}
                    />
                    <div className="divForm">
                      <Button size="small" className="popUpButton" onClick={event => this.goto()}>
                        {res.IR}
                      </Button>
                      <Button size="small" className="popUpButton" onClick={event => this.setState({ goto: false })}>
                        {res.CANCELAR}
                      </Button>
                    </div>
                  </div>}
                {this.state.showStack &&
                  <div className="divContent floatW divS">
                    <List
                      component="nav"
                      aria-labelledby="stack"
                    >
                      {this.state.listTypes
                        .map((item, key) => (
                          <MenuItem className="popup-item" key={key}>
                            <FormControlLabel
                              style={{ width: '100%' }}
                              control={<Checkbox checked={item.active} onChange={() => { this.setStack(item) }} />}
                              label={item.label}
                            />
                          </MenuItem>
                        ))}
                    </List>
                  </div>}

                <div className="divContent floatW divI" style={{
                  bottom: '0.5rem',
                  top: 'auto',
                  width: 'calc(100% - 2rem)',
                  height: '9rem',
                  left: '9rem',
                  padding: 'revert',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  paddingTop: '2rem',
                  display: this.state.showChart ? 'block' : 'none'
                }}>
                  <div style={{ position: 'absolute', top: 0, right: '50%', fontWeight: 'bold' }}><span>{this.state.barTitle} </span></div>
                  <div style={{ position: 'absolute', top: 0, right: '1rem' }}><Button style={{ height: '.5rem' }} color="primary" onClick={() => { this.barChart.resetZoom() }}>reset</Button></div>
                  <div style={{ width: '100%', height: '100%', display: (this.state.tab === '4' && this.state.tabSubMenuV === '1') ? 'flex' : 'solid' }}>
                    {(this.state.tab === '4' && this.state.tabSubMenuV === '1') ?
                      <div style={{
                        width: '7rem', height: '7rem', position: 'absolute',
                        margin: '-1rem 0px 0px 1rem',
                        transform: `rotate(${this.state.angulo_wind}deg)`,
                        transformOrigin: '50% 50%',
                        transition: 'all 0.5s ease 0s'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '7rem',
                          height: '7rem',
                          backgroundImage: 'linear-gradient(#6daaee, #6daaee73, #0000ff00)',
                          borderRadius: '3.5rem',
                        }}>
                          <div style={{
                            position: 'absolute',
                            top: '-.3rem',
                            left: '3rem',
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: '#6daaee',
                            transform: 'rotate(45deg)'
                          }}></div>
                          <div style={{
                            width: '6rem',
                            height: '6rem',
                            backgroundColor: 'white',
                            borderRadius: '3rem',
                          }}></div>
                        </div>
                      </div> : null}
                    {(this.state.tab === '4' && this.state.tabSubMenuV === '1') ?
                      <div style={{
                        height: '10.7rem',
                        width: '10.5rem',
                        scale: '.7',
                        marginTop: '-3.2em',
                        marginLeft: '-0.75rem',
                        transform: `rotate(${this.state.angulo_plane}deg)`,
                        transformOrigin: '50% 50%',
                        transition: 'all 0.5s ease 0s'
                      }}>
                        <PlaneHeat type="G" ckey="1" values={[
                          Number(this.state.anguloVLimiteDown.value),
                          Number(this.state.anguloVMargemDown.value),
                          Number(this.state.anguloVSet),
                          this.state.espelharAnguloV ? Number(this.state.anguloVMargemDown.value) : Number(this.state.anguloVMargemUp.value),
                          this.state.espelharAnguloV ? Number(this.state.anguloVLimiteDown.value) : Number(this.state.anguloVLimiteUp.value)
                        ]} limit="90" />
                      </div> : null}
                    <div style={{ width: (this.state.tab === '4' && this.state.tabSubMenuV === '1') ? 'calc(100% - 11rem)' : 'auto', height: 'calc(100% + 2rem)', marginTop: '-1rem' }}><canvas id="barChart"></canvas></div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    );
  };
}


const TextFields1 = Edit;

const Box = (props) => {
  const { link, res } = props;
  let { guid } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} guid={guid} res={res} />
    </div>)
}
export default Box;

