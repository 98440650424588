import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Checkbox from '@material-ui/core/Checkbox';

import AreaList from './Area_list';
import AreaEdit from './Area_edit';
import Form from './Form';
import Prod from './Prod';
import ListFilter from '../../components/ListFilter/filter';

import jszip from 'jszip';
import togeojson from '@mapbox/togeojson';
import shpjs from 'shpjs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import CloseIcon from '@material-ui/icons/Close';

import * as turf from '@turf/turf';
import tools from '../../components/util/tools';
import geo from '../../components/util/geo';

import saveAs from 'file-saver';

import SignaturePad from 'react-signature-pad';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {

  constructor(props) {
    super(props);

    this.getDateTime = (date) => {
      if (!date) date = new Date();
      let ret = date.getFullYear() + '-';
      ret += (date.getMonth() + 1).toString().padStart(2, '0') + '-';
      ret += date.getDate().toString().padStart(2, '0') + 'T';
      ret += date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
      return ret;
    };

    this.state = {
      isRel: false,
      id: null,
      guid: this.props.guid,
      id_job: null,
      title: '',
      clients: [],
      client: '',
      idClient: '',
      properties: [],
      property: '',
      idProperty: '',
      responsible: '',
      contact: '',
      dateTimeTrabalho: this.getDateTime(),
      turno: {
        manha: false,
        tarde: false,
      },
      cultura: '',
      typeApp: {
        liquid: false,
        solid: false,
      },
      typeService: {
        insecticide: false,
        herbicide: false,
        seeding: false,
        fungicide: false,
        fertilizing: false,
        others: false,
      },
      servicoOutro: '',
      sizeArea: '',
      fluxo: '',
      larguraFaixa: '',
      tipoBico: '',
      espGota: '',
      qualidadeAgua: '',
      areaRisk: {
        app: false,
        bee: false,
        others: false,
      },
      areaRistOutro: '',
      obs: '',
      import: {
        show: false,
        update: null,
        itens: [],
        selected: []
      },
      edit: {
        show: false,
        update: null,
        item: null
      },
      list_areas: [],
      list_points: [],
      list_areas_r: '',
      list_points_r: '',
      list_prod: [],
      list_form: [],
      list_all_prod: [],
      list_all_form: [],
      technicians: [],
      executor: '',
      executor_cfta_cpf: '',
      executorEmail: '',
      isSigning: false,
      imageURL: null,
      pilots: [],
      pilot: '',
      pilotCanac: '',
      pilotEmail: '',
      planes: [],
      plane: '',
      equipment: {
        type: '',
        name: '',
        details: {
          core: '',
          disco: '',
          angulo: '',
          ponta: '',
          anguloPa: '',
          malha: '',
          vru: '',
          orificio: '',
        }
      },
    };

    this.signaturePadRef = React.createRef();
    this.type_import = '';
  }
  componentDidMount() {
    if (this.state.guid) {
      this.getJobPO(this.state.guid);
    }
    this.getProd();
    this.getForm();
    this.readTechnician();
    this.readCustomers();
    this.readPilots();
    this.readPlanes();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  handleChange = (name) => (event) => {
    if (!event.target) {
      return;
    }
    const { value } = event.target;

    if (name === 'equipment') {
      this.setState(prevState => ({
        equipment: {
          ...prevState.equipment,
          type: value,
        }
      }));
    } else if (name === 'name') {
      this.setState(prevState => ({
        equipment: {
          ...prevState.equipment,
          name: value,
        }
      }));
    } else {
      this.setState({ [name]: value, isRel: false });
    }
  };

  getJobPO = (guid) => {
    const apiUrl = Config.server + '/job_po/' + guid;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let item = result[0];
            let turno = item.shift ? JSON.parse(item.shift) : [];
            let typeApp = item.spray_type ? JSON.parse(item.spray_type) : [];
            let typeService = item.service_type ? JSON.parse(item.service_type) : [];
            let areaRisk = item.obstacles ? JSON.parse(item.obstacles) : [];

            this.setState({
              isRel: true,
              id: item.id,
              id_job: item.id_job,
              title: item.title ? item.title : '',
              client: item.client ? item.client : '',
              property: item.property ? item.property : '',
              responsible: item.responsible ? item.responsible : '',
              contact: item.contact ? item.contact : '',
              dateTimeTrabalho: item.start ? this.getDateTime(new Date(item.start)) : item.job_date ? this.getDateTime(new Date(item.job_date)) : this.getDateTime(new Date()),
              turno: {
                manha: turno.indexOf("manha") >= 0,
                tarde: turno.indexOf("tarde") >= 0,
              },
              cultura: item.crop ? item.crop : '',
              typeApp: {
                liquid: typeApp.indexOf("liquid") >= 0,
                solid: typeApp.indexOf("solid") >= 0,
              },
              typeService: {
                insecticide: typeService.indexOf("insecticide") >= 0,
                herbicide: typeService.indexOf("herbicide") >= 0,
                seeding: typeService.indexOf("seeding") >= 0,
                fungicide: typeService.indexOf("fungicide") >= 0,
                fertilizing: typeService.indexOf("fertilizing") >= 0,
                others: !!item.service_type_other,
              },
              servicoOutro: item.service_type_other,
              sizeArea: item.area_ha,
              fluxo: item.flow_rate,
              larguraFaixa: item.swath_width,
              tipoBico: item.nozzle,
              espGota: item.drop_diameter,
              qualidadeAgua: item.water_quality,
              areaRisk: {
                app: areaRisk.indexOf("app") >= 0,
                bee: areaRisk.indexOf("bee") >= 0,
                others: !!item.obstacle_other,
              },
              areaRistOutro: item.obstacle_other,
              obs: item.note,
              executor: item.executor,
              executor_cfta_cpf: item.executor_cfta_cpf,
              executorEmail: item.executorEmail,
              imageURL: item.executorSignature,
              pilot: item.pilot,
              pilotCanac: item.pilotCanac,
              pilotEmail: item.pilotEmail,
              plane: item.plane,
              equipment: JSON.parse(item.equipment),
            });

            this.getJobProdForm('job_po_product', 'list_prod', item.id);
            this.getJobProdForm('job_po_form', 'list_form', item.id);
            this.getJobArea(item.id);
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  getJobProdForm = (api, state, id_job_po) => {
    const apiUrl = Config.server + '/' + api + '/' + id_job_po;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (Array.isArray(result)) {
            this.setState({
              [state]: result,
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  getProd = () => {
    const apiUrl = Config.server + '/product';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (Array.isArray(result)) {
            this.setState({
              list_all_prod: result.map((item, key) => { return { ...item, index: key } }),
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  getForm = () => {
    const apiUrl = Config.server + '/form';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (Array.isArray(result)) {
            this.setState({
              list_all_form: result.map((item, key) => { return { ...item, index: key, name: item.cod + ' - ' + item.description } }),
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  getJobArea = (id_job_po) => {
    const apiUrl = Config.server + '/job_po_area/' + id_job_po;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (Array.isArray(result)) {
            let temp_points = [];
            let temp_areas = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];
              element.guid = tools.newGuid();
              if (element.type === 'point') {
                delete element['key'];
                temp_points.push(element);
              } else {
                temp_areas.push(element);
              }
            }
            this.setState({
              list_points: temp_points,
              list_areas: temp_areas,
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/job_po/';

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += this.state.id;
      formData.append('id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    if (this.state.executor) formData.append('executor', this.state.executor);
    if (this.state.executor_cfta_cpf) formData.append('executor_cfta_cpf', this.state.executor_cfta_cpf);
    if (this.state.executorEmail) formData.append('executorEmail', this.state.executorEmail);
    if (this.state.imageURL) formData.append('executorSignature', this.state.imageURL);
    if (this.state.pilot) formData.append('pilot', this.state.pilot);
    if (this.state.pilotCanac) formData.append('pilotCanac', this.state.pilotCanac);
    if (this.state.pilotEmail) formData.append('pilotEmail', this.state.pilotEmail);
    if (this.state.plane) formData.append('plane', this.state.plane);
    if (this.state.equipment) formData.append('equipment', JSON.stringify(this.state.equipment));

    if (this.state.id_job) formData.append('id_job', this.state.id_job);
    this.state.title && formData.append('title', this.state.title);
    this.state.client && formData.append('client', this.state.client);
    this.state.property && formData.append('property', this.state.property);
    this.state.responsible && formData.append('responsible', this.state.responsible);
    this.state.contact && formData.append('contact', this.state.contact);
    formData.append('timetable', JSON.stringify([{ start: new Date(this.state.dateTimeTrabalho).getTime() }]));

    let turno = [];
    if (this.state.turno.manha) turno.push('manha');
    if (this.state.turno.tarde) turno.push('tarde');
    formData.append('shift', JSON.stringify(turno));

    this.state.cultura && formData.append('crop', this.state.cultura);

    let typeApp = [];
    if (this.state.typeApp.liquid) typeApp.push('liquid');
    if (this.state.typeApp.solid) typeApp.push('solid');
    formData.append('spray_type', JSON.stringify(typeApp));

    let typeService = [];
    if (this.state.typeService.insecticide) typeService.push('insecticide');
    if (this.state.typeService.herbicide) typeService.push('herbicide');
    if (this.state.typeService.seeding) typeService.push('seeding');
    if (this.state.typeService.fungicide) typeService.push('fungicide');
    if (this.state.typeService.fertilizing) typeService.push('fertilizing');
    formData.append('service_type', JSON.stringify(typeService));
    if (this.state.typeService.others) formData.append('service_type_other', this.state.servicoOutro);

    this.state.sizeArea && formData.append('area_ha', this.state.sizeArea);
    this.state.fluxo && formData.append('flow_rate', this.state.fluxo);
    this.state.larguraFaixa && formData.append('swath_width', this.state.larguraFaixa);
    this.state.tipoBico && formData.append('nozzle', this.state.tipoBico);
    this.state.espGota && formData.append('drop_diameter', this.state.espGota);
    this.state.qualidadeAgua && formData.append('water_quality', this.state.qualidadeAgua);

    let areaRisk = [];
    if (this.state.areaRisk.app) areaRisk.push('app');
    if (this.state.areaRisk.bee) areaRisk.push('bee');
    formData.append('obstacles', JSON.stringify(areaRisk));
    if (this.state.areaRisk.others) formData.append('obstacle_other', this.state.areaRistOutro);

    this.state.obs && formData.append('note', this.state.obs);


    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {

          this.setState({ isRel: true });
          let run = () => {
            if (this.state.list_prod.length > 0) {
              let prod = this.state.list_prod.map((item) => { return { id_job_po: this.state.id, id_product: item.id } });
              this.submitProdForm('job_po_product', prod);
            }
            if (this.state.list_form.length > 0) {
              let form = this.state.list_form.map((item) => { return { id_job_po: this.state.id, id_formulation: item.id } });
              this.submitProdForm('job_po_form', form);
            }
            if (this.state.list_areas.length > 0) {
              let areas = this.state.list_areas;
              for (let i = 0; i < areas.length; i++) {
                const element = areas[i];
                this.submitAreaPoint(element);
              }
              if (this.state['list_areas_r']) {
                this.removeAreaPoints(this.state['list_areas_r']);
              }
            }
            if (this.state.list_points.length > 0) {
              let points = this.state.list_points;
              for (let i = 0; i < points.length; i++) {
                const element = points[i];
                this.submitAreaPoint(element);
              }
              if (this.state['list_points_r']) {
                this.removeAreaPoints(this.state['list_points_r']);
              }
            }
          };

          let msg = this.props.res.ITEM_INCLUIR;
          if (this.state.id) {
            msg = this.props.res.ITEM_ALTERADO;
          }
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
          if (result.guid && !this.state.guid) {
            window.history.replaceState(null, "Travicar", "/app/preoperacao/" + result.guid);
          }
          if (!this.state.id) {
            let it = { id: result.id };
            it.guid = result.guid;
            this.setState(it, run);
          } else {
            run();
          }

        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  submitProdForm = (type, list) => {
    let apiUrl = Config.server + '/' + type + '/';
    let method = 'POST';

    const formData = new URLSearchParams();

    formData.append('id_job_po', this.state.id);
    formData.append('list', JSON.stringify(list));

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result && result.message) {
            notification.open({
              message: result.message,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  submitAreaPoint = (item) => {
    let apiUrl = Config.server + '/job_po_area/';
    let method = '';

    const formData = new URLSearchParams();

    if (item.id) {
      apiUrl += item.id;
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('id_job_po', this.state.id);
    formData.append('name', item.name);
    formData.append('lat', item.lat);
    formData.append('lng', item.lng);
    if (item.points_place) {
      formData.append('points_place', item.points_place);
    } else {
      formData.append('img_place', item.img_place);
    }
    formData.append('type', item.type);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          item.id = result;
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  removeAreaPoints = (ids) => {
    let apiUrl = Config.server + '/job_po_area';
    let method = 'delete';

    const formData = new URLSearchParams();

    formData.append('id_job_po', this.state.id);
    formData.append('ids', ids);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {

        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  downloadRelatorio = () => {
    this.setState({ progress: true });

    let apiUrl = Config.server + '/reljobpo';
    let method = 'POST';

    const formData = new URLSearchParams();

    formData.append('culture', this.props.res.LINGUA);
    formData.append('id', this.state.id);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.blob();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          let file_name = 'APL' + Date.now() + '.pdf';
          if (result.type === 'application/pdf' && result.size > 0) {
            saveAs(result, file_name);
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  handleCheck = (type, name) => (event) => {
    let state = this.state[type];
    state[name] = !this.state[type][name];
    this.setState({ [type]: state, isRel: false });
  };

  addList = (list, list_all) => (event) => {
    let temp_import = this.state.import;
    temp_import.show = true;
    temp_import.itens = this.state[list_all];
    temp_import.selected = this.state[list];
    temp_import.update = (itens) => {
      let temp_import = this.state.import;
      temp_import.show = false;
      let temp_itens = [];

      for (let i = 0; i < itens.length; i++) {
        temp_itens.push(itens[i]);
      }
      this.setState({ import: temp_import, [list]: temp_itens });
    }
    this.setState({ import: temp_import, isRel: false });
  };

  loadFile = file => {
    if (file.target.files.length > 0) this.showPro(true);
    for (let index = 0; index < file.target.files.length; index++) {
      const filePath = file.target.files[index];
      setTimeout(() => {
        let ext = fileName => fileName.split(".").pop();
        if (ext(filePath.name.toLowerCase()) === 'kml') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const content = this.fileReader.result;
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(content, "text/xml");
            let track = new togeojson['kml'](xmlDoc);

            this.writeFileMap(track);
          });
          this.fileReader.readAsText(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'kmz') {
          let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
          let getKmlDom = (kmzFile) => {
            let zip = new jszip()
            return zip.loadAsync(kmzFile)
              .then(zip => {
                let kmlDom = null
                zip.forEach((relPath, file) => {
                  if (ext(relPath) === 'kml') {
                    kmlDom = file.async("string").then(getDom)
                  }
                })
                return kmlDom || Promise.reject("No kml file found")
              });
          }
          let geoJson = getKmlDom(filePath).then(kmlDom => {
            let geoJsonObject = new togeojson['kml'](kmlDom);
            return geoJsonObject
          })
          geoJson.then(gj => this.writeFileMap(gj))
        } else if (ext(filePath.name.toLowerCase()) === 'zip') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            shpjs(this.fileReader.result)
              .then((geojson) => {
                if (geojson.length > 0) {
                  geojson.forEach(element => {
                    this.writeFileMap(element);
                  });
                } else {
                  this.writeFileMap(geojson);
                }
              })
              .catch(e => {
                this.showPro(false);
                notification.open({
                  message: this.props.res.ERRO,
                  description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'shp') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const features = [];
            let polyLength = 0;
            shpjs.parseShp(this.fileReader.result).forEach(element => {
              const geometry = { type: element.type, coordinates: element.coordinates };
              features.push({ properties: { guid: undefined, name: undefined, type: element.type }, geometry: geometry });
              if (element.type === "Polygon") {
                polyLength++;
              } else if (element.type === "MultiPolygon") {
                polyLength += element.coordinates.length;
              }
            });
            this.writeFileMap({ features: features, polyLength: polyLength });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else {
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          this.showPro(false);
        }
      }, 50);
    };
    //
  };

  writeFileMap = (track) => {
    this.itens_import = track.features;
    let list = [];
    for (let i = 0; i < track.features.length; i++) {
      let feature = track.features[i];
      if (this.type_import === 'Points') {
        if (feature.geometry.type === 'Point') {
          let name = feature.properties.name ? feature.properties.name : feature.properties.recibo ? feature.properties.recibo : this.nextIndex(this.state.list_area);
          list.push({
            name: name,
            feature: feature,
            index: i
          });
        }
      } else {
        if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'GeometryCollection' || feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'LineString') {
          let name = feature.properties.name ? feature.properties.name : feature.properties.recibo ? feature.properties.recibo : this.nextIndex(this.state.list_area);
          list.push({
            name: name,
            feature: feature,
            index: i
          });
        }
      }
    }

    let temp_import = this.state.import;
    temp_import.show = true;
    temp_import.itens = list;
    temp_import.selected = [];
    temp_import.update = (itens) => {
      let temp_import = this.state.import;
      temp_import.show = false;
      let temp_itens = [];
      for (let i = 0; i < itens.length; i++) {
        itens[i].guid = tools.newGuid();
        temp_itens.push(itens[i]);
      }
      let list = this.fileMap(temp_itens);
      this.setState({
        import: temp_import,
        [this.type_import === 'Points' ? 'list_points' : 'list_areas']: list.concat(this.state[this.type_import === 'Points' ? 'list_points' : 'list_areas'])
      });
      this.showPro(false);
    }
    this.setState({ import: temp_import });
  };

  geoInvertArray = (poly) => {
    let array = [];
    for (let i = 0; i < poly.length; i++) {
      array.push([poly[i][1], poly[i][0]]);
    }
    return array;
  };

  nextIndex = (listP) => {
    let lastI = 1;
    let retorno = this.text_polygon + lastI;
    for (let i = 0; i < listP.length; i++) {
      if (listP[i].name === retorno) {
        i = -1;
        lastI++;
      }
      retorno = this.text_polygon + lastI;
    }
    return retorno;
  };

  fileMap = (tFeatures) => {
    let list_area = [];
    for (let i = 0; i < tFeatures.length; i++) {
      //const element = turfS(track.features[i], {tolerance: 0.00005, highQuality: true});
      let features = tFeatures[i].feature;
      if (features.geometry.type === 'Polygon') {
        //polys = this.loadPolyFile(features.properties, features.geometry.coordinates[0], polys);
        let c = turf.center(features);
        list_area.push({
          name: tFeatures[i].name,
          lng: c ? geo.toDMS(c.geometry.coordinates[1], 'latitude', 4) : '',
          lat: c ? geo.toDMS(c.geometry.coordinates[0], 'longitude', 4) : '',
          points_place: JSON.stringify(this.geoInvertArray(features.geometry.coordinates[0])),
          guid: tools.newGuid(),
          type: 'area'
        });
      } else if (features.geometry.type === 'MultiPolygon') {
        for (let j = 0; j < features.geometry.coordinates.length; j++) {
          features.properties.type = 'Polygon';
          //polys = this.loadPolyFile(features.properties, features.geometry.coordinates[j][0], polys);
          let c = turf.center(turf.polygon(features.geometry.coordinates[j][0]));
          list_area.push({
            name: tFeatures[i].name,
            lng: c ? geo.toDMS(c.geometry.coordinates[1], 'latitude', 4) : '',
            lat: c ? geo.toDMS(c.geometry.coordinates[0], 'longitude', 4) : '',
            points_place: JSON.stringify(this.geoInvertArray(features.geometry.coordinates[j][0])),
            guid: tools.newGuid(),
            type: 'area'
          });
        }
      } else if (features.geometry.type === 'GeometryCollection') {
        let geometries = features.geometry.geometries;
        for (let j = 0; j < geometries.length; j++) {
          let element = geometries[j];
          if (element.type === 'Polygon') {
            //polys = this.loadPolyFile(features.properties, element.coordinates[0], polys);
            let c = turf.center(turf.polygon(element.coordinates[0]));
            list_area.push({
              name: tFeatures[i].name,
              lng: c ? geo.toDMS(c.geometry.coordinates[1], 'latitude', 4) : '',
              lat: c ? geo.toDMS(c.geometry.coordinates[0], 'longitude', 4) : '',
              points_place: JSON.stringify(this.geoInvertArray(element.coordinates[0])),
              guid: tools.newGuid(),
              type: 'area'
            });
          } else if (element.type === 'LineString') {
            //polys = this.loadPolyFile(features.properties, element.coordinates, polys);
            let c = turf.center(turf.polygon(element.coordinates));
            list_area.push({
              name: tFeatures[i].name,
              lng: c ? geo.toDMS(c.geometry.coordinates[1], 'latitude', 4) : '',
              lat: c ? geo.toDMS(c.geometry.coordinates[0], 'longitude', 4) : '',
              points_place: JSON.stringify(this.geoInvertArray(element.coordinates)),
              guid: tools.newGuid(),
              type: 'area'
            });
          }
        }
      } else if (features.geometry.type === 'LineString') {
        const arr1 = features.geometry.coordinates[0];
        const arr2 = features.geometry.coordinates[features.geometry.coordinates.length - 1];
        if (arr1[0] === arr2[0] && arr1[1] === arr2[1]) {
          //polys = this.loadPolyFile(features.properties, features.geometry.coordinates, polys);
          let c = turf.center(turf.polygon(features.geometry.coordinates));
          list_area.push({
            name: tFeatures[i].name,
            lng: c ? geo.toDMS(c.geometry.coordinates[1], 'latitude', 4) : '',
            lat: c ? geo.toDMS(c.geometry.coordinates[0], 'longitude', 4) : '',
            points_place: JSON.stringify(this.geoInvertArray(features.geometry.coordinates)),
            guid: tools.newGuid(),
            type: 'area'
          });
        }
      } else if (features.geometry.type === 'Point') {
        list_area.push({
          name: tFeatures[i].name,
          lng: geo.toDMS(features.geometry.coordinates[1], 'latitude', 4),
          lat: geo.toDMS(features.geometry.coordinates[0], 'longitude', 4),
          points_place: JSON.stringify(this.geoInvertArray([features.geometry.coordinates])),
          guid: tools.newGuid(),
          type: 'point'
        });
      }
    }
    return list_area;
  };

  showEditArea = (type) => (guid) => {
    let edit = this.state.edit;
    edit.show = true;
    edit.update = type;
    if (guid) edit.item = this.state[type].filter((item) => (item.guid === guid))[0];

    this.setState({ edit: edit });
  }

  onCloseEditArea = () => {
    let edit = this.state.edit;
    edit.show = false;
    edit.item = null;
    this.setState({ edit: edit });

  }

  onCloseFilterArea = () => {
    let imp = this.state.import;
    imp.show = false;
    this.setState({ import: imp });
    this.showPro(false);
  }

  showPro = (b) => {
    let dF = document.getElementById('divPro');
    dF.classList.remove("sr-only");
    if (!b) {
      dF.classList.toggle("sr-only");
    }
  }

  updateArea = (from) => (item, type) => {
    let temp_list;
    //tipo de dados (area ou point)
    item.type = 'area';
    if (from === 'list_points') {
      item.type = 'point';
    }
    //tipo de atualizacao (create ou update)
    if (type === 'update') {
      temp_list = this.state[from].filter(obj => obj.guid === item.guid);
      temp_list[0] = item;
    } else {
      temp_list = this.state[from];
      item.guid = tools.newGuid();
      temp_list.push(item);
    }
    this.onCloseEditArea();
    this.setState({ [from]: temp_list, isRel: false });
  }

  updateAreas = (listId, itens, removed) => {
    let update = { [listId]: itens, isRel: false };
    if (removed) {
      update[listId + '_r'] = this.state[listId + '_r'] ? `${removed},${this.state[listId + '_r']}` : removed;
    }
    this.setState(update);
  }

  readTechnician = () => {
    const apiUrl = `${Config.server}/technician`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          const firstTechnician = result[0];
          this.setState({
            technicians: result,
            id_technician: firstTechnician.id,
            executor: firstTechnician.name,
            executor_cfta_cpf: firstTechnician.cfta,
            executorEmail: firstTechnician.email,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handleTechnicianChange = (event) => {
    const selectedTechnician = this.state.technicians.find(technician => technician.name === event.target.value);
    this.setState({
      executor: selectedTechnician.name,
      executor_cfta_cpf: selectedTechnician.crea,
      executorEmail: selectedTechnician.email,
    });
  };

  startSigning = () => {
    this.setState({ isSigning: true });
  };

  isSignatureValid = () => {
    const signaturePad = this.signaturePadRef.current;
    if (signaturePad) {
      return signaturePad.isEmpty();
    }
    return true;
  };

  saveSignature = () => {
    const signaturePad = this.signaturePadRef.current;
    if (signaturePad && !signaturePad.isEmpty()) {
      const imageURL = signaturePad.toDataURL();
      this.setState({ imageURL, isSigning: false });
    } else {
      notification.open({
        message: this.props.res.ATENCAO,
        description: 'Por favor, desenhe a assinatura antes de salvar.',
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
    }
  };


  clearSignature = () => {
    const signaturePad = this.signaturePadRef.current;
    signaturePad.clear();
  };

  handleCloseDialog = () => {
    this.setState({ isSigning: false });
  };

  readPilots = () => {
    const apiUrl = `${Config.server}/pilot`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            pilots: result,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handlePilotChange = (event) => {
    const selectedPilot = this.state.pilots.find(pilot => pilot.name === event.target.value);
    this.setState({
      pilot: selectedPilot.name,
      pilotCanac: selectedPilot.canac,
      pilotEmail: selectedPilot.email,
    });
  };

  readPlanes = () => {
    const apiUrl = `${Config.server}/plane`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            planes: result,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  readCustomers = () => {
    const apiUrl = `${Config.server}/customer`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            clients: result,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handleClientChange = (event) => {
    const selectedCustomers = this.state.clients.find(client => client.name === event.target.value);
    this.setState({
      client: selectedCustomers.name,
      idClient: selectedCustomers.id,
    });
    this.readProperties(selectedCustomers.id)
  };

  readProperties = (idClient) => {
    const apiUrl = Config.server + '/properties/' + idClient;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            properties: result,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handlePropertyChange = (event) => {
    const selectedProperties = this.state.properties.find(property => property.name === event.target.value);
    this.setState({
      property: selectedProperties.name,
      idProperty: selectedProperties.id,
    });
  };

  handleChangeDetails = (name) => (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      equipment: {
        ...prevState.equipment,
        details: {
          ...prevState.equipment.details,
          [name]: value
        }
      }
    }));
  };

  renderEquipmentDetailsInput = () => {
    if (!this.state.equipment || !this.state.equipment.type) {
      return null;
    }

    switch (this.state.equipment.type) {
      case 'bicoHidraulicoCoreDisco':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="core"
              label="Core"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.core : ''}
              onChange={this.handleChangeDetails('core')}
            />
            <TextField
              id="disco"
              label="Disco"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.disco : ''}
              onChange={this.handleChangeDetails('disco')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'bicoHidraulicoCeramica':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'atomizadorTela':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="anguloPa"
              label="Ângulo Pá"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.anguloPa : ''}
              onChange={this.handleChangeDetails('anguloPa')}
            />
            <TextField
              id="malha"
              label="Malha"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.malha : ''}
              onChange={this.handleChangeDetails('malha')}
            />
            <TextField
              id="vru"
              label="VRU"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.vru : ''}
              onChange={this.handleChangeDetails('vru')}
            />
          </div>
        );
      case 'atomizadorDisco':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="anguloPa"
              label="Ângulo Pá"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.anguloPa : ''}
              onChange={this.handleChangeDetails('anguloPa')}
            />
            <TextField
              id="vru"
              label="VRU"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.vru : ''}
              onChange={this.handleChangeDetails('vru')}
            />
          </div>
        );
      case 'bicoHidraulicoSolido':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'bicoHidraulicoPlano':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'cp03tt03':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="orificio"
              label="Orifício"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.orificio : ''}
              onChange={this.handleChangeDetails('orificio')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'cp11tt11':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'cp09tt09':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="orificio"
              label="Orifício"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.orificio : ''}
              onChange={this.handleChangeDetails('orificio')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      case 'outro':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
            />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { link, res } = this.props;

    const textRowRadio = { display: 'inline-block', height: '2rem' };
    const innerDiv = { position: 'absolute', marginTop: '-0.3rem' };
    const div3 = { width: '12rem', marginRight: '1rem' };

    return (
      <div style={{ display: 'relative', position: 'relative' }}>
        <div id="divPro" className="sr-only back-drop" style={{ backgroundColor: '#fff', zIndex: 599 }}>
          <div className="in-back-drop" style={{ position: 'fixed' }}>
            <CircularProgress color="inherit" disableShrink />
          </div>
        </div>
        <ListFilter res={res} onClose={this.onCloseFilterArea} itens={this.state.import.itens} open={this.state.import.show} index={1003} listFilter={this.state.import.update} selected={this.state.import.selected} />
        <Dialog onClose={this.onCloseEditArea} aria-labelledby="simple-dialog-title" open={this.state.edit.show}>
          <AreaEdit res={res} onClose={this.onCloseEditArea} type={this.type_import} item={this.state.edit.item} update={this.updateArea(this.state.edit.update)} />
        </Dialog>
        <form onSubmit={this.handleSubmit}>
          <div className="containerTop">
            <div><h1>{res.PREOPERACAO}</h1></div>
            <div className="centerTop"></div>
            <div>
              {/* <Button variant="contained" onClick={evemt => { this.teste() link('/app/cadastro/agronomo/list') }} className="popUpButton">
                {res.CANCELAR}
              </Button> &nbsp; */}
              <Button type="submit" variant="contained" color="primary" className="popUpButton">
                <SaveIcon className="iconMapS" />
                {res.SALVAR}
              </Button> &nbsp;
              <Button variant="contained" color="primary" disabled={!this.state.isRel} className="popUpButton" onClick={this.downloadRelatorio}>
                {res.RELATORIO}
              </Button>
            </div>
          </div>
          <div className="form-group" style={{ textAlign: 'left' }}>
            <div className=' notTooBig'>
              <TextField
                id="title"
                label={res.TITULO}
                value={this.state.title}
                onChange={this.handleChange('title')}
                margin="normal"
                fullWidth
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
            </div>
            <div className=' notTooBig'>
              <FormControl style={{ marginTop: '1rem', width: '100%' }}>
                <InputLabel>{res.CLIENTE}</InputLabel>
                <Select
                  id="client"
                  value={this.state.client}
                  onChange={this.handleClientChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {this.state.clients.map((client) => (
                    <MenuItem key={client.id} value={client.name}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!this.state.idClient ?
                <TextField
                  id="property"
                  label={res.PROPRIEDADE}
                  value={this.state.property}
                  onChange={this.handleChange('property')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
                :
                <FormControl style={{ marginTop: '1rem', width: '100%' }}>
                  <InputLabel>{res.PROPRIEDADE}</InputLabel>
                  <Select
                    id="property"
                    value={this.state.property}
                    onChange={this.handlePropertyChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {this.state.properties.map((property) => (
                      <MenuItem key={property.id} value={property.name}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0 2rem' }}>
              <div className="notTooBig">
                <TextField
                  id="responsible"
                  label={res.RESPONSAVEL}
                  value={this.state.responsible}
                  onChange={this.handleChange('responsible')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </div>
              <div className="notTooBig">
                <TextField
                  id="contact"
                  label={res.CONTATO}
                  value={this.state.contact}
                  onChange={this.handleChange('contact')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}>
                <InputLabel>{res.PILOTO}</InputLabel>
                <Select
                  id="pilot"
                  value={this.state.pilot}
                  onChange={this.handlePilotChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {this.state.pilots.map((pilot) => (
                    <MenuItem key={pilot.id} value={pilot.name}>
                      {pilot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="pilotCanac"
                label={res.CANAC}
                style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
                value={this.state.pilotCanac}
                onChange={this.handleChange('pilotCanac')}
              />
              <TextField
                id="pilotEmail"
                label={res.EMAIL}
                style={{ marginTop: '1rem', width: '50%' }}
                value={this.state.pilotEmail ? this.state.pilotEmail : ''}
                onChange={this.handleChange('pilotEmail')}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <FormControl style={{ marginTop: '1rem', width: '100%' }}>
                <InputLabel>{res.AERONAVE}</InputLabel>
                <Select
                  id="plane"
                  value={this.state.plane}
                  onChange={this.handleChange('plane')}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {this.state.planes.map((plane) => (
                    <MenuItem key={plane.id} value={plane.name}>
                      {plane.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="containerTop" style={{ marginTop: '1rem' }}>
              <div><h2>{res.INTO_PRE_ORCAMENTO}</h2></div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '28rem auto' }}>
              <div>
                <TextField
                  id="datetime-local1"
                  label={res.DATA_DO_TRABALHO}
                  type="datetime-local"
                  value={this.state.dateTimeTrabalho}
                  onChange={this.handleChange('dateTimeTrabalho')}
                  style={{ width: '11.7rem', marginBottom: '1rem' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div style={{ paddingTop: '1rem' }}>
                <span className="MuiFormLabel-root" style={{ fontSize: '16px' }}>
                  {res.TURNO}
                </span>
                <Checkbox
                  checked={this.state.turno.manha}
                  onClick={this.handleCheck('turno', 'manha')}
                />
                <span onClick={this.handleCheck('turno', 'manha')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.MANHA}
                </span>
                <Checkbox
                  checked={this.state.turno.tarde}
                  onClick={this.handleCheck('turno', 'tarde')}
                />
                <span onClick={this.handleCheck('turno', 'tarde')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.TARDE}
                </span>
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '28rem auto' }}>
              <div style={{ width: '25rem' }}>
                <TextField
                  id="cultura"
                  label={res.CULTURA}
                  value={this.state.cultura}
                  onChange={this.handleChange('cultura')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </div>
              <div style={{ paddingTop: '1rem' }}>
                <span className="MuiFormLabel-root" style={{ fontSize: '16px' }}>
                  {res.TIPO_APLICACAO}
                </span>
                <Checkbox
                  checked={this.state.typeApp.liquid}
                  onClick={this.handleCheck('typeApp', 'liquid')}
                />
                <span onClick={this.handleCheck('typeApp', 'liquid')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.PRODUTO_LIQUIDO}
                </span>
                <Checkbox
                  checked={this.state.typeApp.solid}
                  onClick={this.handleCheck('typeApp', 'solid')}
                />
                <span onClick={this.handleCheck('typeApp', 'solid')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.PRODUTO_SOLIDO}
                </span>
              </div>
            </div>
            <div style={{ paddingTop: '1rem' }}>
              <span className="MuiFormLabel-root" style={{ fontSize: '16px' }}>
                {res.TIPO_SERVICO}
              </span>
              <Checkbox
                checked={this.state.typeService.insecticide}
                onClick={this.handleCheck('typeService', 'insecticide')}
              />
              <span onClick={this.handleCheck('typeService', 'insecticide')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.INSETICIDA}
              </span>
              <Checkbox
                checked={this.state.typeService.herbicide}
                onClick={this.handleCheck('typeService', 'herbicide')}
              />
              <span onClick={this.handleCheck('typeService', 'herbicide')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.HERBICIDA}
              </span>
              <Checkbox
                checked={this.state.typeService.fungicide}
                onClick={this.handleCheck('typeService', 'fungicide')}
              />
              <span onClick={this.handleCheck('typeService', 'fungicide')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.FUNGICIDA}
              </span>
              <Checkbox
                checked={this.state.typeService.seeding}
                onClick={this.handleCheck('typeService', 'seeding')}
              />
              <span onClick={this.handleCheck('typeService', 'seeding')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.SEMEADURA}
              </span>
              <Checkbox
                checked={this.state.typeService.fertilizing}
                onClick={this.handleCheck('typeService', 'fertilizing')}
              />
              <span onClick={this.handleCheck('typeService', 'fertilizing')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.ADUBACAO}
              </span>
              <Checkbox
                checked={this.state.typeService.others}
                onClick={this.handleCheck('typeService', 'others')}
              />
              <span onClick={this.handleCheck('typeService', 'others')} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                {res.OUTROSTXT}
              </span>
              <div style={{ ...textRowRadio, marginLeft: '0.5rem' }}>
                <div style={innerDiv}>
                  <TextField
                    id="servicoOutro"
                    disabled={!this.state.typeService.others}
                    value={this.state.typeService.others ? this.state.servicoOutro : ''}
                    onChange={this.handleChange('servicoOutro')}
                    margin="normal"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=' notTooBig'>
              <TextField
                id="sizeArea"
                label={res.TAMANHO_AREA}
                value={this.state.sizeArea}
                onChange={this.handleChange('sizeArea')}
                type="number"
                margin="normal"
                style={div3}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="fluxo"
                label={res.FLUXO_APLICACAO}
                value={this.state.fluxo}
                onChange={this.handleChange('fluxo')}
                type="number"
                margin="normal"
                style={div3}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="larguraFaixa"
                label={res.LARGURA_FAIXA}
                value={this.state.larguraFaixa}
                onChange={this.handleChange('larguraFaixa')}
                type="number"
                margin="normal"
                style={div3}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />

            </div>
            <div className=' notTooBig'>
              <TextField
                id="tipoBico"
                label={res.TIPO_BICO}
                value={this.state.tipoBico}
                onChange={this.handleChange('tipoBico')}
                margin="normal"
                fullWidth
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0 2rem' }}>
              <div className="notTooBig">
                <TextField
                  id="espGota"
                  label={res.ESPECTRO_GOTA}
                  value={this.state.espGota}
                  onChange={this.handleChange('espGota')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </div>
              <div className="notTooBig">
                <TextField
                  id="qualidadeAgua"
                  label={res.QUALIDADE_AGUA}
                  value={this.state.qualidadeAgua}
                  onChange={this.handleChange('qualidadeAgua')}
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </div>
            </div>
            <div>
              <FormControl style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', width: '100%' }}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>{res.EQUIPAMENTO}:</FormLabel>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.equipment ? this.state.equipment.type : ''} onChange={this.handleChange('equipment')}>
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoCoreDisco" control={<Radio color="primary" />} label="Bico Hidráulico Core e Disco" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoCeramica" control={<Radio color="primary" />} label="Bico Hidráulico Cerâmica" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoSolido" control={<Radio color="primary" />} label="Bico Hidráulico Jato Sólido" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoPlano" control={<Radio color="primary" />} label="Bico Hidráulico Jato Plano" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp03tt03" control={<Radio color="primary" />} label="CP03/TT03" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp09tt09" control={<Radio color="primary" />} label="CP09/TT09" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp11tt11" control={<Radio color="primary" />} label="CP11/TT11" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="atomizadorTela" control={<Radio color="primary" />} label="Atomizador Rotativo Tela" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="atomizadorDisco" control={<Radio color="primary" />} label="Atomizador Rotativo Disco" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="swathmaster" control={<Radio color="primary" />} label="Swathmaster" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="outro" control={<Radio color="primary" />} label="Outro" />
                </RadioGroup>
              </FormControl>
              {this.renderEquipmentDetailsInput()}
            </div>
            <div>
              <div style={{ paddingTop: '1rem' }}>
                <span className="MuiFormLabel-root" style={{ fontSize: '16px' }}>
                  {res.AREA_RISCO}
                </span>
                <Checkbox
                  checked={this.state.areaRisk.app}
                  onClick={this.handleCheck('areaRisk', 'app', true)}
                />
                <span onClick={this.handleCheck('areaRisk', 'app', true)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.APP}
                </span>
                <Checkbox
                  checked={this.state.areaRisk.bee}
                  onClick={this.handleCheck('areaRisk', 'bee', true)}
                />
                <span onClick={this.handleCheck('areaRisk', 'bee', true)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.ABELHAS}
                </span>
                <Checkbox
                  checked={this.state.areaRisk.others}
                  onClick={this.handleCheck('areaRisk', 'others', true)}
                />
                <span onClick={this.handleCheck('areaRisk', 'others', true)} className="MuiFormLabel-root" style={{ fontSize: '12px', cursor: 'pointer' }}>
                  {res.OUTROSTXT}
                </span>
                <div style={{ ...textRowRadio, marginLeft: '0.5rem' }}>
                  <div style={innerDiv}>
                    <TextField
                      id="areaRistOutro"
                      disabled={!this.state.areaRisk.others}
                      value={this.state.areaRisk.others ? this.state.areaRistOutro : ''}
                      onChange={this.handleChange('areaRistOutro')}
                      margin="normal"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 45)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%', maxWidth: '82rem' }}>
              <TextField
                id="obs"
                label={res.OBSERVACOES}
                value={this.state.obs}
                onChange={this.handleChange('obs')}
                margin="normal"
                fullWidth
                multiline
                rows={4}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 200)
                }}
              />
            </div>
          </div>
        </form >
        <div>

        </div>
        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          <div style={{ width: '47rem', flexGrow: 1 }}>
            <Prod res={res} link={link} list={this.state.list_prod} update={(itens) => { this.setState({ list_prod: itens, isRel: false }) }} add={this.addList('list_prod', 'list_all_prod')} />
          </div>
          <div style={{ width: '33rem', flexGrow: 1 }}>
            <Form res={res} link={link} list={this.state.list_form} update={(itens) => { this.setState({ list_form: itens, isRel: false }) }} add={this.addList('list_form', 'list_all_form')} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          <div style={{ width: '40rem', flexGrow: 1 }}>
            <AreaList
              res={res}
              link={link}
              list={this.state.list_areas}
              type={'Polígonos'}
              update={(itens, removed) => { this.updateAreas('list_areas', itens, removed) }}
              upload={(file) => { this.type_import = 'Polygons'; this.loadFile(file) }}
              edit={(guid) => { this.type_import = 'Polygon'; this.showEditArea('list_areas')(guid) }}
            />
          </div>
          <div style={{ width: '40rem', flexGrow: 1 }}>
            <AreaList
              res={res}
              link={link}
              list={this.state.list_points}
              type={'Pistas'}
              update={(itens, removed) => { this.updateAreas('list_points', itens, removed) }}
              upload={(file) => { this.type_import = 'Points'; this.loadFile(file) }}
              edit={(guid) => { this.type_import = 'Point'; this.showEditArea('list_points')(guid) }}
            />
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}>
              <InputLabel>{res.RESPONSAVEL_TECNICO}</InputLabel>
              <Select
                id="executor"
                value={this.state.executor}
                onChange={this.handleTechnicianChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.state.technicians.map((technician) => (
                  <MenuItem key={technician.id} value={technician.name}>
                    {technician.name}
                  </MenuItem>
                )
                )}
              </Select>
            </FormControl>
            <TextField
              id="creaExecutor"
              label={res.CFTA}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.executor_cfta_cpf}
              onChange={this.handleChange('executor_cfta_cpf')}
            />
            <TextField
              id="executorEmail"
              label={res.EMAIL}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.executorEmail ? this.state.executorEmail : ''}
              onChange={this.handleChange('executorEmail')}
            />
          </div>
          <div style={{ marginTop: '1rem' }}>
            {!this.state.isSigning && (
              <Button onClick={this.startSigning} variant="contained" color="primary">
                Assinatura do Técnico
              </Button>
            )}

            <Dialog
              open={this.state.isSigning}
              onClose={this.handleCloseDialog}
              aria-labelledby="signature-dialog"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle id="signature-dialog" style={{ padding: '0' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0.5rem 0',
                    backgroundColor: '#2D3238',
                  }}
                >
                  <div></div>
                  <div>
                    <h3 style={{ margin: '0', color: 'white', whiteSpace: 'nowrap' }}>
                      Assinatura do Técnico
                    </h3>
                  </div>
                  <div>
                    <CloseIcon
                      onClick={this.handleCloseDialog}
                      style={{
                        cursor: 'pointer',
                        width: '2rem',
                        height: '2rem',
                        color: 'white',
                        marginRight: '0.5rem',
                      }}
                    />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                style={{
                  margin: 0,
                  padding: '1rem',
                  color: 'black',
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                <h3 style={{ margin: '0', whiteSpace: 'nowrap' }}>
                  Desenhar assinatura do técnico:
                </h3>
                <div
                  style={{
                    border: '1px solid black',
                    maxWidth: '18.75rem',
                    margin: '0 auto',
                  }}
                >
                  <SignaturePad
                    ref={this.signaturePadRef}
                    options={{ minWidth: 4, penColor: 'black' }}
                  />
                </div>
              </DialogContent>
              <DialogActions
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Button onClick={this.clearSignature} color="secondary">
                  Limpar
                </Button>
                <Button
                  onClick={this.saveSignature}
                  color="primary"
                  disabled={!this.isSignatureValid()}
                >
                  Salvar Assinatura
                </Button>
              </DialogActions>
            </Dialog>

            {this.state.imageURL && (
              <div style={{ marginTop: '1rem' }}>
                <h4>Assinatura do Técnico:</h4>
                <img
                  src={this.state.imageURL}
                  alt="Assinatura"
                  style={{
                    border: '1px solid black',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { guid } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} guid={guid} res={res} />
      </div>
    </div>)
}

export default Box;